<template>
  <div>
    <div ref="pbi" style="max-height: 90vh" id="report-container"></div>
  </div>
</template>

<script>
import * as pbi from "powerbi-client";
import { mapGetters } from "vuex";

export default {
  props: ["type", "id", "workgroup_id", "embedUrl", "email"],
  data() {
    return {
      tokenType: 1,
      config: {},
      powerbi: null,
      serviceUpdate: null,
    };
  },
  async mounted() {
    if (this.type.toLowerCase() === "dashboard") {
      this.updatePBIDashboard();
      //this.serviceUpdate = setInterval(() => this.updatePBIDashboard(), 60000);
    } else {
      this.updatePBIReport();
      //this.serviceUpdate = setInterval(() => this.updatePBIReport(), 60000);
    }
  },
  beforeDestroy() {
    //console.log("limpando timer");
    clearInterval(this.serviceUpdate);
  },
  methods: {
    fullscreen() {
      const indicatorContainer = document.getElementById("report-container");
      const indicator = this.powerbi.get(indicatorContainer);

      indicator.fullscreen();
    },
    async updatePBIDashboard() {
      await this.$store.dispatch("dashboard/getPBITokenData", {
        workgroup_id: this.workgroup_id,
        id: this.id,
        type: this.type,
        email: this.email,
      });

      this.config = {
        type: this.type,
        tokenType: this.tokenType,
        accessToken: this.tokenData.embed_token,
        embedUrl: this.embedUrl,
        id: this.txtEmbedDashboardId,
        pageView: "fitToWidth",
      };

      this.powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const reportContainer = document.getElementById("report-container");
      let indicator = this.powerbi.embed(reportContainer, this.config);

      indicator.off("loaded");
      indicator.on("loaded", function () {
        console.log("Loaded");
      });

      indicator.on("error", function (event) {
        console.log(event.detail);
        indicator.off("error");
      });

      indicator.off("tileClicked");
      indicator.on("tileClicked", function (event) {
        console.log(event.detail);
      });
    },
    async updatePBIReport() {
      await this.$store.dispatch("dashboard/getPBITokenData", {
        workgroup_id: this.workgroup_id,
        id: this.id,
        type: this.type,
        email: this.email,
      });

      this.config = {
        type: "report",
        tokenType: 1,
        accessToken: this.tokenData.embed_token,
        embedUrl: this.embedUrl,
        id: this.txtEmbedDashboardId,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
          },
          layoutType: 1,
          pageSizeType: 0,
        },
      };

      this.powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const reportContainer = document.getElementById("report-container");
      let indicator = this.powerbi.embed(reportContainer, this.config);

      this.serviceUpdate = setInterval(() => {
        indicator.refresh();
      }, 60000);

      indicator.off("loaded");
      indicator.on("loaded", function () {
        console.log("Loaded");
      });

      indicator.on("error", function (event) {
        console.log(event.detail);
        indicator.off("error");
      });

      indicator.off("tileClicked");
      indicator.on("tileClicked", function (event) {
        console.log(event.detail);
      });
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      load: "load",
      tokenData: "dashboard/pbiTokenData",
    }),
  },
};
</script>

<style>
#report-container {
  width: 100%;
  height: 85vh;
  position: relative;
  margin-top: 10px;
  padding: 0;
  background-color: "transparent";
  -webkit-overflow-scrolling: touch;
  border: 0;
}
</style>
