<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div id="buildedit-content" class="content-header px-0">
      <h1 id="buildedit-title" class="title-I">Editar edifício</h1>
    </div>

    <div id="buildedit-div-master" class="col-12 col-sm-12 p-0 col-md-12">
      <div id="buildedit-div-card" class="card direct-chat direct-chat-primary">
        <div id="buildedit-div-card-body" class="card-body">
          <div id="buildedit-form-wrapper" class="p-4 bg-white rounded">
            <form-wizard
              id="buildedit-magic-form-wizard"
              title=""
              subtitle=""
              shape="square"
              color="#4E01D6 "
              ref="fw"
            >
              <tab-content
                id="buildedit-building-info"
                title="Informações do edifício"
                icon="fas fa-sliders-h"
              >
                <BasicTab @next="next" />
              </tab-content>
              <tab-content id="buildedit-building-floors" title="Andares" icon="far fa-building">
                <FloorTab @next="next" />
              </tab-content>
              <tab-content
                id="buildedit-building-zones"
                title="Zonas / Geofences"
                icon="fas fa-map-marker-alt"
              >
                <ZoneTab id="buildedit-zone-tab" @back="back" @next="next" />
              </tab-content>
              <tab-content id="buildedit-see-plant" title="Ver planta" icon="fas fa-eye">
                <SeeTab @back="back" />
              </tab-content>

              <template slot="footer"><hr /></template>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import BasicTab from "./tabs/basic.vue";
import FloorTab from "./tabs/floors.vue";
import ZoneTab from "./tabs/zones.vue";
import SeeTab from "./tabs/see.vue";
export default {
  name: "EditBuilding",
  components: {
    BasicTab,
    FloorTab,
    Breadcrumb,
    FormWizard,
    SeeTab,
    TabContent,
    ZoneTab,
  },
  data: function () {
    return {
      breadcrumbList: [],
      floorDelete: "",
    };
  },
  mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;
    //this.$refs.fw.activateAll();
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  methods: {
    back() {
      this.$refs.fw.prevTab();
    },
    next() {
      this.$refs.fw.nextTab();
    },
  },
  computed: {},
};
</script>

<style scoped>
.wizard-card-footer {
  display: none;
}
</style>
