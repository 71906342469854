import "./assets/scss/AdminLTE.scss";
import "./assets/scss/custom.scss";
import "./plugins/fontawesome-free-6.2.0-web/css/all.min.css";
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueToastr2 from "vue-toastr-2";
import "vue-toastr-2/dist/vue-toastr-2.min.css";
import Popper from "popper.js";
import VueDragResize from "vue-drag-resize";
import { VueMaskDirective } from "v-mask";
import hasRole from "./utils/hasRole";
import { frontMsg } from "./utils/errors";

import Modal from "./components/Modal";
global.Popper = Popper;

window.toastr = require("toastr");
window.toastr.options.escapeHtml = true;
window.frontMsg = frontMsg;

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import {
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BFormGroup,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormSelect,
  BContainer,
  BTab,
  BTabs,
  VBToggle,
  BModal,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BProgress,
  BProgressBar,
  BFormTextarea,
  BRow,
  BCol,
  BFormFile,
} from "bootstrap-vue";

Vue.directive("b-toggle", VBToggle);
Vue.directive("mask", VueMaskDirective);

// DIRETIVAS PARA CONTROLAR VISUALIZAÇÃO DE COMPONENTES BASEADA NA ROLE DO USUARIO
// Ex: <div v-can="['ADMIN']">content</div>
// O usuário só vera a div com o conteúdo caso tenha a role ADMIN
// Ex: <div v-cant="['USER']">content</div>
// Se o usuário possuí a role USER ele não vera o componente
Vue.directive("can", {
  bind: function (el, binding) {
    if (!hasRole(binding.value)) {
      el.style.display = "none";
    }
  },
});
Vue.directive("cant", {
  bind: function (el, binding) {
    if (hasRole(binding.value)) {
      el.style.display = "none";
    }
  },
});

Vue.use(VueToastr2);
Vue.component("b-container", BContainer);
Vue.component("b-dropdown", BDropdown);
Vue.component("b-dropdown-item", BDropdownItem);
Vue.component("b-button", BButton);
Vue.component("b-modal", BModal);
Vue.component("b-table", BTable);
Vue.component("b-progress", BProgress);
Vue.component("b-progress-bar", BProgressBar);
Vue.component("b-tab", BTab);
Vue.component("b-tabs", BTabs);
Vue.component("b-spinner", BSpinner);
Vue.component("b-pagination", BPagination);
Vue.component("b-form-select", BFormSelect);
Vue.component("b-form-select-option", BFormSelect);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-checkbox-group", BFormCheckboxGroup);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-radio-group", BFormRadioGroup);
Vue.component("vue-drag-resize", VueDragResize);
Vue.component("b-input-group", BInputGroup);
Vue.component("b-input-group-prepend", BInputGroupPrepend);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-row", BRow);
Vue.component("b-col", BCol);
Vue.component("b-form-file", BFormFile);
Vue.component("Modal", Modal);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
