import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
//import router from "../router";

export default {
  namespaced: true,
  state: {
    inventarios: [],
    done: true,
  },

  mutations: {
    async GET_INVENTARIO(state) {
      await api.get("tags?sortBy=tag_label").then((response) => {
        state.inventarios = response.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Inventário', 'GET_INVENTARIO');
      });
    },
    async CHANGE_DONE(state) {
      state.done = !state.done;
    }
  },
  getters: {
    inventarios: (state) => state.inventarios,
    done: (state) => state.done,
  },
  actions: {
    get(context) {
      context.commit("GET_INVENTARIO");
    },
    done(context) {
      context.commit("CHANGE_DONE");
    }
  },
};
