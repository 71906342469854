<template>
  <div data-dismiss="modal" aria-label="Close">
    <!-- Modal -->
    <div
      class="modal fade"
      id="import-asset-config-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="id"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
        style="max-width: 1200px !important"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Configuração do Ativo
            </h5>
            <button
              id="ast-config-close-modal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" id="ast-config-close-modal-text"
                >&times;</span
              >
            </button>
          </div>

          <div class="modal-body">
            <div class="col-12 col-sm-12 p-0 col-md-12">
              <div class="card-body">
                <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                  <div class="row mb-2">
                    <div class="col-12">
                      <div
                        class="card direct-chat direct-chat-primary employee"
                      >
                        <div class="card-body custom-card overflow-visible">
                          <ul class="nav nav-tabs bg-main">
                            <li class="nav-item">
                              <a
                                id="info-tab"
                                class="nav-link first"
                                v-bind:class="{
                                  active: assetTabSelected,
                                }"
                                v-on:click="changeTab('assetTabSelected')"
                                href="#"
                                aria-current="assetTabSelected"
                                tabindex="-1"
                                v-bind:style="
                                  assetError.header.error
                                    ? 'color: red !important'
                                    : 'color: #000'
                                "
                                >Ativo</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                id="functionalTab-tab"
                                class="nav-link"
                                v-bind:class="{
                                  active: functionalTabSelected,
                                }"
                                v-on:click="changeTab('functionalTab')"
                                href="#"
                                aria-current="functionalTab"
                                tabindex="-1"
                                v-bind:style="
                                  assetError.functional_data.error
                                    ? 'color: red !important'
                                    : 'color: #000'
                                "
                                >Dados funcionais</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                id="financialTab"
                                class="nav-link"
                                v-bind:class="{
                                  active: financialTabSelected,
                                }"
                                v-on:click="changeTab('financialTab')"
                                href="#"
                                aria-current="financialTab"
                                tabindex="-1"
                                v-bind:style="
                                  assetError.financial_data.error
                                    ? 'color: red !important'
                                    : 'color: #000'
                                "
                                >Dados financeiros</a
                              >
                            </li>
                            <li class="nav-item active">
                              <a
                                class="nav-link"
                                v-bind:class="{
                                  active: this.tabSelected === 'warrantyTab',
                                }"
                                v-on:click="changeTab('warrantyTab')"
                                href="#"
                                aria-current="financialTab"
                                tabindex="-1"
                                id="transmission"
                                v-bind:style="
                                  assetError.warranty_data.error
                                    ? 'color: red !important'
                                    : 'color: #000'
                                "
                                >Dados de garantia</a
                              >
                            </li>
                          </ul>
                          <div class="p-4 bg-white rounded">
                            <div class="tab-content">
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: assetTabSelected,
                                  active: assetTabSelected,
                                }"
                              >
                                <div class="row">
                                  <div class="card-body">
                                    <div class="p-4 bg-white rounded">
                                      <div class="col-12">
                                        <div class="row">
                                          <TwInput col_lg="6" label="Nome">
                                            <input
                                              v-model="asset.name"
                                              type="text"
                                              class="form-control"
                                              placeholder="Nome do Ativo"
                                              id="assetName"
                                              maxlength="64"
                                              disabled
                                            />
                                          </TwInput>
                                          <TwInput label="Data de Aquisição">
                                            <input
                                              v-model="asset.acquisition_date"
                                              type="date"
                                              class="form-control"
                                              placeholder="Data de Aquisição"
                                              id="assetAcquisitionDate"
                                              @focusout="
                                                verifyErrors(
                                                  'header.acquisition_date'
                                                )
                                              "
                                              v-bind:style="
                                                assetError.header
                                                  .acquisition_date.error
                                                  ? 'border-color: red'
                                                  : 'border-color: #ced4da'
                                              "
                                            />
                                          </TwInput>
                                          <TwInput label="Categoria">
                                            <input
                                              list="categoryList"
                                              placeholder="Categoria do Ativo"
                                              class="form-control"
                                              id="assetCategory"
                                              autocomplete="off"
                                              type="text"
                                              v-model="asset.category"
                                              maxlength="64"
                                              disabled
                                            />
                                          </TwInput>
                                          <TwInput label="Subcategoria">
                                            <b-form-input
                                              list="subcategoryList"
                                              placeholder="Subcategoria do Ativo"
                                              class="form-control"
                                              id="assetSubcategory"
                                              autocomplete="off"
                                              type="text"
                                              v-model="asset.subcategory"
                                              maxlength="64"
                                              disabled
                                            ></b-form-input>
                                          </TwInput>
                                        </div>
                                        <div class="row">
                                          <TwInput label="Número de série">
                                            <input
                                              v-model="asset.serial_number"
                                              type="text"
                                              class="form-control"
                                              placeholder="Número de série do Ativo"
                                              id="assetSerial"
                                              maxlength="128"
                                              disabled
                                            />
                                          </TwInput>
                                          <TwInput
                                            label="Identificador Interno"
                                          >
                                            <i
                                              title="Identificador Interno - SKU ou Número de patrimônio"
                                              class="fas fa-question-circle"
                                              style="margin-left: 5px"
                                            >
                                            </i>
                                            <input
                                              v-model="asset.internal_id"
                                              type="text"
                                              class="form-control"
                                              placeholder="Número Identificador do Ativo"
                                              id="assetInternal"
                                              maxlength="32"
                                              disabled
                                            />
                                          </TwInput>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: financialTabSelected,
                                  active: financialTabSelected,
                                }"
                              >
                                <div class="card-body">
                                  <div class="p-4 bg-white rounded">
                                    <div class="row">
                                      <TwInput
                                        col_lg="4"
                                        label="Taxa anual de depreciação (%)"
                                      >
                                        <b-form-input
                                          v-model="
                                            asset.financial_data
                                              .depreciation_rate
                                          "
                                          type="number"
                                          class="form-control"
                                          placeholder="Taxa anual de depreciação (%)"
                                          id="assetDepreciationRate"
                                          min="0"
                                          max="100"
                                          v-bind:style="
                                            assetError.financial_data
                                              .depreciation_rate.error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                          @change="
                                            verifyErrors(
                                              'financial_data.depreciation_rate'
                                            )
                                          "
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Custo de Aquisição"
                                      >
                                        <b-form-input
                                          v-model="
                                            asset.financial_data
                                              .acquisition_cost
                                          "
                                          type="number"
                                          class="form-control"
                                          placeholder="Custo de Compra do Ativo"
                                          id="assetAcquisitionCost"
                                          min="0"
                                          step=".01"
                                          max="10000000000"
                                          v-bind:style="
                                            assetError.financial_data
                                              .acquisition_cost.error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                          @change="
                                            verifyErrors(
                                              'financial_data.acquisition_cost'
                                            )
                                          "
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Data de Descarte"
                                      >
                                        <input
                                          v-model="
                                            asset.financial_data.expiration_date
                                          "
                                          type="date"
                                          class="form-control"
                                          placeholder="Data de Descarte do Ativo"
                                          id="assetDisposalDate"
                                          :min="'1900-01-01'"
                                          :max="'2099-12-31'"
                                          v-bind:style="
                                            assetError.financial_data
                                              .expiration_date.error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                          @focusout="
                                            verifyErrors(
                                              'financial_data.expiration_date'
                                            )
                                          "
                                        />
                                      </TwInput>
                                    </div>
                                    <div class="row">
                                      <TwInput
                                        col_lg="4"
                                        label="Valor Residual"
                                      >
                                        <b-form-input
                                          v-model="
                                            asset.financial_data.salvage_value
                                          "
                                          type="number"
                                          class="form-control"
                                          placeholder="Valor Residual"
                                          id="assetSalvageValue"
                                          max="10000000000"
                                          min="0"
                                          step=".01"
                                          v-bind:style="
                                            assetError.financial_data
                                              .salvage_value.error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                          @change="
                                            verifyErrors(
                                              'financial_data.salvage_value'
                                            )
                                          "
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Vida Útil Operacional"
                                      >
                                        <b-form-input
                                          v-model="
                                            asset.financial_data.useful_life
                                          "
                                          type="number"
                                          class="form-control"
                                          placeholder="Vida Útil Operacional em anos"
                                          id="assetUsefulLife"
                                          min="0"
                                          max="12000"
                                          v-bind:style="
                                            assetError.financial_data
                                              .useful_life.error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                          @change="
                                            verifyErrors(
                                              'financial_data.useful_life'
                                            )
                                          "
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Vida Útil Total"
                                      >
                                        <input
                                          v-model="
                                            asset.financial_data.lifespan
                                          "
                                          type="number"
                                          class="form-control"
                                          placeholder="Vida Útil do Ativo em anos?"
                                          id="assetLifespan"
                                          max="12000"
                                          min="0"
                                          v-bind:style="
                                            assetError.financial_data.lifespan
                                              .error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                          @change="
                                            verifyErrors(
                                              'financial_data.lifespan'
                                            )
                                          "
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Nomenclatura Comum do Mercosul"
                                      >
                                        <input
                                          v-model="asset.financial_data.ncm"
                                          type="text"
                                          class="form-control"
                                          placeholder="Nomenclatura Comum do Mercosul"
                                          id="assetNCM"
                                          maxlength="16"
                                        />
                                      </TwInput>
                                      <TwInput col_lg="4" label="Comodatário">
                                        <input
                                          v-model="
                                            asset.financial_data.borrower
                                          "
                                          type="text"
                                          class="form-control"
                                          placeholder="Comodatário do Ativo"
                                          id="assetBorrower"
                                          maxlength="32"
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Data da Última Movimentação"
                                      >
                                        <input
                                          v-model="
                                            asset.financial_data
                                              .asset_movement_date
                                          "
                                          type="date"
                                          class="form-control"
                                          placeholder="Data da Última Movimentação"
                                          id="assetMovementDate"
                                          :min="'1900-01-01'"
                                          :max="'2099-12-31'"
                                          @focusout="
                                            verifyErrors(
                                              'financial_data.asset_movement_date'
                                            )
                                          "
                                          v-bind:style="
                                            assetError.financial_data
                                              .asset_movement_date.error
                                              ? 'border-color: red'
                                              : 'border-color: #ced4da'
                                          "
                                        />
                                      </TwInput>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: functionalTabSelected,
                                  active: functionalTabSelected,
                                }"
                              >
                                <div class="row">
                                  <div class="card-body">
                                    <div class="p-4 bg-white rounded">
                                      <div class="col-12">
                                        <div class="row">
                                          <TwInput col_lg="4" label="Área">
                                            <select
                                              v-model="
                                                asset.functional_data.area
                                              "
                                              class="form-control"
                                              id="assetArea"
                                            >
                                              <option
                                                v-for="area in area_options"
                                                :key="area"
                                              >
                                                {{ area }}
                                              </option>
                                              <option value="other">
                                                Outro
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Departamento"
                                          >
                                            <select
                                              v-model="
                                                asset.functional_data.department
                                              "
                                              class="form-control"
                                              id="assetDepartment"
                                            >
                                              <option
                                                v-for="department in department_options"
                                                :key="department"
                                              >
                                                {{ department }}
                                              </option>
                                              <option value="other">
                                                Outro
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Responsável"
                                          >
                                            <select
                                              v-model="
                                                asset.functional_data.supervisor
                                              "
                                              class="form-control"
                                            >
                                              <option
                                                v-for="sup in filteredSupervisors"
                                                class="sup"
                                                :key="sup.id"
                                              >
                                                <b>{{ sup.name }}</b>
                                              </option>
                                              <option value="other">
                                                Outro
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Estado de Funcionamento"
                                          >
                                            <select
                                              v-model="
                                                asset.functional_data.state
                                              "
                                              class="form-control"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="ATIVO">
                                                ATIVO
                                              </option>
                                              <option value="INATIVO">
                                                INATIVO
                                              </option>
                                              <option value="DESCARTADO">
                                                DESCARTADO
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Importância"
                                          >
                                            <select
                                              v-model="
                                                asset.functional_data.importance
                                              "
                                              class="form-control"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="ALTA">ALTA</option>
                                              <option value="MEDIA">
                                                MÉDIA
                                              </option>
                                              <option value="BAIXA">
                                                BAIXA
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput col_lg="4" label="Qualidade">
                                            <select
                                              v-model="
                                                asset.functional_data
                                                  .quality_state
                                              "
                                              class="form-control"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="PERFEITO">
                                                PERFEITO
                                              </option>
                                              <option value="BOM">BOM</option>
                                              <option value="SUCATEADO">
                                                SUCATEADO
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Treinamento de Manuseio Necessário"
                                          >
                                            <b-form-checkbox
                                              size="lg"
                                              class="form-group"
                                              v-model="
                                                asset.functional_data
                                                  .training_required
                                              "
                                            >
                                              {{
                                                asset.functional_data
                                                  .training_required
                                                  ? "Sim"
                                                  : "Não"
                                              }}
                                            </b-form-checkbox>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Fabricante"
                                          >
                                            <input
                                              v-model="
                                                asset.functional_data
                                                  .manufacturer
                                              "
                                              type="text"
                                              class="form-control"
                                              placeholder="Fabricante do Ativo"
                                              id="assetManufacturer"
                                              maxlength="64"
                                            />
                                          </TwInput>
                                          <TwInput col_lg="4" label="Modelo">
                                            <input
                                              v-model="
                                                asset.functional_data.model
                                              "
                                              type="text"
                                              class="form-control"
                                              placeholder="Modelo do Ativo"
                                              id="assetModel"
                                              maxlength="64"
                                            />
                                          </TwInput>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: warrantyTabSelected,
                                  active: warrantyTabSelected,
                                }"
                              >
                                <div class="row">
                                  <div class="card-body">
                                    <div class="p-4 bg-white rounded">
                                      <div class="col-12">
                                        <div class="row">
                                          <TwInput label="Estado da Garantia">
                                            <input
                                              v-model="
                                                asset.warranty_data.status
                                              "
                                              class="form-control"
                                              id="warrantyStatus"
                                              disabled
                                            />
                                          </TwInput>

                                          <TwInput label="Código de Garantia">
                                            <b-form-input
                                              v-model="asset.warranty_data.code"
                                              type="text"
                                              class="form-control"
                                              placeholder="Código de Garantia"
                                              id="warrantyCode"
                                              maxlength="64"
                                            />
                                          </TwInput>

                                          <TwInput label="Válido até">
                                            <b-form-input
                                              v-model="
                                                asset.warranty_data.valid_until
                                              "
                                              type="date"
                                              class="form-control"
                                              placeholder="Garantia Válida até"
                                              id="warrantyValidUntil"
                                              @focusout="verifyValidity()"
                                              :min="'1900-01-01'"
                                              :max="'2099-12-31'"
                                              v-bind:style="
                                                assetError.warranty_data
                                                  .valid_until.error
                                                  ? 'border-color: red'
                                                  : 'border-color: #ced4da'
                                              "
                                            />
                                          </TwInput>
                                        </div>
                                        <div class="row">
                                          <TwInput
                                            col_lg="12"
                                            label="Descrição de Cobertura - Garantia"
                                          >
                                            <b-form-textarea
                                              v-model="
                                                asset.warranty_data.description
                                              "
                                              id="warrantyDescription"
                                              class="form-control"
                                              placeholder="Descrição da Garantia"
                                              rows="3"
                                              max-rows="8"
                                              maxlength="256"
                                              no-auto-shrink
                                            ></b-form-textarea>
                                          </TwInput>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row p-1">
                    <button
                      id="saveEditAsset"
                      class="btn btn-primary px-5"
                      v-on:click="updateAsset"
                    >
                      <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import { formatNumber } from "@/utils/format-fields.js";
export default {
  name: "AssetImportConfig",
  props: {
    entireAsset: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      tabSelected: "assetTabSelected",
      breadcrumbList: [],
      DEFAULT: true,
      fields: [
        {
          key: "maintenance_type",
          sortable: true,
          label: "Tipo de Manutenção",
        },
        {
          key: "maintenance_frequency",
          sortable: true,
          label: "Frequência da Manutenção",
        },
        { key: "maintenance_description", label: "Descrição da Manutenção" },
        { key: "actions", label: "Ações" },
      ],
      maintenanceHistory: [
        "PREDITIVA",
        "CORRETIVA",
        "PREVENTIVA",
        "CALIBRAÇÃO",
      ],
      selectedStatus: "",
      statusOptions: [
        { text: "Sim", value: "true" },
        { text: "Não", value: "false" },
      ],
      asset: {
        internal_id: null,
        acquisition_date: null,
        category: "",
        description: "",
        financial_data: {
          acquisition_cost: null,
          depreciation_rate: null,
          expiration_date: null,
          lifespan: null,
          ncm: null,
          salvage_value: null,
          useful_life: null,
          borrower: null,
          last_nf_number: null,
          asset_movement_date: null,
          nf_file: "",
        },
        functional_data: {
          area: null,
          department: null,
          importance: null,
          manufacturer: null,
          model: null,
          quality_state: null,
          state: null,
          supervisor: "",
          training_required: false,
        },
        maintenance_data: [
          {
            description: "",
            frequency: null,
            type: null,
          },
        ],
        name: null,
        serial_number: null,
        subcategory: "",
        warranty_data: {
          code: "",
          description: "",
          status: null,
          valid_until: null,
        },
      },
      assetError: {
        header: {
          error: false,
          label: "Asset",
          acquisition_date: {
            error: false,
            label: "Data de Aquisição",
            type: "date",
          },
        },
        financial_data: {
          error: false,
          label: "Dados Financeiros",
          acquisition_cost: {
            error: false,
            label: "Custo de Aquisição",
            type: "number",
            max: 10000000000,
          },
          depreciation_rate: {
            error: false,
            label: "Taxa Anual de Depreciação",
            type: "number",
            max: 100,
          },
          expiration_date: {
            error: false,
            label: "Data de Descarte",
            type: "date",
          },
          lifespan: {
            error: false,
            label: "Vida Útil Total",
            type: "number",
            max: 12000,
          },
          salvage_value: {
            error: false,
            label: "Valor Residual",
            type: "number",
            max: 10000000000,
          },
          useful_life: {
            error: false,
            label: "Vida Útil Operacional",
            type: "number",
            max: 12000,
          },
          last_nf_number: {
            error: false,
            label: "Número da Última NF",
            type: "number",
            max: 9999999999,
          },
          asset_movement_date: {
            error: false,
            label: "Data da Última Movimentação",
            type: "date",
          },
        },
        warranty_data: {
          error: false,
          label: "Dados de Garantia",
          valid_until: {
            error: false,
            label: "Válido até",
            type: "date",
          },
        },
        functional_data: {
          error: false,
          label: "Dados Funcionais",
        },
      },
      tabErrors: {
        financial_data: false,
        functional_data: false,
        maintenance_data: false,
        warranty_data: false,
      },
    };
  },
  created() {
    this.updateList();
  },
  watch: {
    entireAsset() {
      this.asset = this.entireAsset;
      this.serial_number = this.asset.serial_number;
    },
    $route() {
      this.updateList();
    },
  },
  methods: {
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    updateAsset() {
      document.getElementById("ast-config-close-modal").click();
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    verifyValidity() {
      this.verifyErrors("warranty_data.valid_until");
      const todayDate = new Date();

      if (this.asset.warranty_data.valid_until) {
        let validityDate = new Date(this.asset.warranty_data.valid_until);
        validityDate.setDate(validityDate.getDate() + 1); // Adjusting validity date to the choosed day.

        this.asset.warranty_data.status =
          validityDate >= todayDate ? "VALIDO" : "EXPIRADO";
      } else this.asset.warranty_data.status = null;
    },
    verifyErrors(key) {
      let assetContent = (assetData, objKey) => {
        const assetKey = objKey.split(".");
        let data = assetData;

        for (let i of assetKey) {
          data = data[i];
        }

        return data;
      };

      let changeTabColor = (tab) => {
        for (let input in this.assetError[tab]) {
          if (this.assetError[tab][input].error) {
            return true;
          }
        }

        return false;
      };

      let changeErrorStatus = (status) => {
        const assetKey = key.split(".");

        if (assetKey.length === 1) {
          this.assetError[assetKey[0]].error = status;
        } else if (assetKey.length === 2) {
          this.assetError[assetKey[0]][assetKey[1]].error = status;
        } else if (assetKey.length === 3) {
          this.assetError[assetKey[0]][assetKey[1]][assetKey[2]].error = status;
        }
        this.assetError[assetKey[0]].error = changeTabColor(assetKey[0]);
      };

      let objKey = key.includes("header.") ? key.replace("header.", "") : key;
      let content = assetContent(this.asset, objKey);
      let errorObj = assetContent(this.assetError, key);
      let error = false;

      if (errorObj.type !== "string" && content) {
        if (
          errorObj.type === "number" &&
          (content < 0 || content > errorObj.max)
        ) {
          error = true;
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: errorObj.label,
            numbercondition: `entre 0 e ${formatNumber(errorObj.max)}`,
          });
        } else if (errorObj.type === "date") {
          let inputDate = new Date(content);
          let minDate = new Date("1900-01-01");
          let maxDate = new Date("2099-12-31");

          if (
            inputDate < minDate ||
            inputDate > maxDate ||
            inputDate == "Invalid Date"
          ) {
            error = true;
            window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
              field: errorObj.label,
              extra: "Insira uma data entre 01/01/1900 e 31/12/2099.",
            });
          }
        }
      } else if (
        errorObj.type === "string" &&
        (!content || !content?.toString().trim())
      ) {
        error = true;
        window.frontMsg("FRNT_EMPTY_WARNING", { field: errorObj.label });
      }
      changeErrorStatus(error);
    },
    closeModalVerifies() {
      let replaceInvalidData = (stringKey, assetParameters) => {
        let key = stringKey.split(".");

        if (key.length === 1) {
          this.asset[key[0]] = assetParameters.type === "number" ? null : "";
        }

        if (key.length === 2) {
          this.asset[key[0]][key[1]] =
            assetParameters.type === "number" ? null : "";
        }
      };

      let verifyInvalidities = (asset, stringKey) => {
        let tabs = [
          "header",
          "financial_data",
          "functional_data",
          "warranty_data",
        ];
        let invalidData = false;
        let newKey;

        for (let key in asset) {
          if (asset[key].error) {
            invalidData = true;

            if (tabs.includes(key)) {
              newKey = key !== "header" ? key : "";
              verifyInvalidities(asset[key], newKey);

              if (invalidData) {
                window.frontMsg("ASSET_IMPORT_SAVE_INVALID", {
                  assetTab: asset[key].label,
                });
              }
            } else {
              newKey = stringKey ? `${stringKey}.${key}` : key;
              replaceInvalidData(newKey, asset[key]);
            }

            asset[key].error = false;
          }
        }
      };

      verifyInvalidities(this.assetError, "");
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    financialTabSelected() {
      return this.tabSelected === "financialTab";
    },
    functionalTabSelected() {
      return this.tabSelected === "functionalTab";
    },
    assetTabSelected() {
      return this.tabSelected === "assetTabSelected";
    },
    warrantyTabSelected() {
      return this.tabSelected === "warrantyTab";
    },
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customer_employees() {
      return this.$store.getters["employee/employees"];
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    department_options() {
      return this.$store.getters["employee/departments"].map(
        (department) => department.department
      );
    },
    filteredSupervisors() {
      if (this.employeeSupervisor) {
        return this.customer_employees.filter((employee) => {
          return (
            employee.name
              .toLowerCase()
              .includes(this.employeeSupervisor.toLowerCase()) &&
            employee.functional_data?.position?.toLowerCase() === "supervisor"
          );
        });
      }
      return this.customer_employees.filter(
        (employee) =>
          employee.functional_data?.position?.toLowerCase() === "supervisor"
      );
    },
  },
  mounted() {
    let modal = document.getElementById("import-asset-config-modal");
    let saveButton = document.getElementById("saveEditAsset");
    let closeButton = document.getElementById("ast-config-close-modal");
    let closeButtonText = document.getElementById(
      "ast-config-close-modal-text"
    );

    window.onclick = (event) => {
      if (
        event.target == modal ||
        event.target == saveButton ||
        event.target == closeButton ||
        event.target == closeButtonText
      ) {
        this.closeModalVerifies();
      }
    };
  },
};
</script>
<style>
.margin-left-zero {
  margin-left: 0;
}
.eyesight {
  margin-left: 5px;
  position: absolute;
  right: 36px;
  top: 42px;
  cursor: pointer;
}
.same-size-ps {
  width: 150px;
  text-align: left;
  background-color: #e9ecef;
}
</style>
