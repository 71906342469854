import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    buildings: [],
    building: null,
  },

  mutations: {
    async SAVE_BUILDING(state, data) {
      state.buildings.push(data);
    },
    async DELETE_BUILDING(state, id) {
      state.buildings = state.buildings.filter(
        (building) => building.id !== id
      );
    },
    async UPDATE_BUILDING(state, { data }) {
      await api
        .put("customers/" + data.customer.id + "/buildings", data.building)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Edifício', "UPDATE_BUILDING");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Edifício', "UPDATE_BUILDING");
        });
    },
    async GET_BUILDINGS(state, { data }) {
      await api
        .get("customers/" + data.customer_id + "/buildings")
        .then((res) => {
          state.buildings = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Edifício', 'GET_BUILDINGS');
        })
    },
    async GET_BUILDING(state, { data }) {
      await api
        .get(`buildings/${data.buildingId}`)
        .then((response) => {
          state.building = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Edifício', 'GET_BUILDING');
        });
    },
  },

  getters: {
    buildings: (state) => state.buildings,
    building: (state) => state.building,
  },
  actions: {
    async deleteBuilding(context, data) {
      await api
        .delete(`buildings/${data.id}`)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Edifício', "DELETE_BUILDING");
          context.commit("DELETE_BUILDING", data.id);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Edifício', 'DELETE_BUILDING');
        });
    },
    updateBuilding(context, data) {
      context.commit("UPDATE_BUILDING", { data });
    },
    getBuildings(context, data) {
      context.commit("GET_BUILDINGS", { data });
    },
    getBuilding(context, data) {
      context.commit("GET_BUILDING", { data });
    },
    async saveBuilding(context, data) {
      await api
        .post("customers/" + data.customer.id + "/buildings", data.building)
        .then((res) => {
          data.building["id"] = res.data.id;
          displayErrMsg('GLOBAL_SUCCESS_ADD', 'Edifício', "DELETE_BUILDING");
          context.commit("SAVE_BUILDING", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Edifício', 'SAVE_BUILDING');
        });
    },
  },
};
