var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

export function encrypt_data(data) {
  return CryptoJS.AES.encrypt(data, "T1TrjzQc9L0RvOU1ER7F");
}

export function decrypt_data(encrypted) {
  let decrypted = CryptoJS.AES.decrypt(encrypted, "T1TrjzQc9L0RvOU1ER7F");
  return decrypted.toString(CryptoJS.enc.Utf8);
}
