<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div id="timeradd-content" class="content-header px-0">
      <h1 id="timeradd-title" class="title-I">Adicionar Timer</h1>
    </div>

    <div id="timeradd-div-master" class="col-12 col-sm-12 p-0 col-md-12">
      <div id="timeradd-div-card" class="card direct-chat direct-chat-primary">
        <div id="timeradd-card-body" class="card-body">
          <div id="timeradd-form-wrapper" class="p-4 bg-white rounded">
            <form
              id="timeradd-savecont-form"
              @submit="saveTimers"
              novalidate="true"
            >
              <div id="timeradd-wrapper-row" class="row">
                <TwInput
                  id="alertTimeTW"
                  label="Timer de Alerta Sonoro (s)"
                  required="true"
                  col_lg="4"
                >
                  <input
                    id="alertTime"
                    v-model="editedtimers.alert_time"
                    required
                    type="number"
                    class="form-control"
                    placeholder="45"
                  />
                </TwInput>
                <TwInput
                  id="guardTimeTW"
                  label="Timer de Guarda (s)"
                  required="true"
                  col_lg="4"
                >
                  <input
                    id="guardTime"
                    v-model="editedtimers.guard_time"
                    required
                    type="number"
                    class="form-control"
                    placeholder="30"
                  />
                </TwInput>
                <TwInput
                  id="timeradd-twin-contact"
                  label="Timer de Inatividade (s)"
                  required="true"
                  col_lg="4"
                >
                  <input
                    id="inactivityTime"
                    v-model="editedtimers.inactivity_time"
                    required
                    type="number"
                    class="form-control"
                    placeholder="30"
                  />
                </TwInput>
              </div>

              <div id="timeradd-but-wrapper" class="row mt-2 p-1">
                <button
                  id="saveTimers"
                  class="btn btn-primary px-5"
                  :disabled="load"
                >
                  <i
                    id="timers-save"
                    v-if="load"
                    class="fas fa-sync-alt fa-spin"
                  ></i>
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "TimersTab",
  data: function () {
    return {
      breadcrumbList: [],
      editedtimers: {
        alert_time: "",
        guard_time: "",
        inactivity_time: "",
      },
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("customer/getTimers", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
  },
  methods: {
    saveTimers() {
      event.preventDefault();
      for (const key of Object.keys(this.editedtimers)) {
        if (this.editedtimers[key] <= 0 || this.editedtimers[key] == "") {
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
            field: "Timers",
            extra: "Todos os valores devem ser maiores que 0.",
          });
          return;
        }
      }

      if (this.timersExist) {
        this.$store.dispatch("customer/saveTimers", {
          customer_id: this.$route.params.customerId,
          timers: {...this.editedtimers, id: this.timers.id},
        });
      } else {
        this.$store.dispatch("customer/addTimers", {
          customer_id: this.$route.params.customerId,
          timers: this.editedtimers,
        });
      }
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    timers() {
      delete this.timers.customer_id;
      this.editedtimers = {...this.timers};
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    timers() {
      return this.$store.getters["customer/timers"];
    },
    timersExist() {
      return this.$store.getters["customer/timersExist"];
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
