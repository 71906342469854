import { api } from "../services/api";
import router from "../router";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    platform_licenses: [],
  },
  mutations: {
    GET_LICENSES(state) {
      api.get("platform/licenses").then((response) => {
        state.platform_licenses = response.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Licenças', "GET_LICENSES");
      });
    },
    UPDATE_LICENSES(state, { data }) {
      api.put("platform/licenses", data).then((response) => {
        state.licenses = response.data;
        window.toastr.success("Alterado com sucesso", "Licenças da plataforma");
        router.push("/platform");
      })
      .catch((err) => {
        if (err.response.data && err.response.data.code === 409)
          window.toastr.error("Já está associado ao PBI", "Cliente");
        if (err.response.data && err.response.data.code === 400)
          window.toastr.error(
            "Workspace preenchida incorretamente",
            "Cliente"
          );
        if (err.response.data && err.response.data.code === 404)
          window.toastr.error("Usuário PBI ou senha incorreta", "Cliente");
      });
    },
    CREATE_LICENSE(state, { data }) {
      //Not the final implementation, API's route isn't working
      api.post("platform/licenses", data).then((response) => {
        state.licenses = response.data;
      });
    },
    DELETE_LICENSE(state, { data }) {
      //Not the final implementation, API's route isn't working
      api.delete("platform/licenses", data).then((response) => {
        state.licenses = response.data;
      });
    },
  },
  getters: {
    platform_licenses: (state) => state.platform_licenses,
  },
  actions: {
    getLicenses(context) {
      context.commit("GET_LICENSES");
    },
    updateLicenses(context, data){
      context.commit("UPDATE_LICENSES", { data });
    },
    createLicenses(context, data){
      context.commit("CREATE_LICENSE", { data });
    },
    deleteLicense(context, data){
      context.commit("DELETE_LICENSES", { data });
    },
  },
};
