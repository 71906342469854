// import { subscribe } from "toastr";
import { api } from "../services/api";
// import router from "../router";

export default {
  namespaced: true,
  state: {},

  mutations: {
    async init() {
      try {
        const userId =
          localStorage.getItem("rememberMe") === "true"
            ? localStorage.getItem("user_id")
            : sessionStorage.getItem("user_id");

        if (!userId) {
          await api
            .get("/notifications/secrets?platform=WEB-CHROME")
            .then((res) => {
              const OneSignal = window.OneSignal || [];
              OneSignal.push(() => {
                OneSignal.init({
                  appId: res.data.app_id,
                  notifyButton: {
                    enable: true,
                  },
                });
              });

              OneSignal.push(function () {
                OneSignal.on("subscriptionChange", function (isSubscribed) {
                  console.log(
                    "The user's subscription state is now:",
                    isSubscribed
                  );
                  OneSignal.push(function () {
                    OneSignal.getUserId(function (userId) {
                      console.log("OneSignal User ID:", userId);

                      api.post(
                        "/notifications/" +
                          userId +
                          "/subscribe?platform=WEB-CHROME"
                      );
                      api.post(
                        "/notifications/" +
                          userId +
                          "/subscribe?platform=WEB-FIREFOX"
                      );
                    });
                  });
                });
              });
            });
        }
      } catch (error) {
        console.log("Error while initNotifications > ", error.message);
      }
    },
    async SUBSCRIBE({ data }) {
      await api.post(
        "/notifications/" + data + "/subscribe?platform=WEB-CHROME"
      );
      await api.post(
        "/notifications/" + data + "/subscribe?platform=WEB-FIREFOX"
      );
    },
  },
  getters: {
    // access_token: (state) => state.access_token,
  },
  actions: {
    init(context) {
      context.commit("init");
    },
    subscribe(context, { data }) {
      context.commit("SUBSCRIBE", { data });
    },
  },
};
