<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0"></div>
    <div class="content-header px-0">
      <h1 class="title-I">Editar Tag</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <div class="card-body">
            <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="card direct-chat direct-chat-primary">
                    <div class="card-body custom-card overflow-visible">
                      <ul class="nav nav-tabs bg-main">
                        <li class="nav-item">
                          <a
                            id="addTag-tab"
                            class="nav-link first"
                            v-bind:class="{
                              active: addTagSelected,
                            }"
                            v-on:click="changeTab('addTag')"
                            href="#"
                            aria-current="addTag"
                            tabindex="-1"
                            >Editar Tag</a
                          >
                        </li>

                        <li class="nav-item active">
                          <a
                            class="nav-link"
                            v-bind:class="{
                              active: this.tabSelected === 'vocalizationData',
                            }"
                            v-on:click="changeTab('vocalizationData')"
                            href="#"
                            aria-current="vocalizationData"
                            tabindex="-1"
                            id="vocalizationData"
                            >Editar dados de vocalização</a
                          >
                        </li>
                      </ul>

                      <div class="p-4 bg-white rounded">
                        <div class="tab-content">
                          <div
                            class="tab-pane fade"
                            v-bind:class="{
                              show: addTagSelected,
                              active: addTagSelected,
                            }"
                          >
                            <div class="row">
                              <form @submit="updateTag" novalidate="true">
                                <div class="row">
                                  <TwInput label="Modelo" required="false">
                                    <select
                                      class="form-control"
                                      v-model="tag.tag_model"
                                      id="tag-model-selector"
                                    >
                                      <option
                                        v-for="(model, index) in models"
                                        :key="index"
                                        class="p-2"
                                        :value="model.name"
                                      >
                                        {{ model.name }}
                                      </option>
                                    </select>
                                  </TwInput>
                                  <TwInput label="Nome da Tag" required="false">
                                    <input
                                      required
                                      v-model="tag.tag_label"
                                      type="text"
                                      maxlength="50"
                                      class="form-control"
                                      placeholder="Nome da Tag"
                                      id="tagname"
                                    />
                                  </TwInput>
                                  <TwInput label="Tipo" required="false">
                                    <select
                                      required
                                      class="form-control"
                                      v-model="tag.tag_type"
                                      placeholder="Tipo de Tag"
                                      id="tagtypesel"
                                    >
                                      <option
                                        v-for="(tagType, index) in tagTypes"
                                        :key="index"
                                        class="p-2"
                                        :value="tagType.name"
                                      >
                                        {{ tagType.name }}
                                      </option>
                                    </select>
                                  </TwInput>

                                  <TwInput
                                    label="Identificador"
                                  >
                                    <input
                                      id="edit-tag-identifier"
                                      v-model="tag.mac_address"
                                      v-mask="'NN:NN:NN:NN:NN:NN'"
                                      type="text"
                                      class="form-control"
                                      placeholder="Identificador"
                                      disabled
                                    />
                                  </TwInput>
                                </div>
                                <div class="row p-1">
                                  <button
                                    id="saveTag"
                                    type="submit"
                                    class="btn btn-primary px-5"
                                    :disabled="load"
                                  >
                                    <i
                                      v-if="load"
                                      class="fas fa-sync-alt fa-spin"
                                    ></i>
                                    Salvar
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>

                          <div
                            class="tab-pane fade"
                            v-bind:class="{
                              show: vocalizationDataSelected,
                              active: vocalizationDataSelected,
                            }"
                          >
                            <div class="row">
                              <TwInput label="Text message" required="false">
                                <input
                                  required
                                  v-model="tag.tag_msg.text"
                                  type="text"
                                  class="form-control"
                                  placeholder="Text message"
                                  id="textmessage"
                                />
                              </TwInput>
                              <TwInput label="Voice message" required="false">
                                <input
                                  required
                                  v-model="tag.tag_msg.voice"
                                  type="text"
                                  class="form-control"
                                  placeholder="Voice message"
                                  id="voicemessage"
                                />
                              </TwInput>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fim Prim div -->
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import "./style.scss";

export default {
  name: "AddTag",
  tag: {},
  data: function () {
    return {
      tabSelected: "addTag",
      breadcrumbList: [],
      modelsSelecteds: [],
      tag: {
        tag_state_operational_state: "",
        tag_state_administrative_state: "",
        tag_state_battery_level: "",
        tag_type: "",
        mac_address: "",
        tag_label: "",
        tag_model: "",
        tag_msg: {
          text: "",
          voice: "",
        },
      },
    };
  },
  mounted() {
    if (!this.$route.params.tag) {
      // set this.$route.params.user to the object inside the localStorage
      this.tag = JSON.parse(localStorage.getItem("tagedit"));
      if (!this.tag) {

        window.frontMsg(
          "FRNT_MISSING_WARNING",
          {frontelement: "da Tag"}
        );
        
        this.$router.push("/Tag");
      }
    }
    else{
      // Save the data to localstorage
      this.tag = this.$route.params.tag;
      localStorage.setItem("tagedit", JSON.stringify(this.$route.params.tag));
    }
    
    this.$store.dispatch("tag/getModels");
    this.$store.dispatch("tag/getTagTypes");
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    isTagValid(tag) {
      if (!tag.tag_model || tag.tag_model.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Modelo da Tag" }
        );
        return false;
      }

      if (!tag.tag_label || tag.tag_label.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome da Tag" }
        );
        return false;
      }

      if (!tag.tag_type || tag.tag_type.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Tipo da Tag" }
        );
        return false;
      }

      if (!tag.mac_address || tag.mac_address.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Identificador" }
        );
        return false;
      }

      if (
        /^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\1[0-9a-f]{1,2}){4}$/i.test(
          tag.mac_address
        ) === false
      ) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Identificador" }
        );
        return false;
      }

      return true;
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    updateTag() {
      event.preventDefault();
      if (!this.isTagValid(this.tag)) {
        return;
      }

      this.$store.dispatch("tag/updateTag", {
        ...this.tag,
        mac_address: this.tag.mac_address.replace(/:|-/g, ""),
      });
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    models() {
      return this.$store.getters["tag/models"];
    },
    tagTypes() {
      return this.$store.getters["tag/tagTypes"];
    },
    addTagSelected() {
      return this.tabSelected === "addTag";
    },
    vocalizationDataSelected() {
      return this.tabSelected === "vocalizationData";
    },
  },
};
</script>
