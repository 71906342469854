<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div id="buildadd-content" class="content-header px-0">
      <h1 id="buildadd-title-h1">Adicionar edifício</h1>
    </div>

    <div id="buildadd-div-master" class="col-12 col-sm-12 p-0 col-md-12">
      <div id="buildadd-div-card" class="card direct-chat direct-chat-primary">
        <div id="buildadd-div-card-body" class="card-body">
          <div id="buildadd-form-wrapper" class="p-4 bg-white rounded">
            <form
              id="buildadd-building-form"
              @submit="saveBuilding"
            >
              <div id="buildadd-row-wrapper" class="row">
                <TwInput id="buildadd-twin-bname" label="Nome" required="false">
                  <input
                    id="buildadd-bname"
                    v-model="building.building_name"
                    required
                    type="text"
                    maxlength="55"
                    class="form-control"
                    placeholder="Nome"
                  />
                </TwInput>

                <TwInput id="buildadd-twin-address" label="Endereço">
                  <input
                    id="buildadd-address"
                    v-model="building.address"
                    type="text"
                    maxlength="255"
                    class="form-control"
                    placeholder="Endereço"
                  />
                </TwInput>
              </div>

              <div id="buildadd-load-wrapper" class="row mt-2 p-1">
                <button
                  id="buildadd-save-btn"
                  class="btn btn-primary px-5"
                  :disabled="load"
                >
                  <i
                    id="buildadd-icon-load"
                    v-if="load"
                    class="fas fa-sync-alt fa-spin"
                  ></i>
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "AddBuilding",
  data: function () {
    return {
      breadcrumbList: [],
      building: {
        building_name: "",
        address: "",
        latitude: 0,
        longitude: 0,
      },
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }

    this.updateList();
  },
  watch: {
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    isBuildingValid(building) {
      if (!building.building_name || building.building_name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome do Edifício" }
        );
        return false;
      }

      return true;
    },
    async saveBuilding() {
      event.preventDefault();
      if (!this.isBuildingValid(this.building)) {
        return;
      }

      this.$store.dispatch("building/saveBuilding", {
        customer: this.customer,
        building: this.building,
      });
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
