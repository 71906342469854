var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-body font",class:{ signinjs: _vm.isActive, signupjs: _vm.isOk },attrs:{"id":"total"}},[_c('div',{staticClass:"container-login",attrs:{"id":"flogin-container"}},[_c('div',{staticClass:"content second-content",attrs:{"id":"flogin-second-con"}},[_c('div',{staticClass:"first-column",attrs:{"id":"flogin-first-col"}},[_c('img',{staticStyle:{"width":"300px"},attrs:{"id":"flogin-logo","src":require('../../assets/img/TW-yellow.svg')}}),_c('p',{staticClass:"description description-primary mt-3",attrs:{"id":"flogin-desc"}},[_vm._v(" We make ideas happen! ")]),_c('div',[_c('router-link',{attrs:{"to":"/login","id":"flogin-back-but"}},[_c('button',{staticClass:"btn-login btn-login-primary",attrs:{"id":"flogin-return-but"}},[_vm._v("Voltar")])])],1)]),_c('div',{staticClass:"second-column",attrs:{"id":"flogin-second-column"}},[_c('h2',{staticClass:"title title-second",attrs:{"id":"flogin-title-second"}},[_vm._v("Nova senha")]),_c('p',{staticStyle:{"color":"red"},attrs:{"id":"flogin-expired-pass"}},[_vm._v("Senha expirada, redefina uma nova senha")]),_c('form',{staticClass:"form mt-2 card",attrs:{"id":"flogin-login-form"},on:{"submit":_vm.firstlogin}},[_c('div',{staticClass:"form-group pass-show",attrs:{"id":"flogin-form-gp"}},[_c('p',{attrs:{"id":"flogin-email-p"}},[_vm._v("Email:")]),_c('label',{staticClass:"label-input",attrs:{"id":"flogin-label-for-form","for":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"id":"email","placeholder":"Email","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])]),_c('div',{staticClass:"form-group pass-show",attrs:{"id":"flogin-form-gp2"}},[_c('p',{attrs:{"id":"flogin-senha-p"}},[_vm._v("Senha atual:")]),_c('label',{staticClass:"label-input",attrs:{"id":"flogin-label-for-oldp","for":""}},[((!_vm.passShow ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.old_password),expression:"old_password"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"old_password","id":"old_password","placeholder":"Senha atual","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.old_password)?_vm._i(_vm.old_password,null)>-1:(_vm.old_password)},on:{"change":function($event){var $$a=_vm.old_password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.old_password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.old_password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.old_password=$$c}}}}):((!_vm.passShow ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.old_password),expression:"old_password"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"old_password","id":"old_password","placeholder":"Senha atual","type":"radio"},domProps:{"checked":_vm._q(_vm.old_password,null)},on:{"change":function($event){_vm.old_password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.old_password),expression:"old_password"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"old_password","id":"old_password","placeholder":"Senha atual","type":!_vm.passShow ? 'password' : 'text'},domProps:{"value":(_vm.old_password)},on:{"input":function($event){if($event.target.composing)return;_vm.old_password=$event.target.value}}}),_c('a',{staticClass:"icon2",attrs:{"id":"flogin-show-pass","href":"/"},on:{"click":_vm.showoldPass}},[_c('i',{class:{
                    'far fa-eye': _vm.passShow,
                    'far fa-eye-slash': !_vm.passShow,
                  },attrs:{"id":"flogin-open-eye"}})])])]),_c('div',{staticClass:"form-group pass-show",attrs:{"id":"flogin-form-gp-newpass"}},[_c('p',{attrs:{"id":"flogin-new-pass-p"}},[_vm._v("Nova senha:")]),_c('label',{staticClass:"label-input",attrs:{"id":"flogin-label-newpass","for":""}},[((!_vm.passShow2 ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_password),expression:"new_password"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.new_passwordError && !_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"new_password","id":"new_password","placeholder":"Nova senha","required":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.new_password)?_vm._i(_vm.new_password,null)>-1:(_vm.new_password)},on:{"change":function($event){var $$a=_vm.new_password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.new_password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.new_password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.new_password=$$c}}}}):((!_vm.passShow2 ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_password),expression:"new_password"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.new_passwordError && !_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"new_password","id":"new_password","placeholder":"Nova senha","required":"","type":"radio"},domProps:{"checked":_vm._q(_vm.new_password,null)},on:{"change":function($event){_vm.new_password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_password),expression:"new_password"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.new_passwordError && !_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"new_password","id":"new_password","placeholder":"Nova senha","required":"","type":!_vm.passShow2 ? 'password' : 'text'},domProps:{"value":(_vm.new_password)},on:{"input":function($event){if($event.target.composing)return;_vm.new_password=$event.target.value}}}),_c('a',{staticClass:"icon2",attrs:{"id":"flogin-show-newp-a","href":"/"},on:{"click":_vm.newshowPass}},[_c('i',{class:{
                    'far fa-eye': _vm.passShow2,
                    'far fa-eye-slash': !_vm.passShow2,
                  },attrs:{"id":"flogin-open-eye2"}})])])]),_c('div',{staticClass:"form-group pass-show",attrs:{"id":"flogin-form-gp-confirmp"}},[_c('p',{attrs:{"id":"flogin-form-gp-confirmp-p"}},[_vm._v("Confirmar nova senha:")]),_c('label',{staticClass:"label-input",attrs:{"id":"flogin-label-for-cpass","for":""}},[((!_vm.passShow3 ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmpassword),expression:"confirmpassword"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.new_passwordError && !_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"confirmpassword","id":"confirmpassword","placeholder":"Confirmar senha","required":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.confirmpassword)?_vm._i(_vm.confirmpassword,null)>-1:(_vm.confirmpassword)},on:{"change":function($event){var $$a=_vm.confirmpassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.confirmpassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.confirmpassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.confirmpassword=$$c}}}}):((!_vm.passShow3 ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmpassword),expression:"confirmpassword"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.new_passwordError && !_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"confirmpassword","id":"confirmpassword","placeholder":"Confirmar senha","required":"","type":"radio"},domProps:{"checked":_vm._q(_vm.confirmpassword,null)},on:{"change":function($event){_vm.confirmpassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmpassword),expression:"confirmpassword"}],staticClass:"formzinho form-control",staticStyle:{"height":"45px","width":"100%"},style:(_vm.new_passwordError && !_vm.bad_email
                    ? 'border: 1px solid red;'
                    : 'height: 45px; width: 100%'),attrs:{"name":"confirmpassword","id":"confirmpassword","placeholder":"Confirmar senha","required":"","type":!_vm.passShow3 ? 'password' : 'text'},domProps:{"value":(_vm.confirmpassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmpassword=$event.target.value}}}),_c('a',{staticClass:"icon2",attrs:{"id":"flogin-showpass-3","href":"/"},on:{"click":_vm.confirmshowPass}},[_c('i',{class:{
                    'far fa-eye': _vm.passShow3,
                    'far fa-eye-slash': !_vm.passShow3,
                  },attrs:{"id":"flogin-open-eye3"}})])]),_c('div',[(!_vm.new_passwordError)?_c('small',{staticClass:"form-text text-muted",staticStyle:{"font-size":"105%"},attrs:{"id":"flogin-form-gp-confirmp-small"}},[_vm._v("Mínimo 8 caracteres, 1 maiúscula, 1 minúscula, 1 especial e 1 número")]):_vm._e(),(_vm.new_passwordError && !_vm.bad_email)?_c('small',{staticClass:"form-text",staticStyle:{"color":"red","font-size":"105%"},attrs:{"id":"flogin-form-gp-confirmp-small2"}},[_vm._v("Mínimo 8 caracteres, 1 maiúscula, 1 minúscula, 1 especial e 1 número")]):_vm._e(),_c('small',{staticClass:"form-text text-muted",staticStyle:{"font-size":"85%","font-weight":"bold"},attrs:{"id":"flogin-form-gp-confirmp-small3"}},[_vm._v("Exemplo: MMaa#*01")])])]),_c('button',{staticClass:"btn-login btn-login-pass",staticStyle:{"color":"white"},attrs:{"id":"login-entrar"}},[_vm._v(" Redefinir senha ")]),(_vm.load)?_c('div',{staticClass:"overlay text-center",attrs:{"id":"flogin-loading-overlay"}},[_c('i',{staticClass:"fas fa-sync-alt fa-spin",attrs:{"id":"flogin-loading-i"}})]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }