<template>
  <div id="basicb-content" class="mt-3">
    <form id="basicb-form-update" @submit="updateBuilding" novalidate="true">
      <div id="basicb-row-wrapper" class="row">
        <div id="basicb-col-wrapper" class="col-6 pr-4">
          <div id="basicb-form-gp" class="form-group">
            <label>
              Nome
              <span id="basicb-required" class="required">*</span>
            </label>

            <input
              id="basicb-bname"
              v-model="buildingEdit.building_name"
              required
              type="text"
              maxlength="55"
              class="form-control"
              placeholder="Nome"
              :disabled="!hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])"
            />
          </div>
        </div>
        <div id="basicb-col-wrapper2" class="col-6 pl-4">
          <div id="basicb-form-gp2" class="form-group">
            <label>Endereço</label>
            <input
              id="basicb-address"
              v-model="buildingEdit.address"
              type="text"
              maxlength="255"
              class="form-control"
              placeholder="Endereço"
              :disabled="!hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])"
            />
          </div>
        </div>
      </div>

      <div id="basicb-save-wrapper" class="row mt-2 p-1">
        <button
          id="basicb-save-data"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          class="btn btn-primary px-5"
          :disabled="load"
        >
          <i id="basicb-load-i" v-if="load" class="fas fa-sync-alt fa-spin"></i>
          Salvar
        </button>
      </div>
    </form>
    <div id="basicb-row-wrapper2" class="row d-flex flex-row-reverse">
      <button
        id="buttonFloors"
        @click="goFloors"
        type="button"
        class="btn btn-primary"
      >
        Andares <i id="basicb-right-i" class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import hasRole from "@/utils/hasRole";

export default {
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
      return;
    }

    if (this.$route.params.buildingId) {
      this.$store.dispatch("building/getBuilding", {
        buildingId: this.$route.params.buildingId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Edifício" });
      this.$router.push("/");
      return;
    }
  },
  watch: {
    building() {
      this.buildingEdit = {
        id: this.building.id,
        building_name: this.building.building_name ?? "",
        address: this.building.address ?? "",
        latitude: this.building.latitude ?? 0,
        longitude: this.building.longitude ?? 0,
      };
    },
  },
  data() {
    return {
      buildingEdit: {
        id: "",
        building_name: "",
        address: "",
        latitude: 0,
        longitude: 0,
      },
    };
  },
  methods: {
    hasRole,
    isBuildingValid(building) {
      if (!building.building_name || building.building_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome do Edifício" });
        return false;
      }

      return true;
    },
    updateBuilding() {
      event.preventDefault();
      if (!this.isBuildingValid(this.buildingEdit)) {
        return;
      }

      this.$store.dispatch("building/updateBuilding", {
        customer: this.customer,
        building: this.buildingEdit,
      });
    },
    goFloors() {
      this.$emit("next");
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    building() {
      return this.$store.getters["building/building"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
  },
};
</script>

<style></style>
