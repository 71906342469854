var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");
//verifica se o usuário logado tem alguma das roles recebidas como parametro
export default function hasRole(roles) {
  const user_roles =
    localStorage.getItem("rememberMe") === "true"
      ? localStorage.getItem("roles")
      : sessionStorage.getItem("roles");
  
  let decrypted = CryptoJS.AES.decrypt(user_roles, "LQcL5RAVPVn7y0PJ");
  let plaintext = decrypted.toString(CryptoJS.enc.Utf8);
  const userRoles = JSON.parse(plaintext.replaceAll("'", '"'));
  let hasRole = userRoles.find((role) => {
    return roles.includes(role);
  });
  if (hasRole) {
    return true;
  } else {
    return false;
  }
}
