import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
// import router from '../router'

export default {
  namespaced: true,
  state: {
    zone: [],
    zones: [],
    allGeofences: [],
  },
  mutations: {
    GET_ZONES(state, data) {
      state.zones = data;
    },
    SET_ZONE(state, data) {
      state.zone = data;
    },
    ADD_ZONE(state, data) {
      state.zones.push(data);
    },
    UPDATE_ZONE(state, data) {
      const index = state.zones.findIndex((item) => item.id === data.id);
      state.zones[index].description = data.description;
      state.zones[index].zone_color = data.zone_color;
      state.zones[index].zone_name = data.zone_name;
    },
    DELETE_ZONE(state, id) {
      state.zones = state.zones.filter((zone) => zone.id !== id);
    },
    PUSH_GEOFENCES(state, data) {
      if (data.length) state.allGeofences.push(...data);
    },
    CLEAR_ALL_GEOFENCES(state) {
      state.allGeofences = [];
    },
  },
  getters: {
    zone: (state) => state.zone,
    zones: (state) => state.zones,
    allGeofences: (state) => state.allGeofences,
  },
  actions: {
    async getZones(context, floor_id) {
      await api
        .get("floors/" + floor_id + "/zones")
        .then((res) => {
          context.commit("GET_ZONES", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Zona', "GET_ZONES");
        });
    },
    async getZone(context, zone_id) {
      await api
        .get("/zones/" + zone_id)
        .then((res) => {
          context.commit("SET_ZONE", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Zona', "GET_ZONE");
        });
    },
    async saveZone(context, { floor_id, sendZone }) {
      if (!sendZone.id) {
        await api
          .post("floors/" + floor_id + "/zones", sendZone)
          .then((res) => {
            context.commit("ADD_ZONE", res.data);
            displayErrMsg("GLOBAL_SUCCESS_ADD", 'Zona', "SAVE_ZONE");
          })
          .catch((err) => {
            // TODO: Special cases
            // console.log(err.response.code);
            // if (err.response.data.code === 409) {
            //   window.toastr.error("Já existe uma zona com esse nome", "Zona");
            // } else if (
            //   err.response.data.code === 400 &&
            //   err.response.data.message.includes("color")
            // ) {
            //   window.toastr.error("Especifique uma cor", "Zona");
            // } else {
            //   window.toastr.error("Erro ao salvar dados", "Zona");
            // }
            displayErrMsg(err.response.data.code, 'Zona', "SAVE_ZONE");
          });
      } else {
        context.dispatch("updateZone", {
          floor: { id: floor_id },
          zone: sendZone,
        });
      }
    },
    async updateZone(context, data) {
      await api
        .put("floors/" + data.floor.id + "/zones", {
          description: data.zone.description,
          id: data.zone.id,
          zone_color: data.zone.zone_color,
          zone_column: data.zone.zone_column,
          zone_name: data.zone.zone_name,
        })
        .then((res) => {
          context.commit("UPDATE_ZONE", res.data);
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Zona', "UPDATE_ZONE");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Zona', "UPDATE_ZONE");
        });
    },
    async deleteZone(context, id) {

      await api
        .delete(`zones/${id}`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Zona', "DELETE_ZONE");
          context.commit("DELETE_ZONE", id);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Zona', "DELETE_ZONE");
        });
    },
    async getGeofencesByZone(context, zone_id) {
      await api
        .get(`/zones/${zone_id}/geofences`)
        .then((response) => {
          context.commit("PUSH_GEOFENCES", response.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Geofences', "GET_GEOFENCES_BY_ZONE");
        });
    },
  },
};
