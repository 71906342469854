<template>
  <div class="hello">
    <ol class="breadcrumb mb-0 p-0">
      <li
        v-for="(breadcrumb, index) in breadcrumbList"
        :key="index"
        class="breadcrumb-item"
        v-bind:class="{
          active: !!breadcrumb.link,
        }"
        tabindex="-1"
      >
        <router-link
          v-if="breadcrumb.link"
          :to="{ name: breadcrumb.name, params: breadcrumb.params }"
          tabindex="-1"
        >
          {{ breadcrumb.label }}
        </router-link>
        <span tabindex="-1" v-else>{{ breadcrumb.label }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    breadcrumbList: Array,
  },
};
</script>
