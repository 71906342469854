<template>
  <div>
    <div class="content-header px-0">
      <h1 class="title-I">Clientes cadastrados</h1>
    </div>

    <div class="form-inline col-12 p-0">
      <div class="pl-0 col-10 filterInput">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control col-11 w-100"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </div>
      <router-link
        id="addcustomer"
        v-can="['ADMIN']"
        title="Adicionar cliente"
        to="/customer/add"
        class="btn btn-primary add-user col-2"
      >
        <span class="d-none d-md-block d-lg-block">Adicionar </span>
        <span class="d-block d-md-none d-lg-none">
          <i class="fas fa-plus-circle"></i>
        </span>
      </router-link>
    </div>

    <b-table
      style="background-color: white"
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum cliente encontrado para essa busca"
      :busy="load"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :items="customer"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
        />
      </template>
      <template v-slot:cell(group)="data">{{ data.item.group.name }}</template>
      <template v-slot:cell(actions)="data">
        <a
          id="excluircustomer"
          @click="localCustomer = data.item"
          v-can="['ADMIN']"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#modalDeleteCustomer"
          title="Excluir"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>
        <router-link
          class="mr-3"
          :to="{
            name: 'customer-add-tags',
            params: { customerId: data.item.id },
          }"
          v-can="['ADMIN']"
          title="Associar tags"
          id="associartags"
        >
          <i class="fas fa-tag iconspace"></i>
        </router-link>
        <router-link
          class="mr-3"
          :to="{
            name: 'customer-add-gateways',
            params: { customerId: data.item.id },
          }"
          v-can="['ADMIN']"
          title="Associar coletores"
          id="coletoresassociar"
        >
        <i class="fas fa-tower-cell iconspace"></i>
        </router-link>
        <a
          id="editcustomer"
          @click="editCustomer(data.item)"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER','FRONTDESK','DASHBOARD','USER']"
          class="mr-3"
          title="Editar"
          style="cursor: pointer"
        >
          <i class="fas fa-pen iconspace"></i>
        </a>
      </template>
    </b-table>

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>

    <Modal
      id="modalDeleteCustomer"
      title="Excluir cliente"
      v-bind:msg="
        'Tem certeza que deseja excluir ' + localCustomer.company_name + '?'
      "
      button="Excluir"
      :method="deleteCustomer"
    />
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import hasRole from "@/utils/hasRole";

export default {
  name: "ManagerCustomer",
  data() {
    return {
      filter: null,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortBy: "company_name",
      localCustomer: "",
      fields: [
        { key: "company_name", sortable: true, label: "Nome" },
        { key: "cnpj", sortable: true, label: "CNPJ" },
        { key: "group", sortable: true, label: "Grupo" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("customer/get");
    this.$store.dispatch("customer/updateTabIndex", 0);
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customer) {
      this.rows = customer.length;
    },
    deleteCustomer() {
      event.preventDefault();
      this.$store.dispatch("customer/deleteCustomer", {
        id: this.localCustomer.id,
      });
    },
    editCustomer(data) {
      this.$router.push({
        name: "customer-edit",
        params: { customerId: data.id },
      });
    },
  },
  watch: {
    customer() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customer.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.customer.length;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customers"];
    },
  },
  components: {
    Modal,
  },
};
</script>

<style>
.change-my-color {
  fill: green;
}
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
