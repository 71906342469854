<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a id="header-pushmenu" class="nav-link" data-widget="pushmenu" href="#">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </ul>
    <form class="form-inline ml-3">
    </form>
  </nav>
</template>

<script>
export default {
  name: "Header",
  mounted() {
    this.$store.dispatch("notification/init");
  },
};
</script>

<style></style>
