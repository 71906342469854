import { api } from "../services/api";
import router from "../router";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    gatewayconfig: [],
    gatewayStatus: [],
    deletedConfig: [],
    gateways: [],
    config: [],
    models: [],
    types: [],
    loadingCustomerGateways: false,
    loadingGateways: false,
    customerGateways: [],
    loadingAssign: false,
    loadingUnassign: false,
    bulkLoad: false,
    bulkErrors: [],
    availableGateways: [],
    successGateways: [],
    badGateways: [],
  },

  mutations: {
    SET_BULK_LOAD(state, value) {
      state.bulkLoad = value;
    },
    SET_BULK_ERRORS(state, value) {
      state.bulkErrors = value;
    },
    PUSH_BULK_ERRORS(state, value) {
      state.bulkErrors.push(value);
    },
    SET_GATEWAYS(state, value) {
      state.gateways = value;
    },
    SET_AVAILABLE_GATEWAYS(state, data) {
      state.availableGateways = data;
    },
    async SAVE_GATEWAY(state, { data }) {
      await api
        .post(`gateways`, data)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", 'Coletor', 'SAVE_GATEWAY');
          let { data } = res;
          state.gateways.push({ data });
          router.push({ path: "/gateway" });
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'SAVE_GATEWAY');
        });
    },

    // CONFIG /////////////////////
    async SAVE_CONFIG_GATEWAY(state, { data }) {
      let x = {
        transmission: data.transmission,
        app: data.app,
        ble: data.ble,
        http: data.http,
        mqtt: data.mqtt,
        antena: data.antena,
        autoprovision: data.autoprovision,
      };
      // Send only one and then API sets default values
      if (x.transmission === "mqtt") {
        delete x.http;
      }
      if (x.transmission === "http") {
        delete x.mqtt;
      }
      await api
        .post(`gateways/mac_address/${data.mac_address}/config`, x)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", 'Coletor', 'SAVE_CONFIG_GATEWAY');
          let { data } = res;
          state.gateways.push({ data });
          router.push({ path: "/gateway" });
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'SAVE_CONFIG_GATEWAY');
        });
    },

    // CONFIG ///////////////
    async SAVE_GATEWAYS(state, { data }) {
      state.successGateways = [];
      state.badGateways = [];
      let temp = JSON.parse(JSON.stringify(data.gateways));
      // remove gateway_config from all objects inside temp
      temp.forEach((element) => {
        delete element.gateway_config;
      });
      temp = { gateways: temp };
      await api
        .post(`gateways/list`, temp)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Coletor", "SAVE_GATEWAYS");
          let resData = res.data;
          resData.forEach((element) => {
            state.gateways.push(element);
          })
          data.gateways.forEach(async (element) => {
            if (element.gateway_config) {
              delete element.gateway_config.mac_address;
              let formattedMac = element.mac_address
                .split(":")
                .join("")
                .toLowerCase();
              await api
                .post(
                  `gateways/mac_address/${formattedMac}/config`,
                  element.gateway_config
                )
                .then(() => {
                  state.successGateways.push(formattedMac);
                })
                .catch((err) => {
                  state.badGateways.push(formattedMac);
                  displayErrMsg(err.response.status, 'Coletor', 'SAVE_CONFIGS');
                });
            } else {
              state.successGateways.push(
                element.mac_address.split(":").join("").toLowerCase()
              );
            }
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'SAVE_GATEWAYS');
        });
    },
    async SAVE_CONFIGS(state, { data }) {
      state.successGateways = [];
      state.badGateways = [];
      data.gateways.forEach(async (element) => {
        if (element.gateway_config) {
          delete element.gateway_config.mac_address;
          let formattedMac = element.mac_address
            .split(":")
            .join("")
            .toLowerCase();
          await api
            .post(
              `gateways/mac_address/${formattedMac}/config`,
              element.gateway_config
            )
            .then(() => {
              state.successGateways.push(formattedMac);
            })
            .catch((err) => {
              state.badGateways.push(formattedMac);
              displayErrMsg(err.response.status, 'Coletor', 'SAVE_CONFIGS');
            });
        }
      });
    },
    UPDATE_GATEWAY(state, payload) {
      const index = state.gateways.findIndex((gateway) => {
        return payload.id === gateway.id;
      });
      if (index > -1) {
        state.gateways[index] = payload;
      } else {
        state.gateways.push(payload);
      }
    },
    async DELETE_GATEWAY(state, { data }) {
      await api
        .delete(`gateways/mac_address/${data.mac_address}`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Coletor', 'DELETE_GATEWAY');
          state.gateways = state.gateways.filter(
            (user) => user.mac_address !== data.mac_address
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'DELETE_GATEWAY');
        });
    },

    // CONFIG /////////////////////
    async DELETE_CONFIG(state, { data }) {
      await api
        .delete(`gateways/mac_address/${data.mac_address}/config`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Config', 'DELETE_CONFIG');
          state.gateways = state.gateways.filter(
            (user) => user.mac_address !== data.mac_address
          );
          state.deletedConfig = data.mac_address;
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'DELETE_CONFIG');
        });
    },
    async GET_GATEWAY(state) {
      await api.get("gateways?sortBy=id").then((response) => {
        state.gateways = response.data.reverse();
      })
      .catch((err) => {
        displayErrMsg(err.response.status, 'Coletor', 'GET_GATEWAY');
      });
    },
    DEL_GATEWAY(state, id) {
      state.gateways = state.gateways.filter((gateway) => {
        return gateway.id !== id;
      });
    },
    async GET_MODELS(state) {
      await api.get("gateways/models").then((response) => {
        state.models = response.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.status, 'Coletor', 'GET_MODELS');
      });
    },
    async GET_TYPES(state) {
      await api.get("gateways/types").then((response) => {
        state.types = response.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.status, 'Coletor', 'GET_TYPES');
      });
    },
    async GET_STATUS_GATEWAY(state, { data }) {
       await api
          .get(`gateways/mac_address/${data.mac_address}`)
          .then((response) => {
            state.gatewayStatus = response.data;
          })
          .catch((err) => {
            displayErrMsg(err.response.status, 'Coletor', 'GET_STATUS_GATEWAY');
          });
    },
    async GET_CONFIG(state, { data }) {
      await api
        .get(`gateways/mac_address/${data.mac_address}/config`)
        .then((response) => {
          state.gatewayconfig = response.data;
          state.gatewayconfig.exists = true;
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'GET_CONFIG');
          state.gatewayconfig.exists = false;
        });
    },
    async GET_CUSTOMER_GATEWAYS(state, { data }) {
      state.loadingCustomerGateways = true;

      await api.get("gateways/customer/" + data.customer_id).then((res) => {
        state.customerGateways = res.data;
        state.loadingCustomerGateways = false;
      })
      .catch((err) => {
        displayErrMsg(err.response.status, 'Coletor', 'GET_CUSTOMER_GATEWAYS');
      });
      state.loadingCustomerGateways = false;
    },
    async ASSIGN_GATEWAY_TO_CUSTOMER(state, { data }) {
      state.loadingAssign = true;

      const send_gateway_list = data.gateway_list.filter((id) => {
        return state.availableGateways.some((gateway) => {
          return gateway.id === id;
        });
      });
      if (send_gateway_list.length) {
        await api
          .patch("gateways/assign/" + data.customer_id, {
            id_list: send_gateway_list,
          })

          .then(() => {
            state.loadingAssign = false;

            // Remove gateways from available gateways
            // push gateways to customer gateways
            state.availableGateways = state.availableGateways.filter(
              (gateway) => {
                if (send_gateway_list.includes(gateway.id)) {
                  state.customerGateways.push(gateway);
                  return false;
                }
                return true;
              }
            );
            displayErrMsg("ASSIGN_GATEWAY_SUCCESS", 'Coletor', 'ASSIGN_GATEWAY_TO_CUSTOMER');
          })

          .catch((err) => {
            state.loadingAssign = false;
            displayErrMsg(err.response.status, 'Coletor', 'ASSIGN_GATEWAY_TO_CUSTOMER');
          });
      } else {
        state.loadingAssign = false;
        displayErrMsg("ASSIGN_GATEWAY_NO_GATEWAY", 'Coletor', 'ASSIGN_GATEWAY_TO_CUSTOMER');
      }
    },
    async UNASSIGN_GATEWAY_FROM_CUSTOMER(state, { data }) {
      state.loadingCustomerGateways = true;
      const send_gateway_list = data.gateway_list.filter((id) => {
        return state.customerGateways.some((gateway) => {
          return gateway.id === id;
        });
      });

      if (send_gateway_list.length) {
        await api
          .patch("gateways/unassign/" + data.customer_id, {
            id_list: send_gateway_list,
          })
          .then(() => {
            displayErrMsg("UNASSIGN_GATEWAY_SUCCESS", 'Coletor', 'UNASSIGN_GATEWAY_FROM_CUSTOMER');

            state.customerGateways = state.customerGateways.filter(
              (gateway) => {
                if (!data.gateway_list.includes(gateway.id)) {
                  return gateway;
                } else {
                  state.availableGateways.push(gateway);
                }
              }
            );
            state.loadingCustomerGateways = false;
          })
          .catch((err) => {
            state.loadingCustomerGateways = false;
            displayErrMsg(err.response.status, 'Coletor', 'UNASSIGN_GATEWAY_FROM_CUSTOMER');
          });
      }
    },
    async UNASSIGN_GATEWAY_FROM_CUSTOMER_EACH(state, { data }) {
      await api
        .patch(
          "gateways/" +
            data.gateway.id +
            "/customer/" +
            data.customer_id +
            "/unassign"
        )
        .then(() => {
          displayErrMsg("UNASSIGN_GATEWAY_SUCCESS", 'Coletor', 'UNASSIGN_GATEWAY_FROM_CUSTOMER_EACH');

          state.gateways.push(data.gateway);
          const i = state.customerGateways.findIndex(
            (gatewayObj) => gatewayObj == data.gateway
          );
          state.customerGateways.splice(i, 1);
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'UNASSIGN_GATEWAY_FROM_CUSTOMER_EACH');
        });
    },
    async BULK_PASTE_CONFIG(state, { data }) {
      const destination = data.selected.map((gateway) => {
        return gateway.mac_address;
      });
      const origin_mac = data.copy_config.mac_address;
      await api
        .post(`gateways/mac_address/${origin_mac}/config/copy`, {
          mac_list: destination,
        })
        .then(() => {
          displayErrMsg("PASTE_GATEWAY_CONFIG_SUCCESS", 'Coletor', 'BULK_PASTE_CONFIG');
          data.selected.forEach((gateway) => {
            const i = state.gateways.findIndex(
              (gatewayObj) => gatewayObj.mac_address == gateway.mac_address
            );
            state.gateways[i].gateway_config = data.copy_config;
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'BULK_PASTE_CONFIG');
        });
    },
    async GET_AVAILABLE_GATEWAYS(state, { data }) {
      state.loadingAvailableGateways = true;
      await api
        .get("/gateways/customer/-1")
        .then((res) => {
          state.availableGateways = res.data;
          state.loadingAvailableGateways = false;
        })
        .catch((err) => {
          state.loadingAvailableGateways = false;
          displayErrMsg(err.response.status, 'Coletor', 'GET_AVAILABLE_GATEWAYS');
        });
    },
  },
  getters: {
    gateways(state) {
      return state.gateways;
    },
    availableGateways(state) {
      return state.availableGateways;
    },
    gatewayStatus(state) {
      return state.gatewayStatus;
    },
    deletedConfig(state) {
      return state.deletedConfig;
    },
    gatewayconfig: (state) => state.gatewayconfig,
    config: (state) => state.config,
    models: (state) => state.models,
    types: (state) => state.types,
    loadingCustomerGateways: (state) => state.loadingCustomerGateways,
    loadingGateways: (state) => state.loadingGateways,
    customerGateways: (state) => state.customerGateways,
    loadingAssign: (state) => state.loadingAssign,
    bulkLoad: (state) => state.bulkLoad,
    bulkErrors: (state) => state.bulkErrors,
    loadingAvailableGateways: (state) => state.loadingAvailableGateways,
    successGateways: (state) => state.successGateways,
    badGateways: (state) => state.badGateways,
  },
  actions: {
    deleteGateway(context, data) {
      context.commit("DELETE_GATEWAY", { data });
    },
    deleteConfig(context, data) {
      context.commit("DELETE_CONFIG", { data });
    },
    saveGateway(context, data) {
      context.commit("SAVE_GATEWAY", { data });
    },
    saveConfigGateway(context, data) {
      context.commit("SAVE_CONFIG_GATEWAY", { data });
    },
    saveGateways(context, data) {
      context.commit("SAVE_GATEWAYS", { data });
    },
    saveConfigs(context, data) {
      context.commit("SAVE_CONFIGS", { data });
    },
    setAvailableGateways(context, data) {
      context.commit("SET_AVAILABLE_GATEWAYS", data);
    },
    getAvailableGateways(context, data) {
      context.commit("GET_AVAILABLE_GATEWAYS", data);
    },

    async updateGateway(context, data) {
      let sendGateway = {
        gateway_model: data.gateway_model,
        gateway_name: data.gateway_name,
        gateway_type: data.gateway_type,
        id: data.id,
        mac_address: data.mac_address,
        serial_number: data.serial_number,
      };

      // Check if this is being sent -'
      if (data.gateway_state && data.gateway_state.administrative_state) {
        sendGateway.gateway_state = {
          administrative_state: data.gateway_state.administrative_state,
          operational_state: data.gateway_state.operational_state,
        };
      }

      const res = await api.put(`gateways`, sendGateway);
      if (res.status === 200 && res.data.id) {
        context.commit("UPDATE_GATEWAY", res.data);
        displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Coletor', 'UPDATE_GATEWAY');
        localStorage.setItem("gateway", JSON.stringify(res.data));
      } else {
        displayErrMsg(res.status, 'Coletor', 'UPDATE_GATEWAY');
      }
    },
    async updateConfigGateway(context, data) {
      let x = {
        transmission: data.transmission,
        app: data.app,
        ble: data.ble,
        http: data.http,
        mqtt: data.mqtt,
        antena: data.antena,
        autoprovision: data.autoprovision,
      };
      if (x.transmission === "mqtt") {
        delete x.http;
      }
      if (x.transmission === "http") {
        delete x.mqtt;
      }
      await api
        .put(`gateways/mac_address/${data.mac_address}/config`, x)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Coletor', 'UPDATE_CONFIG_GATEWAY');
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Coletor', 'UPDATE_CONFIG_GATEWAY');
        });
    },
    get(context) {
      context.commit("GET_GATEWAY");
    },
    getModels(context) {
      context.commit("GET_MODELS");
    },
    getTypes(context) {
      context.commit("GET_TYPES");
    },
    async getStatusGateway(context, data) {
      await context.commit("GET_STATUS_GATEWAY", { data });
    },
    getConfig(context, data) {
      context.commit("GET_CONFIG", { data });
    },
    getCustomerGateways(context, data) {
      context.commit("GET_CUSTOMER_GATEWAYS", { data });
    },
    assignGatewayToCustomer(context, data) {
      context.commit("ASSIGN_GATEWAY_TO_CUSTOMER", { data });
    },
    unassignGatewayFromCustomer(context, data) {
      context.commit("UNASSIGN_GATEWAY_FROM_CUSTOMER", { data });
    },
    unassignGatewayFromCustomerEach(context, data) {
      context.commit("UNASSIGN_GATEWAY_FROM_CUSTOMER_EACH", { data });
    },
    bulkPasteConfig(context, data) {
      context.commit("BULK_PASTE_CONFIG", { data });
    },
    async bulkSaveGateways(context, data) {
      let gateways = [];
      data.forEach((gateway) => {
        const sendGateway = {
          gateway_model: gateway.gateway_model,
          gateway_name: gateway.gateway_name,
          gateway_state: {
            administrative_state: gateway.gateway_state.administrative_state,
            operational_state: gateway.gateway_state.operational_state,
          },
          gateway_type: gateway.gateway_type,
          id: gateway.id,
          mac_address: gateway.mac_address,
          serial_number: gateway.serial_number,
        };
        gateways.push(sendGateway);
      });
      try {
        await api.put(`gateways/list`, { gateways: gateways });
        displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Coletor', 'BULK_SAVE_GATEWAYS');
      } catch (err) {
        displayErrMsg(err.response.status, 'Coletor', 'BULK_SAVE_GATEWAYS');
      }

      const res = await api.get("gateways?sortBy=gateway_label");
      context.commit("SET_GATEWAYS", res.data);

      context.commit("SET_BULK_LOAD", false);
    },
    async bulkDeleteGateways(context, data) {
      let query = "";
      data.forEach((gateway) => {
        query += `id=${gateway.id}&`;
      });
      try {
        const resp = await api.delete(`gateways/list?${query}`);
        if (resp.status === 204) {
          data.forEach((gateway) => {
            context.commit("DEL_GATEWAY", gateway.id);
          });
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Coletores', 'BULK_DELETE_GATEWAYS');
        }
      } catch (err) {
        displayErrMsg(err.response.status, 'Coletor', 'BULK_DELETE_GATEWAYS');
      }
    },
  },
};
