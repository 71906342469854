<template>
  <div class="d-flex">
    <canvas
      :height="height"
      :width="width"
      id="my-canvas"
      ref="my-canvas"
    ></canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vueCanvas: null,
      imgHeight: 0,
      imgWidth: 0,
      imgProporcao: 0,
      x: 0,
      y: 0,
      p1x: null,
      p1y: null,
      p2x: null,
      p2y: null,
      pointX: null,
      pointY: null,
      reduceFactor: 1,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
      required: false,
    },
    height: {
      type: String,
      default: null,
      required: false,
    },
    width: {
      type: String,
      default: null,
      required: false,
    },
    map: {
      type: String,
      default: null,
      required: false,
    },
  },
  mounted() {
    var c = document.getElementById("my-canvas");
    var ctx = c.getContext("2d");

    this.vueCanvas = ctx;

    this.renderImage();

    //

    if (this.type === "onePoint") {
      c.addEventListener("mousedown", (e) => {
        if (!this.pointX && !this.pointY) {
          this.pointX = e.offsetX;
          this.pointY = e.offsetY;
          this.drawDot(this.pointX, this.pointY, "red");

          setTimeout(() => {
            this.$emit("point", {
              x: this.pointX * this.reduceFactor,
              y: this.pointY * this.reduceFactor,
              imgWidth: this.imgWidth * this.reduceFactor,
              imgHeight: this.imgHeight * this.reduceFactor,
            });
          }, 1000);
        }
      });
    } else {
      c.addEventListener("mousedown", (e) => {
        if (this.p1x && this.p1y && this.p2x && this.p2y) {
          this.renderImage();
          this.p1x = this.p1y = this.p2x = this.p2y = null;
        } else if (this.p1x === null && this.p1y === null) {
          this.p1x = e.offsetX;
          this.p1y = e.offsetY;
          this.drawDot(this.p1x, this.p1y, "red");
          //desenhar ponto 1
        } else {
          this.p2x = e.offsetX;
          this.p2y = e.offsetY;
          this.drawDot(this.p2x, this.p2y, "red");
          this.drawLine(this.p1x, this.p1y, this.p2x, this.p2y, "red");
          setTimeout(() => {
            const distPX = this.calcDistance(
              this.p1x,
              this.p1y,
              this.p2x,
              this.p2y
            );

            /*
            Permite apenas numeros positivos com decimais opcionais(permite" .5")
            */
            const EntradaValida = /^\d*\.?\d*$/;
            const EntradaZero = /^[0]+$/;
            let metros = 0;
            let tryNewInput = true;
            while (tryNewInput) {
              metros = window.prompt("Essa reta representa quanto em metros?");
              /*If cancel is pressed, metros receive null value and it shouldn't send a error message*/
              if (metros == null) break;
              if (!EntradaValida.test(metros) || EntradaZero.test(metros)) {
                window.alert(
                  "Entrada Inválida\nUse números positivos ou decimais com ponto\nExemplo: 10 ou 12.5 "
                );
              } else {
                tryNewInput = false;
              }
            }

            this.$emit("distance", (distPX / metros) * this.reduceFactor);
          }, 500);
          //desenhar ponto 2
          //desenhar linha ponto1-ponto2
          //perguntar distancia em metros da linha marcada
        }
      });
    }

    // c.addEventListener("mousemove", (e) => {
    //   // if (this.isDrawing === true) {
    //   //   this.drawLine(ctx, this.x, this.y, e.offsetX, e.offsetY);
    //   // }
    // });

    // window.addEventListener("mouseup", () => {
    //   // if (this.isDrawing === true) {
    //   //   this.isDrawing = false;
    //   // }
    // });
  },
  watch: {
    map() {
      this.renderImage();
    },
  },
  methods: {
    calcDistance(x1, y1, x2, y2) {
      var a = x1 - x2;
      var b = y1 - y2;

      return Math.sqrt(a * a + b * b);
    },
    drawLine(x1, y1, x2, y2, color) {
      this.vueCanvas.strokeStyle = color;
      this.vueCanvas.lineWidth = 1;
      this.vueCanvas.beginPath();
      this.vueCanvas.moveTo(x1, y1);
      this.vueCanvas.lineTo(x2, y2);
      this.vueCanvas.stroke();
    },
    drawDot(x, y, color) {
      this.vueCanvas.fillStyle = color;
      this.vueCanvas.lineWidth = 1;
      this.vueCanvas.beginPath();
      this.vueCanvas.arc(x, y, 3, 0, 2 * Math.PI, true);
      this.vueCanvas.fill();
    },
    renderImage() {
      // clear canvas
      this.vueCanvas.clearRect(0, 0, this.width, this.height);

      // draw rect
      if (this.map) {
        var background = new Image();
        background.src = this.map;
        background.onload = ({ target }) => {
          this.imgHeight = target.height;
          this.imgWidth = target.width;
          this.$emit("imgSize", {
            imgWidth: this.imgWidth,
            imgHeight: this.imgHeight,
          });
          this.imgProporcao = this.imgWidth / this.imgHeight;
          let drawWidth = 0;
          let drawHeight = 0;
          if (this.imgWidth >= this.imgHeight) {
            drawWidth = this.imgWidth <= 1000 ? this.imgWidth : 1000;
            drawHeight = drawWidth / this.imgProporcao;
          } else {
            drawHeight = this.imgHeight <= 1000 ? this.imgHeight : 1000;
            drawWidth = drawHeight / this.imgProporcao;
          }
          this.reduceFactor = this.imgHeight / drawHeight;
          this.vueCanvas.drawImage(background, 0, 0, drawWidth, drawHeight);
        };
      }
    },
  },
};
</script>

<style>
#my-canvas {
  border: 1px solid gainsboro;
}
</style>
