<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalLogin"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLogin"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLogin">
            Dados Sensíveis, necessário confirmação.
          </h5>
          <button
            id="pass-close-modal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="login">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Usuário</label>

                  <input
                    id="modalpass-email-in"
                    v-model="email"
                    required
                    type="text"
                    class="form-control"
                    placeholder="Usuário"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Senha</label>

                  <input
                    id="modalpass-pass-in"
                    v-model="password"
                    required
                    type="password"
                    class="form-control"
                    placeholder="Sua senha"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button id="pass-mod-login-btn" @click="login" type="button" class="btn btn-primary">
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPassword",
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      alert("login");
    },
  },
  props: {
    msg: String,
  },
};
</script>
