<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Histórico de Manutenção</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary">
          <div class="card-body">
            <div class="p-4 bg-white rounded">
              <form @submit.prevent="assignTag">
                <div class="row px-0 col-12">
                  <TwInput label="Busca" :col_lg="'8'">
                    <b-input-group>
                      <b-input-group-prepend is-text
                        ><i class="fas fa-search"></i>
                      </b-input-group-prepend>
                      <input
                        v-model="filter"
                        type="search"
                        class="form-control"
                        style="max-width: 900px"
                        id="filterInput"
                        placeholder="Buscar"
                      />
                    </b-input-group>
                  </TwInput>
                  <!--                   <div class="col-2 d-flex flex-column-reverse pb-3">
                    <b-button
                      style="background-color: #4e01d6"
                      v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
                      id="assetExport"
                      @click="
                        () => {
                          this.$router.push({ name: 'customer-asset-import' });
                        }
                      "
                      >Importar</b-button
                    >
                  </div> -->
                  <div class="col-4 d-flex flex-column-reverse pb-3">
                    <b-button
                      style="background-color: #4e01d6"
                      v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
                      id="maintenanceExport"
                      @click="maintenanceExport"
                      >Exportar</b-button
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <div>
                      <b-table
                        striped
                        hover
                        :items="actualHistory"
                        :fields="fieldsHistory"
                        ref="history_table"
                        :filter="filter"
                        @filtered="onFiltered"
                        @context-changed="changeBsTableState"
                        show-empty
                        empty-text="Nenhum registro encontrado"
                        empty-filtered-text="Nenhum registro encontrado para essa busca"
                      >
                        <template v-slot:cell(auditor)="data">
                          <b-form-input
                            v-if="data.item.editable"
                            list="auditList"
                            placeholder="Selecione"
                            v-model="data.item.auditor"
                            class="form-control"
                            id="auditor"
                            type="text"
                            autocomplete="off"
                            v-bind:style="
                              data.item.error.auditor ? 'border-color: red' : 'border-color: #ced4da'
                            "
                          ></b-form-input>
                          <datalist id="auditList" v-if="data.item.editable">
                            <option
                              v-for="mtype in auditorHistory"
                              v-bind:key="mtype"
                            >
                              {{ mtype }}
                            </option>
                          </datalist>
                          <span v-else>{{ data.item.auditor }}</span>
                        </template>
                        <template v-slot:cell(maintenance_type)="data">
                          <b-form-input
                            v-if="data.item.editable"
                            list="maintenanceTypeList"
                            placeholder="Selecione"
                            v-model="data.item.type"
                            class="form-control"
                            id="maintenanceType"
                            type="text"
                            autocomplete="off"
                            v-bind:style="
                              data.item.error.type ? 'border-color: red' : 'border-color: #ced4da'
                            "
                          ></b-form-input>
                          <datalist
                            id="maintenanceTypeList"
                            v-if="data.item.editable"
                          >
                            <option
                              v-for="mtype in maintenanceTypes"
                              v-bind:key="mtype"
                            >
                              {{ mtype }}
                            </option>
                          </datalist>
                          <span v-else>{{ data.item.type }}</span>
                        </template>
                        <template v-slot:cell(description)="data">
                          <b-form-textarea
                            v-if="data.item.editable"
                            id="description"
                            type="text"
                            v-model="data.item.description"
                            v-bind:style="
                              data.item.error.description ? 'border-color: red' : 'border-color: #ced4da'
                            "
                            placeholder="Descrição da Manutenção"
                          />
                          <span v-else>{{ data.item.description }}</span>
                        </template>
                        <template v-slot:cell(date)="data">
                          <b-form-input
                            v-if="data.item.editable"
                            id="date"
                            type="date"
                            v-model="data.item.date"
                            v-bind:style="
                              data.item.error.date ? 'border-color: red' : 'border-color: #ced4da'
                            "
                            placeholder="Data da manutenção"
                            :min="'1900-01-01'"
                            :max="'2099-12-31'"
                          />
                          <span v-else>{{ data.item.date }}</span>
                        </template>
                        <template v-slot:cell(local)="data">
                          <!-- dropdown INTERNO EXTERNO -->
                          <b-form-select
                            v-if="data.item.editable"
                            id="local"
                            v-model="data.item.local"
                            :options="localOptions"
                            v-bind:style="
                              data.item.error.local ? 'border-color: red' : 'border-color: #ced4da'
                            "
                            required
                          ></b-form-select>
                          <span v-else>{{ data.item.local }}</span>
                        </template>
                        <template v-slot:cell(actions)="data">
                          <b-button
                            v-if="data.item.editable"
                            variant="danger"
                            @click="removeRow(data.index, 2)"
                          >
                            Remover
                          </b-button>
                        </template>
                        <template #table-caption>
                          <!-- Save button to save new history -->
                          <b-button
                            v-if="
                              actualHistory.length > maintenanceHistory.length
                            "
                            variant="success"
                            @click="saveHistory"
                          >
                            Salvar
                          </b-button>
                          <b-button
                            class="btn btn-primary ml-2"
                            id="addMaintenance"
                            @click="addRow(2)"
                          >
                            <i class="fa fa-add"></i></b-button
                        ></template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import hasRole from "../../utils/hasRole";
import TwInput from "../../components/TwInput.vue";
import { formatDate, formatDateAPI } from "@/utils/format-fields.js";
const XLSX = require("xlsx");

export default {
  name: "MaintenancePage",
  data: function () {
    return {
      breadcrumbList: [],
      filter: "",
      fieldsHistory: [
        { key: "auditor", label: "Auditor" },
        { key: "maintenance_type", label: "Tipo de Manutenção" },
        { key: "description", label: "Descrição" },
        { key: "date", label: "Data" },
        { key: "local", label: "Local" },
        { key: "actions", label: "Ações" },
      ],
      filterType: 0,
      tagIdentification: "",
      tagType: "",
      selectedTag: {},
      asset: {},
      maintenanceTypes: ["PREDITIVA", "CORRETIVA", "PREVENTIVA", "CALIBRAÇÃO"],
      auditorHistory: ["NATHAN", "MARI", "ADRIANO", "ROGERIO"],
      localOptions: ["INTERNO", "EXTERNO"],
    };
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  watch: {
    $route() {
      this.updateList();
    },
    filterType() {
      this.clearSearchInputs();
    },
    selectedAsset() {
      if (this.selectedAsset && this.selectedAsset.id) {
        this.asset = this.selectedAsset;
      }
    },
  },
  computed: {
    customer() {
      return this.$store.getters["customer/customer"];
    },
    selectedAsset() {
      return this.$store.getters["asset/selectedAsset"];
    },
    customerTags() {
      return this.$store.getters["tags/customerTags"];
    },
    filteredTags() {
      if (this.filterType == 0) {
        if (this.tagIdentification) {
          return this.customerTags.filter((tag) => {
            return (
              tag.mac_address
                .toLowerCase()
                .includes(this.tagIdentification.toLowerCase()) && !tag.asset
            );
          });
        }
      } else {
        if (this.tagType) {
          return this.customerTags.filter((tag) => {
            return (
              tag.tag_type.toLowerCase().includes(this.tagType.toLowerCase()) &&
              !tag.asset
            );
          });
        }
      }
      return this.customerTags.filter((tag) => !tag.asset);
    },
    assetTags() {
      return this.$store.getters["tags/assetTags"];
    },
    loadingAssign() {
      return this.$store.getters["tags/loadingAssign"];
    },
    load() {
      return this.$store.getters["load"];
    },
    maintenanceHistory() {
      let history = this.$store.getters["asset/maintenanceHistory"];
      history.forEach((item) => {
        item.editable = false;
      });
      return history;
    },
    actualHistory() {
      let history = [...this.maintenanceHistory];
      return history;
    },
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    if (this.$route.params.assetId) {
      this.$store.dispatch("asset/getAsset", {
        assetId: this.$route.params.assetId,
      });
      this.$store.dispatch("asset/getMaintenanceHistory", {
        assetId: this.$route.params.assetId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Ativo" });
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    clearSearchInputs() {
      this.tagIdentification = "";
      this.tagType = "";
    },
    onFiltered(assets) {
      this.rows = assets.length;
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;

      if (this.customer) {
        this.breadcrumbList[1].label = `${this.customer.company_name}`;
      }

      if (!hasRole(["ADMIN", "SYSADMIN"])) {
        this.breadcrumbList[2].name = null;
        this.breadcrumbList[2].link = null;
      }

      this.breadcrumbList[2].label = "Colaborador";
    },
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    addRow(tableIndex) {
      document.activeElement.blur();
      this.actualHistory.push({
        auditor: null,
        description: null,
        date: null,
        local: null,
        type: null,
        editable: true,
        error: {
          auditor: false,
          description: null,
          type: false,
          date: false,
          local: false,
        },
      });
      this.$refs.history_table.refresh();
    },
    removeRow(index, tableIndex) {
      this.actualHistory.splice(index, 1);
      this.$refs.history_table.refresh();
    },
    saveHistory() {
      let validData = true;
      let toBeAdded = this.actualHistory.filter((item) => item.editable);
      toBeAdded.forEach((item) => {
        if (!this.validInputs(item)) validData = false;
        else {
          delete item.editable;
          delete item.error;
        }
      });

      if (validData) {
        toBeAdded.forEach((item) => {
          item.date = formatDateAPI(item.date);
        })
        this.$store.dispatch("asset/addMaintenanceHistory", {
          assetId: this.$route.params.assetId,
          maintenances: toBeAdded,
        });
      } else {
        window.frontMsg("FRNT_ACTION_WARNING", { reason: "há campos com erros" });
      }
    },
    validInputs(item){
      let valid = true;

      for (let attr in item.error){
        if (!item[attr] || (attr === "date" && item.date.length !== 10)) {
          item.error[attr] = true;
          valid = false;
        }

        else item.error[attr] = false;
      }

      return valid;
    },
    maintenanceExport() {
      /* make a copy of maintenanceHistory and remove asset_id and editable */
      let arr_of_objs = this.maintenanceHistory.map((item) => {
        let obj = { ...item };
        delete obj.asset_id;
        delete obj.editable;
        return obj;
      });
      let worksheet = XLSX.utils.json_to_sheet(arr_of_objs, {});
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "MaintenanceExport.xlsx");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  &:focus,
  &:active {
    + .autocomplete {
      display: block;
    }
  }
}
.autocomplete {
  border: 1px solid #ced4da;
  max-height: 200px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: white;
  border-radius: 4px;
  overflow-y: auto;
  display: none;

  &:hover,
  &:active {
    display: block;
  }
}

.tag {
  padding: 4px;
  font-size: 0.75rem;

  &.selected-tag {
    text-align: left;
    border-radius: 4px;
    display: block;
    width: 100%;
    line-height: 1;
    padding: 5px;
    border: 1px solid #ced4da;

    > div {
      margin-top: 2px;
    }
  }

  &:hover {
    cursor: pointer;
    background: #f4f6f9;
  }

  div {
    span {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
