<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div id="contadd-content" class="content-header px-0">
      <h1 id="contadd-title" class="title-I">Adicionar contato</h1>
    </div>

    <div id="contadd-div-master" class="col-12 col-sm-12 p-0 col-md-12">
      <div id="contadd-div-card" class="card direct-chat direct-chat-primary">
        <div id="contadd-card-body" class="card-body">
          <div id="contadd-form-wrapper" class="p-4 bg-white rounded">
            <form id="contadd-savecont-form" @submit="saveContact" novalidate="true">
              <div id="contadd-wrapper-row" class="row">
                <TwInput id="contadd-twin-contact" label="Nome" required="false">
                  <input
                    id="nomeCotato"
                    v-model="contact.name"
                    required
                    type="text"
                    maxlength="50"
                    class="form-control"
                    placeholder="Nome"
                  />
                </TwInput>

                <TwInput id="contadd-twin-tel" label="Telefone" required="false">
                  <input
                    id="telefoneContato"
                    v-model="contact.phones[0]"
                    required
                    v-mask="'+(##) ## #####-####'"
                    type="text"
                    class="form-control"
                    placeholder="Telefone"
                  />
                  <small id="contadd-tel-ex" class="form-text text-muted"
                    >Ex: +(55) 41 99999-9999</small
                  >
                </TwInput>
              </div>

              <div id="contadd-but-wrapper" class="row mt-2 p-1">
                <button id="saveContato" class="btn btn-primary px-5" :disabled="load">
                  <i id="contadd-load-save" v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "AddContact",
  data: function () {
    return {
      breadcrumbList: [],
      contact: {
        name: "",
        phones: [],
      },
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    isContactValid(contact) {
      if (!contact.name || contact.name.trim() === "") {
        window.frontMsg('FRNT_EMPTY_WARNING', {field: "Nome do Contato"});
        return false;
      }
      if (!contact.phones) {
        window.frontMsg('FRNT_EMPTY_WARNING', {field: "Número de Telefone"});
        return false;
      }
      let phone = contact.phones.map((phone) => phone.replace(/[\s()-]/g, ""));
      if (!phone || phone.length < 1 || phone[0].trim() === "") {
        window.frontMsg('FRNT_EMPTY_WARNING', {field: "Número de Telefone"});
        return false;
      }
      else if (phone[0].length < 13 || phone[0].length > 14) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Número de Telefone" }
        );
        return false;
      }
      return true;
    },
    saveContact() {
      event.preventDefault();
      if (!this.isContactValid(this.contact)) {
        return;
      }
      this.$store.dispatch("contact/saveContact", {
        customer_id: this.customer.id,
        contact: {
          ...this.contact,
          phones: this.contact.phones.map((phone) =>
            phone.replace(/[\s()-]/g, "")
          ),
        },
      });
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
