import { api } from "../services/api";

export default {
  namespaced: true,
  state: {
    alerts: [],
    classes: [],
    customerAlerts: [],
  },

  mutations: {
    async GET_ALERTS_TYPES(state) {
      console.log(state);

      await api.get(`alerts/types`).then((response) => {
        console.log(response);
        // state.alerts = response.data;
      });
    },
    async GET_ALERTS_CLASSES(state) {
      await api.get("alerts/classes").then((response) => {
        state.classes = response.data;
      });
    },

    async GET_CUSTOMER_ALERTS(state, { data }) {
      await api.get(`alerts/customer/${data.customer_id}`).then((response) => {
        state.customerAlerts = response.data;
      });
    },
  },
  getters: {
    alerts: (state) => state.alerts,
    classes: (state) => state.classes,
    customerAlerts: (state) => state.customerAlerts,
  },
  actions: {
    get(context) {
      context.commit("GET_ALERTS_TYPES");
    },
    // getAlertsClasses(context, classeName) {
    //   api
    //     .get(`customers?types?sortBy=${classeName}`)

    //     .then((response) => {
    //       context.commit("GET_ALERTS_CLASSES", response.data);
    //     });
    // },
    getAlertsClasses(context, classe) {
      //console.log(context);
      api
        .get(`/alerts/types?sortBy=code&class_=${classe}`)

        .then((response) => {
          context.commit("GET_ALERTS_CLASSES", response.data);
          console.log(response.data);
          context.state.alerts = response.data;
        });
    },
    // getCustomerAlerts(context, classe) {
    //   api
    //     .get(`types?sortBy=code&class_=${classe}`)
    //     .then((response) => {
    //       console.log(response.data);
    //       context.commit("GET_ALERTS_TYPES", response.data);
    //     })
    //     .catch(() => {
    //       window.toastr.error(
    //         "Não foi possivel encontrar os clientes desse grupo",
    //         "Clientes"
    //       );
    //     });
    // },
  },
};
