<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Adicionar cliente</h1>
    </div>

    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary Customer">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <form @submit="saveCustomer" novalidate="true">
              <div class="row">
                <TwInput label="Nome" required="false">
                  <input
                    id="namecustomer"
                    v-model="name"
                    required
                    type="text"
                    maxlength="50"
                    class="form-control"
                    placeholder="Nome do cliente"
                  />
                </TwInput>
                <TwInput label="CNPJ" required="false">
                  <input
                    id="cnpjcustomer"
                    v-model="cnpj"
                    required
                    v-mask="'##.###.###/####-##'"
                    type="text"
                    class="form-control"
                    placeholder="CNPJ"
                  />
                </TwInput>
              </div>
              <div class="row">
                <TwInput label="Grupo" required="false">
                  <select
                    id="groupcustomer"
                    v-model="groupsSelecteds"
                    multiple
                    required
                    class="form-control p-0"
                  >
                    <option
                      v-for="(group, index) in groups"
                      :key="index"
                      class="p-2"
                      :value="group.name"
                    >
                      {{ group.name }}
                    </option>
                  </select>
                </TwInput>
                <TwInput
                  label="Time Zone"
                  required="false"
                  placeholder="Ex: +0300"
                >
                  <select required id="timezones" class="form-control" v-model="timezone">
                    <option value="+1200">UTC +12 Horário Padrão Da Nova Zelândia  </option>
                    <option value="+1100">UTC +11 Horário Padrão De Sacalina </option>
                    <option value="+1000">UTC +10 Horário Padrão Da Austrália Oriental  </option>
                    <option value="+0900">UTC +9  Horário Padrão Do Japão</option>
                    <option value="+0800">UTC +8  Horário Padrão Da Austrália Ocidental</option>
                    <option value="+0700">UTC +7  Horário Da Indochina</option>
                    <option value="+0600">UTC +6  Horário Do Oceano Índico </option>
                    <option value="+0500">UTC +5  Horário Padrão Do Paquistão </option>
                    <option value="+0400">UTC +4  Horário Do Golfo </option>
                    <option value="+0300">UTC +3  Horário Padrão De Moscou </option>
                    <option value="+0200">UTC +2  Horário Da África Do Sul</option>
                    <option value="+0100">UTC +1  Horário Padrão Da Europa Central </option>
                    <option value="+0000">UTC +0  Horário Do Meridiano De Greenwich </option>
                    <option value="-0100">UTC -1  Horário Padrão Do Cabo Verde</option>
                    <option value="-0200">UTC -2  Horário Da Geórgia Do Sul </option>
                    <option value="-0300">UTC -3  Horário Padrão De Brasília</option>
                    <option value="-0400">UTC -4  Horário Padrão Do Chile</option>
                    <option value="-0500">UTC -5  Horário Do Equador </option>
                    <option value="-0600">UTC -6  Horário De Galápagos</option>
                    <option value="-0700">UTC -7  Horário Padrão Da Montanha </option>
                    <option value="-0800">UTC -8  Horário Padrão Do Pacífico</option>
                    <option value="-0900">UTC -9  Horário Padrão Do Alasca</option>
                    <option value="-1000">UTC -10  Horário Do Taiti </option>
                    <option value="-1100">UTC -11  Horário Padrão De Samoa </option>
                    {{ timezone }}
                  </select>
                </TwInput>
              </div>
              <div class="row p-1">
                <button id="savecustomer" class="btn btn-primary px-5" :disabled="load">
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import { validCnpj } from "../../services/helpers";
import TwInput from "@/components/TwInput.vue";
import "./style.scss";
export default {
  name: "AddUser",
  data: function () {
    return {
      name: "",
      breadcrumbList: [],
      groupsSelecteds: [],
      cnpj: "",
      timezone: "-0300",
      grant_type: "",
      scope: "",
      resource: "",
    };
  },
  mounted() {
    this.$store.dispatch("customer/get");
    this.$store.dispatch("customer/getGroups");
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    },
    groupsSelecteds() {
      if (this.groupsSelecteds.length > 1)
        this.groupsSelecteds = [this.groupsSelecteds.slice(-1).pop()];
    },
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    saveCustomer() {
      event.preventDefault();
      if (!this.isCustomerValid()) {
        return;
      }
      this.$store.dispatch("customer/saveCustomer", {
        contacts: [],
        buildings: [],
        timezone: this.timezone,
        company_name: this.name,
        cnpj: this.cnpj,
        group: {
          name: this.groupsSelecteds[0],
        },
      });
    },
    isCustomerValid() {
      this.cnpj = this.cnpj.replace(/\s/g, "");
      if (!this.name || this.name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome" }
        );
        return false;
      }

      if (this.cnpj.length < 18) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "CNPJ" }
        );
        return false;
      }
      let checkCnpj = (obj) => obj.cnpj === this.cnpj;

      if (this.customer.some(checkCnpj)) {
        window.frontMsg(
          "FRNT_IN_USE_WARNING",
          { field: "CNPJ" }
        );
        return false;
      }

      if (this.cnpj && validCnpj(this.cnpj) === false) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "CNPJ" }
        );
        return false;
      }

      if (
        !this.groupsSelecteds ||
        this.groupsSelecteds.length != 1 ||
        this.groupsSelecteds[0].trim() === ""
      ) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Grupo" }
        );
        return false;
      }

      return true;
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    groups() {
      return this.$store.getters["customer/groups"];
    },
    customer() {
      return this.$store.getters["customer/customers"];
    },
  },
};
</script>
