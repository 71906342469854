<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1>Editar andar</h1>
    </div>

    <Modal
      id="modalDeleteZone"
      title="Excluir zona"
      v-bind:msg="
        'Tem certeza que deseja excluir ' + zoneDelete.zone_name + '?'
      "
      button="Excluir"
      :method="deleteZone"
    />

    <Modal
      id="modalDeleteGeo"
      title="Excluir geofence"
      v-bind:msg="'Tem certeza que deseja excluir ' + geoDelete.geo_name + '?'"
      button="Excluir"
      :method="deleteGeo"
    />

    <div
      class="modal fade"
      id="modalGeofence"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalGeofenceLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit="geoSet" novalidate="true">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalGeofenceLabel">
                Adicionar geofence
              </h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-modalgeo-name-in"
                      v-model="geofence.geo_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Zona
                      <span class="required">*</span>
                    </label>

                    <select
                      v-model="geofence.zone_id"
                      required
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                    >
                      <option :value="null" disabled>Selecione</option>
                      <option
                        v-for="(zone, index) in zones"
                        :key="index"
                        :value="zone.id"
                      >
                        {{ zone.zone_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Associar Coletores:
                      <span class="required">*</span>
                    </label>

                    <select
                      v-model="gatewaysSelected"
                      multiple
                      required
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                      id="gatewaysSelected-select"
                    >
                      <option
                        v-for="(gateway, index) in customerGateways"
                        :key="index"
                        :value="gateway.id"
                      >
                        {{ gateway.gateway_name }} - {{ gateway.mac_address }}
                      </option>
                    </select>
                    <small class="form-text text-muted">
                      Para marcar ou desmarcar um coletor, basta apertar a tecla
                      "Ctrl" no teclado e clicar nos coletores desejados.
                    </small>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Raio (m)
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-modalgeo-radius-in"
                      v-model="geofence.radius"
                      required
                      type="number"
                      class="form-control"
                      placeholder="Raio (m)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-dismiss="modal"
                id="edit-modalgeo-cancel"
              >
                Cancelar
              </button>
              <button
                id="edit-modalget-add"
                type="submit"
                class="btn btn-primary"
              >
                Adicionar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--  -->

    <div
      class="modal fade"
      id="modalEditGeofence"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEditGeofenceLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit="geoUpdate" novalidate="true">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalEditGeofenceLabel">
                Editar geofence
              </h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-modalgeo-name-in2"
                      v-model="geofenceEdit.geo_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Zona
                      <span class="required">*</span>
                    </label>

                    <select
                      v-model="geofenceEdit.zone_id"
                      required
                      disabled
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                      id="edit-modalgeo-zone-in2"
                    >
                      <option :value="null" disabled>Selecione</option>
                      <option
                        v-for="(zone, index) in zones"
                        :key="index"
                        :value="zone.id"
                      >
                        {{ zone.zone_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Associar Coletores:
                      <span class="required">*</span>
                    </label>

                    <select
                      v-model="gatewaysSelected"
                      multiple
                      required
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                      id="gatewaysSelected-select2"
                    >
                      <option
                        v-for="(gateway, index) in customerGateways"
                        :key="index"
                        :value="gateway.id"
                      >
                        {{ gateway.gateway_name }} - {{ gateway.mac_address }}
                      </option>
                    </select>
                    <small class="form-text text-muted">
                      Para marcar ou desmarcar um coletor, basta apertar a tecla
                      "Ctrl" no teclado e clicar nos coletores desejados.
                    </small>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Raio (m)
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-modalgeo-radius-in2"
                      v-model="geofenceEdit.radius"
                      required
                      type="number"
                      class="form-control"
                      placeholder="Raio (m)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-dismiss="modal"
                id="edit-modalgeo-cancel2"
              >
                Cancelar
              </button>
              <button
                id="edit-modalgeo-save"
                type="submit"
                class="btn btn-primary"
              >
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--  -->

    <div
      class="modal fade"
      id="modalZone"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalZoneLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit="zoneSet" novalidate="true">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalZoneLabel">Adicionar zona</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="modalzone-name-in"
                      v-model="zone.zone_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Descrição
                      <span class="required">*</span>
                    </label>

                    <input
                      id="modalzone-description-in"
                      v-model="zone.description"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Descrição"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Cor
                      <span class="required">*</span>
                    </label>

                    <input
                      id="modalzone-color-in"
                      v-model="zone.zone_color"
                      required
                      type="color"
                      class="form-control"
                      placeholder="Cor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-dismiss="modal"
                id="modalzone-cancel"
              >
                Cancelar
              </button>
              <button
                id="edit-modalzone-add"
                type="submit"
                class="btn btn-primary"
              >
                Adicionar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalZoneEdit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalZoneEditLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit="zoneUpdate" novalidate="true">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalZoneEditLabel">Editar zona</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="modalzone-name-in2"
                      v-model="zoneEdit.zone_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Descrição
                      <span class="required">*</span>
                    </label>

                    <input
                      id="modalzone-description-in2"
                      v-model="zoneEdit.description"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Descrição"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Cor
                      <span class="required">*</span>
                    </label>

                    <input
                      id="modalzone-color-in2"
                      v-model="zoneEdit.zone_color"
                      required
                      type="color"
                      class="form-control"
                      placeholder="Cor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                id="edit-modalgeo-cancel3"
                type="button"
                class="btn btn-outline-dark"
                data-dismiss="modal"
                v-on:click="cancelZoneUpdate"
              >
                Cancelar
              </button>
              <button
                id="edit-modalgeo-save2"
                type="submit"
                class="btn btn-primary"
              >
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalMap"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalMapLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit="mapSet" novalidate="true">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalMapLabel">Editar planta</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-mm-name-in"
                      v-model="mapEdit.map_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>Descrição</label>

                    <input
                      id="edit-mm-description-in"
                      v-model="mapEdit.description"
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Descrição"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Arquivo
                      <span class="required">*</span>
                    </label>
                    <div class="custom-file mb-3">
                      <input
                        type="file"
                        ref="fileInput"
                        v-on:change="onFileChange"
                        class="custom-file-input"
                        id="validatedCustomFile"
                        :required="
                          fileName || mapEdit.file_archive ? false : true
                        "
                      />
                      <label
                        class="custom-file-label"
                        for="validatedCustomFile"
                        >{{ fileName || mapEdit.file_archive }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Escala
                      <span class="required">*</span>
                    </label>

                    <input
                      v-model="mapEdit.scale"
                      required
                      id="edit-mm-scale-in"
                      type="number"
                      class="form-control"
                      placeholder="Escala"
                    />
                    <small class="form-text text-muted">
                      Valor da escala representa a quantidade de pixels para 1
                      metro. Por exemplo, uma escala com valor 20, significa 20
                      pixels para 1 metro na planta.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-dismiss="modal"
                v-on:click="cancelMapUpdate"
                id="edit-map-cancel"
              >
                Cancelar
              </button>
              <button id="edit-map-save" type="submit" class="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div>
      <div>
        <div>
          <div class="p-4 bg-white rounded">
            <form @submit="updateFloor" novalidate="true">
              <div class="row">
                <div class="col-6 pr-4">
                  <div class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-floor-name-in"
                      v-model="floor.floor_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>
                <div class="col-6 pl-4">
                  <div class="form-group">
                    <label>
                      Descrição
                      <span class="required">*</span>
                    </label>

                    <input
                      id="edit-floor-description-in"
                      v-model="floor.description"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Descrição"
                    />
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <a
                    data-toggle="modal"
                    data-target="#modalZone"
                    ref="myBtnModal"
                    href="#modalZone"
                    class="btn btn-primary px-5"
                    @click="clearZoneFields"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                    Adicionar Zona
                  </a>
                  <a
                    data-toggle="modal"
                    data-target="#modalGeofence"
                    ref="myBtnModal"
                    href="#modalGeofence"
                    class="btn btn-primary px-5 ml-2"
                    @click="clearGeofenceFields"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                    Adicionar geofence
                  </a>
                </div>
                <div class="col-12 mb-2 row">
                  <div
                    v-for="(zone, index) in zones"
                    :key="index"
                    class="btn-group m-2 clearfix"
                  >
                    <button
                      @click="toggleZone(zone)"
                      class="btn"
                      :class="
                        zonesSwipe.includes(zone.id)
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      "
                      type="button"
                      :id="'zone-' + zone.id"
                    >
                      {{ zone.zone_name }}
                    </button>
                    <button
                      type="button"
                      :class="
                        zonesSwipe.includes(zone.id)
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      "
                      class="btn dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      :id="'zone-' + zone.id"
                    >
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                      <a
                        @click="
                          zoneEdit = { ...zone };
                          zoneEditOld = { ...zone };
                        "
                        href="#editZone"
                        data-toggle="modal"
                        data-target="#modalZoneEdit"
                        ref="myBtnModal"
                        class="dropdown-item"
                        >Editar</a
                      >

                      <a
                        @click="zoneDelete = zone"
                        href="#deleteZone"
                        data-toggle="modal"
                        data-target="#modalDeleteZone"
                        class="dropdown-item text-danger"
                        >Excluir</a
                      >
                    </div>
                  </div>
                </div>

                <div
                  id="map"
                  ref="mapDiv"
                  v-if="filePreview || floor.map.file_archive"
                  class="overflow-auto"
                >
                  <img
                    ref="mapImg"
                    @click="clicked($event)"
                    :src="filePreview || floor.map.file_archive"
                    alt
                  />
                  <VueDragResize
                    class="mark"
                    v-for="(geo, index) in geofences"
                    v-on:dragging="resize"
                    :parentW="
                      $refs['mapImg'].clientWidth - applyScale(geo.radius) * 2
                    "
                    :parentH="
                      $refs['mapImg'].clientHeight - applyScale(geo.radius) * 2
                    "
                    :parentLimitation="true"
                    @dragstop="(newRect) => onDragstop(geo, newRect)"
                    :isResizable="false"
                    :isActive="false"
                    :w="10"
                    :h="10"
                    :x="geo.x_position - applyScale(geo.radius)"
                    :y="geo.y_position - applyScale(geo.radius)"
                    :key="index"
                    v-bind:style="{
                      background: zones.filter((zn) => zn.id === geo.zone_id)[0]
                        .zone_color,
                      marginLeft: applyScale(geo.radius) + 'px',
                      marginTop: applyScale(geo.radius) + 'px',
                    }"
                  >
                    <a
                      v-bind:style="{
                        marginTop: '10px',
                        marginLeft: '5px',
                      }"
                      class="deleteGeo"
                      data-toggle="modal"
                      data-target="#modalDeleteGeo"
                      href="#modalDeleteGeo"
                      @click="geoDelete = geo"
                    >
                      <i class="far fa-trash-alt"></i>
                    </a>
                    <a
                      v-bind:style="{
                        marginTop: '10px',
                        marginLeft: '-35px',
                      }"
                      class="editGeo"
                      data-toggle="modal"
                      href="#modalEditGeofence"
                      @click="
                        geofenceEdit = { ...geo };
                        geofenceEditOld = { ...geo };
                        gatewaysSelected = geo.gateways.map((item) => item.id);
                      "
                    >
                      <i class="fas fa-pen"></i>
                    </a>
                    <span
                      v-bind:style="{
                        width: applyScale(geo.radius) * 2 + 'px',
                        height: applyScale(geo.radius) * 2 + 'px',
                        marginLeft: '-' + (applyScale(geo.radius) - 2.5) + 'px',
                        marginTop: '-' + (applyScale(geo.radius) - 2.5) + 'px',
                        background:
                          zones.filter((zn) => zn.id === geo.zone_id)[0]
                            .zone_color + '8f',
                      }"
                      :title="geo.geo_name"
                    ></span>
                  </VueDragResize>
                </div>

                <div class="col-12 mb-4 mt-3 pr-4">
                  <a
                    @click="
                      mapEdit = floor.map;
                      mapEditOld = { ...floor.map };
                    "
                    data-toggle="modal"
                    data-target="#modalMap"
                    ref="myBtnModal"
                    href="#modalMap"
                    >Editar planta</a
                  >
                </div>
              </div>

              <div class="row p-1">
                <button
                  id="edit-save-btn"
                  class="btn btn-primary px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import Modal from "../../components/Modal.vue";
import VueDragResize from "vue-drag-resize";
export default {
  name: "EditFloor",
  data: function () {
    return {
      description: "",
      zoneDelete: "",
      floor_name: "",
      geoDelete: "",
      map: {
        description: "",
        file_archive: "",
        map_name: "",
        scale: "",
      },
      file: "",
      filePreview: "",
      fileName: "",
      breadcrumbList: [],
      mapEdit: [],
      mapEditOld: [],
      zoneEdit: [],
      zoneEditOld: [],
      geofenceEdit: [],
      geofenceEditOld: [],
      zone: {
        zone_name: "",
        description: "",
        zone_color: "#FFFFFF",
      },
      geofences: [],
      gatewaysSelected: [],
      geofence: {
        zone_id: null,
        geo_name: "",
        radius: "",
      },
      zonesSwipe: [],
    };
  },
  mounted() {
    if (this.$route.params.floorId) {
      this.$store.dispatch("floor/get", {
        floorId: this.$route.params.floorId,
      });

      this.$store.dispatch("zone/getZones", {
        floorId: this.$route.params.floorId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Andar" });
      this.$router.push("/");
    }

    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("gateway/getCustomerGateways", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    if (this.$route.params.buildingId) {
      this.$store.dispatch("building/getBuilding", {
        buildingId: this.$route.params.buildingId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Edifício" });
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    onDragstop(geo, newRect) {
      let index = this.geofences.indexOf(geo);

      this.geos[index].x_position =
        newRect.left + this.applyScale(this.geos[index].radius);
      this.geos[index].y_position =
        newRect.top + this.applyScale(this.geos[index].radius);

      this.$store.dispatch("geofence/updateGeofence", {
        idx: index,
        zone_id: this.geos[index].zone_id,
        changePos: true,
        geo: {
          geo_name: this.geos[index].geo_name,
          geo_type: "STATIC",
          id: this.geos[index].id,
          location: this.floor.map.map_name,
          radius: this.geos[index].radius,
          x_position: newRect.left + this.applyScale(this.geos[index].radius),
          y_position: newRect.top + this.applyScale(this.geos[index].radius),
        },
      });
    },
    unassignGeofenceGateways(geofence, index, gatewaysList) {
      gatewaysList.forEach((item) => {
        this.$store.dispatch("geofence/unassignGateway", {
          idx: index,
          geofence_id: geofence.id,
          gateway: item,
          customer_id: this.customer.id,
        });
      });
    },
    assignGeofenceGateways(geofence, index, gatewaysList) {
      gatewaysList.forEach((item) => {
        this.$store.dispatch("geofence/assignGateway", {
          idx: index,
          geofence_id: geofence.id,
          gateway: item,
          customer_id: this.customer.id,
        });
      });
    },
    geoUpdate() {
      event.preventDefault();

      if (!this.isGeofenceValid(this.geofenceEdit)) {
        return;
      }

      let index = this.geofences.findIndex(
        (obj) => obj.id == this.geofenceEdit.id
      );

      let prevGateways = this.geofenceEdit.gateways.map((item) => {
        return item.id;
      });

      /* Unassigns gateways from the geofence if any was disselected */
      let unassignGatewaysIdsList = prevGateways.filter(
        (item) => !this.gatewaysSelected.includes(item)
      );
      let unassignGatewaysList = this.customerGateways.filter((item) =>
        unassignGatewaysIdsList.includes(item.id)
      );
      if (unassignGatewaysList.length > 0) {
        this.unassignGeofenceGateways(
          this.geofenceEdit,
          index,
          unassignGatewaysList
        );
      }

      /* Assigns new gateways to the geofence */
      let assignGatewaysIdsList = this.gatewaysSelected.filter(
        (item) => !prevGateways.includes(item)
      );
      let assignGatewaysList = this.customerGateways.filter((item) =>
        assignGatewaysIdsList.includes(item.id)
      );
      if (assignGatewaysIdsList.length > 0) {
        this.assignGeofenceGateways(
          this.geofenceEdit,
          index,
          assignGatewaysList
        );
      }
      this.geofences[index].geo_name = this.geofenceEdit.geo_name;
      this.geofences[index].gateways = this.geofenceEdit.gateways;
      this.geofences[index].radius = this.geofenceEdit.radius;
      this.$store.dispatch("geofence/updateGeofence", {
        idx: index,
        zone_id: this.geofenceEdit.zone_id,
        geo: {
          geo_name: this.geofenceEdit.geo_name,
          geo_type: "STATIC",
          id: this.geofenceEdit.id,
          location: this.floor.map.map_name,
          radius: this.geofenceEdit.radius,
          x_position: this.geofenceEdit.x_position,
          y_position: this.geofenceEdit.y_position,
        },
      });
      this.hideModal("#modalEditGeofence");
    },
    clearGeofenceFields() {
      this.geofence.geo_name = "";
      this.geofence.zone_id = null;
      this.geofence.radius = "";
      this.gatewaysSelected = [];
    },
    clearZoneFields() {
      this.zone.zone_name = "";
      this.zone.description = "";
      this.zone.zone_color = "";
    },
    geoButtonVerticalMargin(geo) {
      const radius = this.applyScale(geo.radius);
      if (geo.y_position - radius <= 40) {
        return radius + 10 + "px";
      } else {
        return "-" + (radius + 40) + "px";
      }
    },
    resize(newRect) {
      this.top = newRect.top;
      this.left = newRect.left;
    },
    clicked: function (event) {
      if (!this.geofence.radius) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Dados do Geofence" });
        return;
      }

      let x = event.pageX - (event.srcElement.offsetParent.offsetLeft + 5);
      let y = event.pageY - (event.srcElement.offsetParent.offsetTop + 5);

      let geoData = {
        x_position: x,
        y_position: y,
        geo_name: this.geofence.geo_name,
        zone_id: this.geofence.zone_id,
        radius: this.geofence.radius,
        height: this.applyScale(this.geofence.radius),
        width: this.applyScale(this.geofence.radius),
      };

      this.saveGeo(geoData);

      this.geofence.geo_name = "";
      this.geofence.zone_id = null;
      this.geofence.radius = "";
      this.gatewaysSelected = [];
    },
    applyScale(valueInMeters) {
      return (valueInMeters * this.floor.map.scale) / 1;
    },
    geoButtonTransform(geo, shiftPos = 0) {
      const radius = this.applyScale(geo.radius);
      if (radius * 2 < this.$refs["mapImg"].clientHeight - 40) {
        return this.geoButtonVerticalTransform(geo, shiftPos);
      } else if (radius * 2 < this.$refs["mapImg"].clientWidth - 40) {
        return this.geoButtonHorizontalTransform(geo, shiftPos);
      } else {
        return `translate(${shiftPos - 35}px, 0px)`;
      }
    },
    saveGeo(geo) {
      this.$store.dispatch("geofence/saveGeofence", {
        zone_id: geo.zone_id,
        customer_id: this.customer.id,
        geo_gateways_ids: this.gatewaysSelected,
        geo: {
          geo_name: geo.geo_name,
          geo_type: "STATIC",
          location: this.floor.map.map_name,
          radius: geo.radius,
          x_position: geo.x_position,
          y_position: geo.y_position,
        },
      });
    },
    geoSet() {
      event.preventDefault();

      if (!this.isGeofenceValid(this.geofence)) {
        return;
      }
      window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
        field: "Geofence",
        extra: "Clique no mapa para adicionar um Geofence",
      });
      this.hideModal("#modalGeofence");
    },
    toggleZone: function (zone) {
      const { id } = zone;
      if (!this.zonesSwipe.includes(id)) {
        this.zonesSwipe.push(id);
      } else {
        this.zonesSwipe.splice(this.zonesSwipe.indexOf(id), 1);
      }
    },
    deleteZone() {
      event.preventDefault();

      this.$store.dispatch("zone/deleteZone", {
        id: this.zoneDelete.id,
      });
    },
    deleteGeo() {
      event.preventDefault();

      this.$store.dispatch("geofence/deleteGeofence", {
        id: this.geoDelete.id,
        geo: this.geoDelete,
        customer_id: this.customer.id,
      });
    },
    isFloorValid(floor) {
      if (!floor.floor_name || floor.floor_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome do andar" });
        return false;
      }

      if (!floor.description || floor.description.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Descrição" });
        return false;
      }

      if (
        this.floor.map.file_archive == "" ||
        this.floor.map.map_name == "" ||
        this.floor.map.scale == ""
      ) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Dados do Andar" });
        this.showModal();
        return false;
      }

      return true;
    },
    updateFloor(e) {
      e.preventDefault();
      if (!this.isFloorValid(this.floor)) {
        return;
      }
      this.$store.dispatch("floor/updateFloor", {
        building: this.building,
        floor: {
          ...this.floor,
          building_id: this.building.id,
          map: {
            ...this.floor.map,
            file_archive:
              this.floor.map.file_archive || this.mapEdit.file_archive,
          },
        },
      });
    },
    resetInput() {
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileName = files[0].name;

      let reader = new FileReader();
      reader.onload = (event) => {
        this.file = event.target.result;
        this.mapEdit.file_archive = this.file;
      };
      reader.readAsDataURL(files[0]);
    },
    isMapValid(map) {
      if (!map.map_name || map.map_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome da Planta" });
        return false;
      }

      if (!map.file_archive || map.file_archive.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Arquivo da Planta" });
        return false;
      }

      if (!map.scale) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Escala da Planta" });
        return false;
      }

      return true;
    },
    mapSet(e) {
      e.preventDefault();
      if (!this.isMapValid(this.floor.map)) {
        return;
      }
      this.$store.dispatch("floor/updateFloor", {
        building: this.building,
        floor: {
          ...this.floor,
          building_id: this.building.id,
          map: {
            ...this.mapEdit,
            file_archive: this.file || this.mapEdit.file_archive,
          },
        },
      });
      this.hideModal();
      this.filePreview = this.file;
    },
    cancelMapUpdate() {
      this.mapEdit.description = this.mapEditOld.description;
      this.mapEdit.file_archive = this.mapEditOld.file_archive;
      this.mapEdit.map_name = this.mapEditOld.map_name;
      this.mapEdit.scale = this.mapEditOld.scale;
    },
    zoneUpdate(e) {
      e.preventDefault();
      if (!this.isZoneValid(this.zoneEdit)) {
        return;
      }
      this.$store.dispatch("zone/updateZone", {
        floor: this.floor,
        zone: { ...this.zoneEdit },
      });
      this.hideModal("#modalZoneEdit");
    },
    cancelZoneUpdate() {
      this.zoneEdit.zone_name = this.zoneEditOld.zone_name;
      this.zoneEdit.description = this.zoneEditOld.description;
      this.zoneEdit.zone_color = this.zoneEditOld.zone_color;
    },
    isZoneValid(zone) {
      if (!zone.zone_name || zone.zone_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome da Zona" });
        return false;
      }

      if (!zone.description || zone.description.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Descrição da zona" });
        return false;
      }

      if (!zone.zone_color || zone.zone_color.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Cor da zona" });
        return false;
      }

      return true;
    },
    zoneSet(e) {
      e.preventDefault();
      if (!this.isZoneValid(this.zone)) {
        return;
      }
      this.$store.dispatch("zone/saveZone", {
        floor: this.floor,
        zone: {
          zone_name: this.zone.zone_name,
          description: this.zone.description,
          zone_color: this.zone.zone_color,
          geofences: [],
        },
      });

      this.zone.description = "";
      this.zone.zone_name = "";
      this.zone.zone_color = "#FFFFFF";
      this.hideModal("#modalZone");
    },
    showModal() {
      const elem = this.$refs.myBtnModal;
      elem.click();
    },
    hideModal(toClose = "#modalMap") {
      var modal = document.querySelector(toClose);
      var jQueryObj = Object.keys(modal).filter(
        (key) =>
          key.toString().indexOf("jQuery") !== -1 &&
          modal[key].hasOwnProperty("bs.modal")
      );

      modal[jQueryObj]["bs.modal"].hide();
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
      if (this.building) {
        this.breadcrumbList[2].label = `Editar Edíficio ${this.building.building_name}`;
      }
    },
    isGeofenceValid(geofence) {
      /**Checks if the geofence is valid.

      Checks if the name is not null or empty, if the zone is not null or
      empty, if the radius is not null or empty and if the radius is a
      positive integer.
      If any inconsistency is detected, a warning toast message is displayed.
      **/
      if (!geofence.geo_name || geofence.geo_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome" });
        return false;
      }

      if (!geofence.zone_id || geofence.zone_id === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Zona" });
        return false;
      }

      if (!geofence.radius || geofence.radius === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Raio" });
        return false;
      }

      const radius = this.applyScale(geofence.radius);

      if (radius * 2 > this.$refs["mapImg"].clientHeight) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Tamanho do Raio",
          numbercondition: "menor que 2x a altura da planta",
        });
        return false;
      }

      if (radius * 2 > this.$refs["mapImg"].clientWidth) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Tamanho do Raio",
          numbercondition: "menor que 2x a largura da planta",
        });
        return false;
      }

      if (!Number.isInteger(parseFloat(geofence.radius))) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Raio",
          numbercondition: "inteiro maior que zero",
        });
        return false;
      }

      if (parseInt(geofence.radius) < 0) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Altura",
          numbercondition: "inteiro maior que zero",
        });
        return false;
      }

      return true;
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.$store.dispatch("gateway/getCustomerGateways", {
          customer_id: this.customer.id,
        });
        this.updateList();
      }
    },
    building() {
      if (this.building) {
        this.updateList();
      }
    },
    floor() {
      if (this.floor.id) {
        this.zonesSwipe = this.zones.map((zone) => zone.id);

        this.$store.dispatch("zone/getZones", {
          floorId: this.floor.id,
        });
      }
    },
    zonesSwipe() {
      this.$store.dispatch("geofence/get", {
        zones: this.zonesSwipe,
      });
    },
    geos() {
      this.geofences = this.geos;
    },
  },
  computed: {
    customer() {
      return this.$store.getters["customer/customer"];
    },
    building() {
      return this.$store.getters["building/building"];
    },
    floor() {
      return this.$store.getters["floor/floor"];
    },
    combinedWatch() {
      return this.zones && this.floor;
    },
    load() {
      return this.$store.getters["load"];
    },
    zones() {
      return this.$store.getters["zone/zones"];
    },
    customerGateways() {
      return this.$store.getters["gateway/customerGateways"];
    },
    geos() {
      return this.$store.getters["geofence/geofences"];
    },
  },
  components: {
    Breadcrumb,
    Modal,
    VueDragResize,
  },
};
</script>

<style scoped lang="scss">
#map {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  overflow: hidden;

  img {
    border: 1px dotted transparent;
    &.sizeEnter:hover {
      border-color: #000;
    }
  }

  .mark {
    display: block;
    background: lightblue;
    position: absolute;
    border-radius: 100%;

    margin: 0 auto;
    border: 0;
    &:before {
      outline: 0;
    }
    .editGeo,
    .deleteGeo {
      position: absolute;
      width: 37px;
      height: 35px;
      border: 1px solid #444444;
      background: #fff;
      overflow: hidden;
      z-index: 200;
      text-align: center;
      color: #464646;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > span {
      animation: rotate360 3s infinite linear;
      background: rgba(106, 123, 197, 0.558);
      position: absolute;
      border-radius: 100%;
      overflow: hidden;
      &:before {
        position: absolute;
        z-index: 1024;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 50%;
        content: "";
        background-image: linear-gradient(
          135deg,
          rgba(185, 5, 5, 0.2) 0%,
          rgba(160, 15, 15, 0.01) 70%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
}

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.custom-file label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 90px;
}
.custom-file-label::after {
  content: "Arquivo...";
}
</style>
