var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumb',{attrs:{"breadcrumbList":_vm.breadcrumbList}}),_c('h4',{staticClass:"mt-3 title-I"},[_vm._v("Editar Cliente")]),_c('div',{staticClass:"mt-3 col-12 col-sm-12 p-0 col-md-12"},[_c('div',{staticClass:"card direct-chat direct-chat-primary"},[_c('div',{staticClass:"card-body m-3",staticStyle:{"min-height":"610px"}},[_c('b-tabs',{attrs:{"content-class":"mt-5"}},[(
              _vm.hasRole([
                'ADMIN',
                'SYSADMIN',
                'MANAGER',
                'DASHBOARD',
                'FRONTDESK',
                'USER',
              ])
            )?_c('b-tab',{attrs:{"active":_vm.lastTab === 'customer',"id":"customerTab"},on:{"click":function($event){return _vm.setLastTab('customer')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"far fa-id-card nav-icon mr-2"}),_c('strong',[_vm._v("Cliente")])]},proxy:true}],null,false,167804335)},[_c('CustomerTab')],1):_vm._e(),(
              _vm.hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER'])
            )?_c('b-tab',{attrs:{"active":_vm.lastTab === 'building',"id":"buildingTab"},on:{"click":function($event){return _vm.setLastTab('building')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon far fa-building mr-2"}),_c('strong',[_vm._v("Edifícios")])]},proxy:true}],null,false,2224627246)},[_c('BuildingTab')],1):_vm._e(),(
              _vm.hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER'])
            )?_c('b-tab',{attrs:{"active":_vm.lastTab === 'contact',"id":"contactTab"},on:{"click":function($event){return _vm.setLastTab('contact')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon far fa-address-book mr-2"}),_c('strong',[_vm._v("Contatos")])]},proxy:true}],null,false,1088310464)},[_c('ContactTab')],1):_vm._e(),(
              _vm.hasRole([
                'ADMIN',
                'SYSADMIN',
                'MANAGER',
                'FRONTDESK',
                'DASHBOARD',
                'USER',
              ])
            )?_c('b-tab',{attrs:{"active":_vm.lastTab === 'employee',"id":"employeeTab"},on:{"click":function($event){return _vm.setLastTab('employee')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon fa fa-user-friends mr-2"}),_c('strong',[_vm._v("Colaboradores")])]},proxy:true}],null,false,1027758578)},[_c('EmployeeTab')],1):_vm._e(),(_vm.hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK']))?_c('b-tab',{attrs:{"active":_vm.lastTab === 'asset',"id":"assetTab"},on:{"click":function($event){return _vm.setLastTab('asset')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon fa fa-file-invoice-dollar mr-2"}),_c('strong',[_vm._v("Ativos")])]},proxy:true}],null,false,1830284294)},[_c('AssetTab')],1):_vm._e(),(
              _vm.hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER'])
            )?_c('b-tab',{attrs:{"active":_vm.lastTab === 'tag',"id":"tabTag"},on:{"click":function($event){return _vm.setLastTab('tag')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon fa fa-tag mr-2"}),_c('strong',[_vm._v("Tags")])]},proxy:true}],null,false,2480278060)},[_c('TagTab')],1):_vm._e(),(_vm.hasRole(['ADMIN', 'SYSADMIN', 'MANAGER']))?_c('b-tab',{attrs:{"active":_vm.lastTab === 'gateway',"id":"gatewayTab"},on:{"click":function($event){return _vm.setLastTab('gateway')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon fa fa-tower-cell mr-2"}),_c('strong',[_vm._v("Coletores")])]},proxy:true}],null,false,1286766549)},[_c('GatewayTab')],1):_vm._e(),(_vm.hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD']))?_c('b-tab',{attrs:{"active":_vm.lastTab === 'notification',"id":"notificationTab"},on:{"click":function($event){return _vm.setLastTab('notification')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"wizard-icon far fa-bell mr-2"}),_c('strong',[_vm._v("Regras de notificação")])]},proxy:true}],null,false,2863772321)},[_c('NotificationTab')],1):_vm._e()],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }