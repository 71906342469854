<template>
  <div
    id="error-body"
    v-bind:class="loggedIn ? 'error-body-loggedIn' : 'error-body-loggedOut'"
  >
    <div id="btn-container" class="btn-container">
        <p class="error-title"> 401 </p>
        <p class="error-text"> Ooops! Acesso não permitido. </p>
        <div class="img">
          <img src="@/assets/img/icon_alert_triangle_outline.png"/>
        </div>
        <br><br>
        <button
          id="login-ir"
          class="btn-login-go "
          @click="loginGo"
        >
          IR PARA LOGIN
        </button>
    </div>
    

  </div>
</template>

<script>
import auth from "@/auth";
export default {
  data() {
    return {
      loggedIn: auth.loggedIn(),
    };
  },
  methods: {
    loginGo() {
      let redirect =  "https://" + window.location.hostname + "/";
      this.$store.dispatch("login/logout", {
          redirect_to: redirect,
        });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.error-body-loggedIn {
  background-image: url("@/assets/img/Fundo-401.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 120%;
  height: 90vh;
  background-color: #021D3B;
  margin-top:-2%;
  margin-left: -5%;
  margin-bottom: -2%
}
.error-body-loggedOut {
  background-image: url("@/assets/img/Fundo-401.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 88vh;
  background-color: #021D3B;
}
.error-title {
  text-align: center;
  font-size: 48px;
  font-family: "dosis";
  font-weight: bold;
  color: #FFA517;
}
.error-text {
  text-align: center;
  font-size: 24px;
  font-family: "dosis";
  font-weight: medium;
  color: #FFA517;
}
.btn-login-go {
  font-family: "dosis";
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 161px;
  align-self: center;
  text-align: center;
  border-width: 0;
  margin-top: 1rem;
  border: none;
  color: #021D3B;
  background-color: #FFA517;
}
.btn-container { 
  position: absolute;
  overflow: hidden;
  width: 65%;
  margin: 15%;
  text-align: center;
}

.img{
  top: 20%;
  left: 63%;
  position: absolute;
  }

</style>
