<template>
  <div class="notification-rule-page">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Editar Regra</h1>
    </div>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <form @submit="editNotificationRule" novalidate="true">
        <div class="card direct-chat direct-chat-primary">
          <div class="card-body">
            <div class="pl-4 pt-4 pr-4 bg-white rounded">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Regra <span class="required">*</span></label>
                    <!-- If the role is SYSADMIN, make the input disabled -->
                    <input
                      id="nameNotification"
                      v-model="notificationRule.name"
                      required
                      type="text"
                      maxlength="128"
                      class="form-control"
                      placeholder="Regra"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="form-group col-12 mb-2">
                      <label>Usuários <span class="required">*</span></label>
                      <input
                        id="users-filter-in"
                        v-model="filterUsers"
                        type="search"
                        class="form-control col-12 w-100"
                        placeholder="Buscar"
                        :disabled="disabled"
                      />
                    </div>
                  </div>
                  <div class="row pr-2 pl-2">
                    <button
                      id="edit-selall-btn"
                      type="button"
                      class="btn btn-light table-header-button col-12"
                      @click="onSelectAll"
                      :disabled="disabled"
                    >
                      <i class="fas fa-chevron-right"></i>
                      <i class="fas fa-chevron-right"></i>
                    </button>
                    <b-table
                      class="custom-scroll"
                      thead-class="user-table-header"
                      empty-text="Não há usuários cadastrados"
                      hover
                      borderless
                      no-sort-reset
                      show-empty
                      :busy="load"
                      :fields="fieldsSelectUser"
                      :items="unselectedUsers"
                      :filter="filterUsers"
                      @row-clicked="onSelectUser"
                      :disabled="disabled"
                    >
                      <template v-slot:table-busy>
                        <div class="text-center my-2">
                          <strong>
                            <i class="fas fa-sync-alt fa-spin"></i>
                          </strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="form-group col-12 mb-2">
                      <label>Usuários que vão receber alertas</label>
                      <input
                        id="users-filter-alert"
                        v-model="filterSelectedUsers"
                        type="search"
                        class="form-control col-12 w-100"
                        placeholder="Buscar"
                        :disabled="disabled"
                      />
                    </div>
                  </div>

                  <div class="row pr-2 pl-2">
                    <button
                      id="edit-desall-btn"
                      type="button"
                      class="btn btn-light table-header-button col-12"
                      @click="onDeselectAll"
                      :disabled="disabled"
                    >
                      <i class="fas fa-chevron-left"></i>
                      <i class="fas fa-chevron-left"></i>
                    </button>
                    <b-table
                      class="custom-scroll"
                      thead-class="user-table-header"
                      empty-text="Não há usuários selecionados"
                      hover
                      borderless
                      no-sort-reset
                      show-empty
                      :busy="load"
                      :fields="fieldsDeselectUser"
                      :items="selectedUsers"
                      :filter="filterSelectedUsers"
                      @row-clicked="onDeselectUser"
                      :disabled="disabled"
                    >
                      <template v-slot:table-busy>
                        <div class="text-center my-2">
                          <strong>
                            <i class="fas fa-sync-alt fa-spin"></i>
                          </strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label
                      >Selecione as ações <span class="required">*</span></label
                    >
                    <b-form-checkbox-group
                      id="checkBox1"
                      v-model="selectedActions"
                      stacked
                      plain
                      :options="actionOptions"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>

              <!-- Início classe-->
              <div class="row">
                <TwInput v-show="classes.length > 1" col_lg="3" :disabled="disabled" label="Classe">
                  <select id="not-classe" class="form-control" v-model="selectedClasses">
                    <option value="null" disabled selected>
                      Selecione a classe
                    </option>

                    <option
                      v-for="classe in classes"
                      :value="classe"
                      :key="classe.name"
                    >
                      {{ classe.name }}
                    </option>
                  </select>
                </TwInput>
              </div>
              <!-- AQUI -->
              <label>Alerta<span class="required">*</span></label> 
              <div class="row form-group limited-height-200 bordered-row">
                <b-form-group class="col-12 checkbox-group bordered-row" required="true">
                  <b-form-checkbox-group
                    v-model="selectedAlerts"
                    style="column-count: 2"
                    stacked
                    plain
                    :options="alerts"
                    :disabled="disabled"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="row">
                <div class="col-4 mb-0">
                  <div class="form-group mb-0">
                    <label>Status <span class="required">*</span></label>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="selectedStatus"
                        :options="statusOptions"
                        plain
                        name="status"
                        :disabled="disabled"
                      />
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer" v-can="['ADMIN']">
            <button id="saveNotification" class="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import "./styles.scss";
import hasRole from "../../utils/hasRole"
import TwInput from "@/components/TwInput";

export default {
  name: "EditNotificationRule",
  data: function () {
    return {
      key: "",
      passShow: false,
      passkey: false,
      filterUsers: null,
      filterSelectedUsers: null,
      selectedClasses: null,
      classe: null,
      breadcrumbList: [],
      notificationRule: {
        id: "",
        name: "",
        status: "",
        customer_id: null,
        actions: [],
        alerts: [],
        users: [],
      },
      selectedUsers: [],
      fieldsSelectUser: [{ key: "name", sortable: true, label: "Nome" }],
      fieldsDeselectUser: [{ key: "name", sortable: true, label: "Nome" }],
      selectedActions: [],
      actionOptions: [
        { text: "Email", value: "EMAIL" },
        { text: "SMS", value: "SMS" },
        { text: "Push", value: "PUSH" },
        { text: "Log", value: "LOG" },
        { text: "Voice", value: "VOICE" },
        { text: "WhatsApp", value: "WHATSAPP" },
      ],
      selectedAlerts: [],
      selectedStatus: "ATIVO",
      statusOptions: [
        { text: "Ativo", value: "ATIVO" },
        { text: "Inativo", value: "INATIVO" },
      ],
      disabled: false,
    };
  },
  mounted() {
    if (hasRole("SYSADMIN") && !hasRole("ADMIN")) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }

    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("notificationRule/getUsers", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }

    if (this.$route.params.notificationRuleId) {
      this.$store.dispatch("notificationRule/getNotificationRule", {
        notificationRuleId: this.$route.params.notificationRuleId,
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "da Regra" }
      );
      this.$router.push("/");
    }

    this.$store.dispatch("alert/get");
    this.$store.dispatch("alert/getAlertsClasses");
    this.updateList();
  },
  watch: {
    classes() {
      if (this.classes.length < 0) {
        this.selectedClasses = this.classe[2];
      }
    },
    selectedClasses() {
      this.$store.dispatch("alert/getAlertsClasses", this.selectedClasses.name);
    },
    customer() {
      if (this.customer) {
        this.updateList();
        this.$store.dispatch("notificationRule/getUsers", {
          customerId: this.customer.id,
        });
      }
    },
    computedNotificationRule() {
      if (this.computedNotificationRule) {
        this.notificationRule = {
          id: this.computedNotificationRule.id ?? "",
          name: this.computedNotificationRule.name ?? "",
          status: this.computedNotificationRule.status ?? "",
          customer_id: this.computedNotificationRule.customer_id ?? null,
          actions: this.computedNotificationRule.actions ?? [],
          alerts:
            this.computedNotificationRule.alerts.map((alert) => alert.code) ??
            [],
          users: this.computedNotificationRule.users ?? [],
        };

        this.selectedUsers = this.computedNotificationRule.users.map(
          (user) => ({
            email: user.email,
            name: user.name,
          })
        );
        this.selectedActions = this.computedNotificationRule.actions;
        this.selectedAlerts = this.computedNotificationRule.alerts.map(
          (alert) => alert.code
        );
        this.selectedStatus = this.computedNotificationRule.status;
      } else {
        window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "da Regra" }
      );
        this.$router.push("/");
      }
    },
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    onSelectUser(item) {
      if(!this.disabled) {
        this.selectedUsers.push(item);
      }
      else{
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "Você não tem permissão para alterar esta regra",
          }
        );
      }
    },
    onDeselectUser(item) {
      if(!this.disabled) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(item), 1);
      }
      else{
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "Você não tem permissão para alterar esta regra",
          }
        );
      }
    },
    cla() {
      this.claa = !this.claa;
    },
    onSelectAll() {
      this.selectedUsers = [...this.users];
    },
    onDeselectAll() {
      this.selectedUsers = [];
    },
    isNotificationRuleValid(notificationRule) {
      if (!notificationRule.name || notificationRule.name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Regra" }
        );
        return false;
      }
      if (!notificationRule.users.length) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Usuário" }
        );
        return false;
      }
      if (!notificationRule.actions.length) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Ação" }
        );
        return false;
      }
      if (!notificationRule.alerts.length) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Alerta" }
        );
        return false;
      }
      if (!notificationRule.status || notificationRule.status.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Status" }
        );
        return false;
      }
      if (
        !notificationRule.customer_id ||
        isNaN(notificationRule.customer_id)
      ) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Cliente" }
        );
        return false;
      }
      return true;
    },
    editNotificationRule() {
      event.preventDefault();

      this.notificationRule.users = this.selectedUsers.map(
        (user) => user.email
      );
      this.notificationRule.actions = this.selectedActions;
      this.notificationRule.alerts = this.selectedAlerts;
      this.notificationRule.status = this.selectedStatus;
      this.notificationRule.customer_id = this.customer.id;

      if (!this.isNotificationRuleValid(this.notificationRule)) {
        return;
      }

      this.$store.dispatch("notificationRule/updateNotificationRule", {
        notificationRule: this.notificationRule,
      });
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    computedNotificationRule() {
      return this.$store.getters["notificationRule/notificationRule"];
    },
    users() {
      return this.$store.getters["notificationRule/users"].map((user) => ({
        name: user.name,
        email: user.email,
      }));
    },
    alerts() {
      return this.$store.getters["alert/alerts"].map((alert) => ({
        text: alert.msg,
        value: alert.code,
      }));
    },
    classes() {
      let classes = this.$store.getters["alert/classes"];
      classes = classes.filter((classes) => {
        return classes.name;
      });
      return classes;
    },
    unselectedUsers() {
      const selectedUsers = this.selectedUsers.map((user) => user.email);
      if (this.users)
        return this.users.filter((user) => !selectedUsers.includes(user.email));

      return [];
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
