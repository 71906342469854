<template>
  <div v-can="['ADMIN', 'SYSADMIN']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Associar Coletores</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary">
          <div class="card-body">
            <div class="p-4 bg-white rounded">
              <div class="row">
                <TwInput col_lg="6" label="Filtro">
                  <div class="pr-1 pl-1 pb-3">
                    <input
                      v-model="searchAvailablesGateways"
                      type="search"
                      class="form-control col-12 w-100"
                      placeholder="Buscar gateways disponíveis"
                      id="associarGateway"
                    />
                  </div>
                  <div class="row pr-1 pl-1">
                    <div class="col-12 pb-3">
                      <button
                        type="button"
                        class="btn btn-light table-header-button col-12"
                        @click="onSelectAllAvailable"
                        id="assignGateway"
                        :disabled="loadingCustomerGateways || loadingAssign || loadingAvailableGateways"
                      >
                        <i class="fas fa-chevron-right"></i>
                        <i class="fas fa-chevron-right"></i></button
                      ><b-table
                        class="custom-scroll mt-2"
                        thead-class="user-table-header"
                        empty-text="Não há gateways disponíveis"
                        head-variant="light"
                        borderless
                        no-sort-reset
                        show-empty
                        @click="selectedGateway = gateway"
                        :busy="loadingAvailableGateways || loadingAssign"
                        :items="availableGateways"
                        :fields="fields"
                        :filter="searchAvailablesGateways"
                        empty-filtered-text="nenhum gateway encontrado com esse filtro"
                        @row-clicked="onSelectAvailableGateways"
                      >
                        <template v-slot:table-busy>
                          <div class="text-center my-2">
                            <strong>
                              <i class="fas fa-sync-alt fa-spin"></i>
                            </strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </TwInput>

                <TwInput col_lg="6" label="Filtro">
                  <div class="pr-1 pl-1 pb-3">
                    <input
                      id="search-associarGateway"
                      v-model="searchFilteredAssignedGateways"
                      type="search"
                      class="form-control col-12 w-100"
                      placeholder="Buscar gateways já associados"
                    />
                  </div>
                  <div class="row pr-1 pl-1">
                    <div class="col-12 pb-3">
                      <button
                        type="button"
                        class="btn btn-light table-header-button col-12"
                        @click="onDeselectAll"
                        id="unassignGateway"
                        :disabled="loadingAvailableGateways || loadingAssign || loadingCustomerGateways"
                      >
                        <i class="fas fa-chevron-left"></i>
                        <i class="fas fa-chevron-left"></i></button
                      ><b-table
                        class="custom-scroll mt-2"
                        thead-class="user-table-header"
                        empty-text="Não há gateways associados"
                        head-variant="light"
                        borderless
                        no-sort-reset
                        @click="selectedGateway = gateway"
                        show-empty
                        :items="customerGateways"
                        :busy="loadingCustomerGateways || loadingAssign"
                        :fields="fields"
                        :filter="searchFilteredAssignedGateways"
                        empty-filtered-text="nenhum gateway encontrado com esse filtro"
                        @row-clicked="unassignGateway"
                      >
                        <template v-slot:table-busy>
                          <div class="text-center my-2">
                            <strong>
                              <i class="fas fa-sync-alt fa-spin"></i>
                            </strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </TwInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput";
import { mapGetters } from "vuex";

export default {
  name: "CustomerAddGateways",
  data: function () {
    return {
      breadcrumbList: [],
      fields: [
        { label: "Nome Coletor", key: "gateway_name" },
        { label: "Identificador", key: "mac_address" },
        { label: "Tipo Coletor", key: "gateway_type" },
        { label: "Ações", key: "actions", class: "actions" },
      ],
      employee: {},
      filterType: 0,
      gatewayIdentification: "",
      gatewayType: "",
      searchFilteredAssignedGateways: "",
      searchAvailablesGateways: "",
      selectedGateway: {},
      GatewayUnassign: "",
    };
  },
  components: {
    Breadcrumb,
    //Modal,
    TwInput,
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    filterType() {
      this.clearSearchInputs();
    },
    customer() {
      if (this.customer) {
        this.$store.dispatch("gateway/getCustomerGateways", {
          customer_id: this.customer.id,
        });
        this.updateList();
      }
    },
  },
  computed: {
    ...mapGetters({
      loadingCustomerGateways: "gateway/loadingCustomerGateways",
      customerGateways: "gateway/customerGateways",
      loadingAvailableGateways: "gateway/loadingAvailableGateways",
    }),
    customer() {
      return this.$store.getters["customer/customer"];
    },
    filteredGateways() {
      if (this.filterType == 0) {
        if (this.gatewayIdentification) {
          return this.allGateways.filter((gateway) => {
            return (
              gateway.mac_address
                .toLowerCase()
                .includes(this.gatewayIdentification.toLowerCase()) &&
              !gateway.customer_id
            );
          });
        }
      } else {
        if (this.gatewayType) {
          return this.allGateways.filter((gateway) => {
            return (
              gateway.gateway_type
                .toLowerCase()
                .includes(this.gatewayType.toLowerCase()) &&
              !gateway.customer_id
            );
          });
        }
      }
      return this.allGateways.filter((gateway) => !gateway.customer_id);
    },
    customerGateways() {
      return this.$store.getters["gateway/customerGateways"];
    },
    loadingGateways() {
      return this.$store.getters["gateway/loadingGateways"];
    },
    loadingCustomerGateways() {
      return this.$store.getters["gateway/loadingCustomerGateways"];
    },
    loadingAssign() {
      return this.$store.getters["gateway/loadingAssign"];
    },
    availableGateways: {
      get() {
        return this.$store.getters["gateway/availableGateways"];
      },
      set(value) {
        this.$store.dispatch("gateway/setAvailableGateways", value);
      },
    },
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("gateway/getCustomerGateways", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }
    this.$store.dispatch("gateway/getAvailableGateways", { customer_id: "-1" });
  },
  methods: {
    async onSelectAllAvailable() {
      if (this.availableGateways.length === 0) {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "não há Coletores disponíveis",
          }
        );
        return;
      }
      const list_id = this.availableGateways.map((gateway) => {
        return gateway.id;
      });
      await this.$store.dispatch("gateway/assignGatewayToCustomer", {
        gateway_list: list_id,
        customer_id: this.$route.params.customerId,
      });
    },
    async onDeselectAll() {
      if (this.customerGateways.length === 0) {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "não há Coletores associados",
          }
        );
        return;
      }
      const list_id = this.customerGateways.map((gateway) => {
        return gateway.id;
      });
      await this.$store.dispatch("gateway/unassignGatewayFromCustomer", {
        gateway_list: list_id,
        customer_id: this.$route.params.customerId,
      });
    },
    async onSelectAvailableGateways(item) {
      await this.$store.dispatch("gateway/assignGatewayToCustomer", {
        gateway_list: [item.id],
        customer_id: this.$route.params.customerId,
      });
    },
    clearSearchInputs() {
      this.gatewayIdentification = "";
      this.gatewayType = "";
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `${this.customer.company_name}`;
      }
    },
    assignGateway() {
      const data = {
        gateway: this.selectedGateway,
        customer_id: this.customer.id,
      };

      if (this.selectedGateway.id) {
        this.$store.dispatch("gateway/assignGatewayToCustomer", data);

        this.selectedGateway = {};
        this.clearSearchInputs();
      } else {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          {field: "Coletor", extra: "Selecione uma Coletor para associar" }
        );
      }
    },
    unassignGateway(item) {
      const data = {
        gateway_list: [item.id],
        customer_id: this.$route.params.customerId,
      };
      this.$store.dispatch("gateway/unassignGatewayFromCustomer", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  &:focus,
  &:active {
    + .autocomplete {
      display: block;
    }
  }
}
.autocomplete {
  border: 1px solid #ced4da;
  max-height: 200px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: white;
  border-radius: 4px;
  overflow-y: auto;
  display: none;

  &:hover,
  &:active {
    display: block;
  }
}

.gateway {
  padding: 4px;
  font-size: 0.75rem;

  &.selected-gateway {
    text-align: left;
    border-radius: 4px;
    display: block;
    width: 100%;
    line-height: 1;
    padding: 5px;
    border: 1px solid #ced4da;

    > div {
      margin-top: 2px;
    }
  }

  &:hover {
    cursor: pointer;
    background: #f4f6f9;
  }

  div {
    span {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
