<template>
  <footer class="main-footer">
    <strong
      >Copyright &copy; 2014-{{ year }}
      <a tabindex="-1" href="#">TRUE WORK</a>.</strong
    >
    Todos direitos reservados.
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year: () => {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
