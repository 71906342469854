export default {
  namespaced: true,
  state: {
    isRefreshingAccessToken: false,
  },

  mutations: {
    async SET_IS_REFRESHING_ACCESS_TOKEN(state, isRefreshing) {
      state.isRefreshingAccessToken = isRefreshing;
    },
  },
  getters: {
    isRefreshingAccessToken: (state) => state.isRefreshingAccessToken,
  },
  actions: {
    setIsRefreshingAccessToken(context, isRefreshing) {
      context.commit("SET_IS_REFRESHING_ACCESS_TOKEN", isRefreshing);
    },
  },
};
