/* eslint-disable no-undef */
import axios from "axios";
import store from "../store";
axios.defaults.withCredentials = true;

// const api_url = config.VUE_APP_API_URL || process.env.VUE_APP_API_URL;
// const api_version =
//   config.VUE_APP_API_VERSION || process.env.VUE_APP_API_VERSION;

const baseURL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH
  : window.location.protocol +
    "//" +
    window.location.hostname +
    process.env.VUE_APP_API_PATH;

export const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const interceptors = {
  TOKEN_EXPIRED(error) {
    return new Promise((resolve) => {
      store.dispatch("token/setIsRefreshingAccessToken", true);
      axios
        .post(
          baseURL + "/auth/refresh",
          {
            web_secret_token:
              localStorage.getItem("rememberMe") === "true"
                ? localStorage.getItem("web_secret_token")
                : sessionStorage.getItem("web_secret_token"),
          },
          {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("rememberMe") === "true"
                  ? localStorage.getItem("web_secret_token")
                  : sessionStorage.getItem("web_secret_token")
              }`,
            },
          }
        )
        .then((response) => {
          if (localStorage.getItem("rememberMe") === "true") {
            localStorage.setItem("access_token", response.data.access_token);
          } else {
            sessionStorage.setItem("access_token", response.data.access_token);
          }
          error.config.headers.Authorization = `Bearer ${response.data.access_token}`;
          resolve(api.request(error.config));
        })
        .catch((err) => {
          if (
            err.response.data.subcode === "TOKEN_EXPIRED" ||
            err.response.data.code === 401
          ) {

            if (window.location.href.includes("confirm-login")){
              window.toastr.error("Código de validação incorreto", "Erro");
              store.dispatch("login/confirmLogin"); 
            }
                          
            else
              store.dispatch("login/logoutCache");
          }
        })
        .finally(() => {
          store.dispatch("token/setIsRefreshingAccessToken", false);
        });
    });
  },
};

api.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("access_token")
        : sessionStorage.getItem("access_token")
    }`;
    store.dispatch("incrementLoad");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    // store.dispatch("changeLoad", false);
    store.dispatch("decrementLoad");
    return response;
  },
  function (error) {
    store.dispatch("decrementLoad");
    if (error.response.data.subcode === "FRESH_TOKEN_REQUIRED") {
      window.toastr.error(
        "Login expirado, ação sensível, redirecionando para login...",
        "Erro",
        {
          closeButton: true,
          progressBar: true,
          timeOut: "3000",
        }
      );

      setTimeout(() => {
        store.dispatch("login/logout");
      }, 3000);
    } else if (
      error.response.status === 401 &&
      error.response.config.url !== "/auth"
    ) {
     // console.log(error.response);
      if (
        error.response.data.subcode === "NOT_AUTHORIZED" &&
        !window.location.href.includes("confirm-login") &&
        !window.location.href.includes("confirm-reset")
      ) {
        window.toastr.error("Usuário ou senha incorretos", "Erro", {
        
          
        });
      } else if (
        error.response.data.subcode === "NOT_AUTHORIZED" &&
        window.location.href.includes("confirm-reset")
      ) {
        window.toastr.error("Código de confirmação inválido", "Erro", {
          closeButton: true,
        });
      } else if (!store.getters["token/isRefreshingAccessToken"]) {
        return interceptors.TOKEN_EXPIRED(error);
      } else {
        return new Promise((resolve) => {
          const refreshingTokenInterval = setInterval(() => {
            if (!store.getters["token/isRefreshingAccessToken"]) {
              clearInterval(refreshingTokenInterval);
              resolve(api.request(error.config));
            }
          }, 200);
        });
      }
    } else if (
      error.response.data.subcode === "NOT_AUTHORIZED" &&
      error.response.config.url === "/auth"
    ) {
      window.toastr.error("Usuário ou senha incorretos", "Erro", {
        closeButton: true,
      });
    } else if (error.response.data.subcode === "NOT_AUTHORIZED") {
      window.toastr.error(error.response.data.message, "Erro", {
        closeButton: true,
      });
    }
    store.dispatch("changeLoad", false);

    return Promise.reject(error);
  }
);
