<template>
  <div class="wrapper" id="app">
    <headerDefault v-if="loggedIn || access_token" />
    <headerUnsigned v-else-if="page401" />
    <SideBar v-if="loggedIn || access_token" />
    <!-- Main Sidebar Container -->
    <div 
      v-bind:class="{
        'content-wrapper px-lg-5 px-xl-5 px-md-5 px-3 py-4':
          loggedIn || access_token,
      }"
    >
      <ModalPassword />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <Footer v-if="loggedIn || access_token || page401" />
  </div>
</template>

<script>
import headerDefault from "./components/Header.vue";
import headerUnsigned from "./components/HeaderUnsigned.vue";
import SideBar from "./components/Sidebar.vue";
import ModalPassword from "./components/ModalPassword.vue";
import Footer from "./components/Footer.vue";
import auth from "./auth";

export default {
  name: "app",
  data() {
    return {
      loggedIn: auth.loggedIn(),
      page401: window.location.pathname.includes('401'),
    };
  },
  computed: {
    access_token() {
      return this.$store.getters["login/access_token"];
    },
  },
  mounted() {
    window.addEventListener("storage", (event) => {
      if (event.storageArea != localStorage) return;
      if (event.key === "access_token") {
        if (event.newValue == null || event.newValue == "") {
          window.location.href = "/login";
        }
        else{
          window.location.reload();
        }
      }
    });
  },
  components: {
    headerDefault,
    headerUnsigned,
    SideBar,
    Footer,
    ModalPassword,
  },
};
</script>

<style lang="scss">
html,
body {
  // min-height: 101vh; 
  max-width: 100%;
  overflow-x: hidden;
}
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0s;
}
.fade-enter,
.fade-leave-to,
.fade-leave-active {
  transform: translateX(10px);
  opacity: 0;
}
.modal-backdrop {
  opacity: 0.5 !important;
}
.btn-link {
  color: #2b97d6;
  font-size: 14px;
}
.rounded-button {
  width: 30px !important;
  height: 30px !important;
  background: #2b97d6;
  border-radius: 10px;
}
</style>
<style src="bootstrap-vue/dist/bootstrap-vue.css"></style>
