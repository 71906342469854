import { api } from "../services/api";
import {displayErrMsg} from "../utils/errors";
//import gateway from "./gateway";
// import router from '../router'

export default {
  namespaced: true,
  state: {
    geofence: null,
    geofences: [],
    gateways: [],

    availableGateways: [],
  },
  getters: {
    geofence: (state) => state.geofence,
    geofences: (state) => state.geofences,
    gateways: (state) => state.gateways,

    availableGateways: (state) => state.availableGateways,
  },
  mutations: {
    ADD_GEOFENCE(state, data) {
      state.geofences.push(data);
    },
    SET_GEOFENCES(state, payload) {
      state.geofences = payload;
    },
    ASSIGN_GATEWAY(state, data) {
      state.gateways.push(data);
    },
    UPDATE_GEOFENCE(state, data) {
      const index = state.geofences.findIndex((item) => item.id === data.id);
      state.geofences[index].id = data.id;
      state.geofences[index].geo_name = data.geo_name;
      state.geofences[index].geo_type = data.geo_type;
      state.geofences[index].location = data.location;
      state.geofences[index].radius = data.radius;
      state.geofences[index].x_position = data.x_position;
      state.geofences[index].y_position = data.y_position;
    },
    DELETE_GEOFENCE(state, id) {
      state.geofences = state.geofences.filter(
        (geofence) => geofence.id !== id
      );
    },
    UNASSIGN_GATEWAY(state, data) {
      state.gateways = state.gateways.filter((gateway) => gateway.id !== data);
    },
    SET_GEO(state, data) {
      state.geofence = data;
    },
    SET_AVAILABLE_GATEWAYS(state, data) {
      state.availableGateways = data;
    },
  },
  actions: {
    async getByZone(context, zone_id) {
      this.load = true;
      try {
        const res = await api.get(`/zones/${zone_id}/geofences`);
        context.commit("SET_GEOFENCES", res.data);
      } catch (err) {
        displayErrMsg(err.response.status, 'Geofence', 'GET_BY_ZONE');
      }
    },
    async saveGeofence(context, { zone_id, sendGeofence }) {
      if (!sendGeofence.id) {
        await api
          .post("zones/" + zone_id + "/geofences", {
            geo_name: sendGeofence.geo_name,
            geo_type: sendGeofence.geo_type,
            location: sendGeofence.location,
            radius: sendGeofence.radius,
            x_position: sendGeofence.x_position,
            y_position: sendGeofence.y_position,
          })
          .then((res) => {
            context.commit("ADD_GEOFENCE", res.data);
            displayErrMsg("GLOBAL_SUCCESS_ADD", 'Geofence', 'SAVE_GEOFENCE');
          })
          .catch((err) => {
            displayErrMsg(err.response.status, 'Geofence', 'SAVE_GEOFENCE');
          });
      } else {
        context.dispatch("updateGeofence", {
          zone: { id: zone_id },
          geofence: sendGeofence,
        });
      }
    },
    async updateGeofence(context, { zone, geofence }) {
      await api
        .put("zones/" + zone.id + "/geofences", {
          id: geofence.id,
          geo_name: geofence.geo_name,
          geo_type: geofence.geo_type,
          location: geofence.location,
          radius: geofence.radius,
          x_position: geofence.x_position,
          y_position: geofence.y_position,
        })
        .then((res) => {
          context.commit("UPDATE_GEOFENCE", res.data);
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Geofence', 'UPDATE_GEOFENCE');
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Geofence', 'UPDATE_GEOFENCE');
        });
    },
    async deleteGeofence(context, id) {

      await api
        .delete(`geofences/${id}`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Geofence', 'DELETE_GEOFENCE');
          context.commit("DELETE_GEOFENCE", id);
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Geofence', 'DELETE_GEOFENCE');
        });
    },
    async getGeo(context, id) {
      await api
        .get(`geofences/${id}`)
        .then((res) => {
          context.commit("SET_GEO", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Geofence', 'GET_GEO');
        });
    },
    async getAvailableGateways(context, customerId) {
      await api
        .get(`gateways/customer/${customerId}`)
        .then((res) => {
          context.commit("SET_AVAILABLE_GATEWAYS", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.status, 'Geofence', 'GET_AVAILABLE_GATEWAYS');
        });
    },
    async assignGateway(context, { customer_id, data, gateway_list }) {
      gateway_list.forEach(async (gateway_id) => {
        await api
          .patch(
            `/gateways/${gateway_id}/customer/${customer_id}/geofences/assign`,
            {
              id_list: [data.id],
            }
          )
          .then(() => {
            displayErrMsg("ASSIGN_GATEWAY_SUCCESS", 'Coletor', 'ASSIGN_GATEWAY');
            context.commit("ASSIGN_GATEWAY", data);
          })
          .catch((err) => {
            displayErrMsg(err.response.status, 'Coletor', 'ASSIGN_GATEWAY');
          });
      });
    },
    async unassignGateway(context, { customer_id, data, unaassign_list }) {
      unaassign_list.forEach(async (unaassign_list) => {
        await api
          .patch(
            `/gateways/${unaassign_list}/customer/${customer_id}/geofences/unassign`,
            {
              id_list: [data.id],
            }
          )
          .then(() => {
            displayErrMsg("UNASSIGN_GATEWAY_SUCCESS", 'Coletor', 'UNASSIGN_GATEWAY');
            context.commit("UNASSIGN_GATEWAY", data);
          })
          .catch((err) => {
            displayErrMsg(err.response.status, 'Coletor', 'UNASSIGN_GATEWAY');
          });
      });
    },
  },
};
