import { api } from "./services/api";
export default {
  loggedIn() {
    if (localStorage.getItem("rememberMe") === "true") {
      return localStorage.getItem("access_token") &&
        localStorage.getItem("access_token") !== ""
        ? true
        : false;
    } else {
      return sessionStorage.getItem("access_token") &&
        sessionStorage.getItem("access_token") !== ""
        ? true
        : false;
    }
  },
  logout() {
    return api.put("/v0/auth/logout").then((response) => {
      // Clear local storage
      localStorage.clear();
      return response;
    });
  },
};
