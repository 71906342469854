export function nullEmptyProps(jsonObj, depth = 2) {
  Object.keys(jsonObj).map((key) => {
    if (
      jsonObj[key] === "" ||
      jsonObj[key] === null ||
      jsonObj[key] === undefined
    ) {
      jsonObj[key] = null;
    } else if (
      typeof jsonObj[key] === "object" &&
      !Array.isArray(jsonObj[key])
    ) {
      if (depth > 1) {
        jsonObj[key] = nullEmptyProps(jsonObj[key], depth - 1);
      }
    }
  });
  return jsonObj;
}
