import { api } from "../services/api";

export default {
  namespaced: true,
  state: {
    parameter: null,
    customer: null,
  },

  mutations: {
    async SAVE_PARAMETER(state, data) {
      state.parameter.push(data);
    },
    async GET_PARMS(context, { data }) {
      await api
        .get("customer/" + data.customerId + "/ml/parms")
        .then((response) => {
          context.parameter = response.data;

          // data.parameter = response.data;
        });

      // console.log(context).then((res) => {
      //   data.parameter["id"] = res.data.id;
      // })
    },
    //   async UPDATE_PARAMETER(context, data) {

    //     await api.put(
    //       "customer/" + context.rootGetters["customer/customer"].id + "/ml/parms",
    //       data.parameter
    //     );
    //     console.log(data.parameter).then(() => {
    //       window.toastr.success(
    //         "Alterado com sucesso",
    //         "Parametrização Fisiológica"
    //       );
    //     });
    //
    //   },
    //   async UPDATE_PARAMETER(state, data) {
    //     state.parameter.push(data);
    //   },
  },

  getters: {
    parameter: (state) => state.parameter,
    customer: (state) => state.customer,
  },
  actions: {
    async getparms(context, data) {
      context.commit("GET_PARMS", { data });
    },
    async saveParameter(context, data) {
      await api
        .post(
          "customer/" +
            context.rootGetters["customer/customer"].id +
            "/ml/parms",
          data.parameter
        )
        .then((res) => {
          data.parameter["id"] = res.data.id;
          window.toastr.success("Adicionado com sucesso", "Parâmetro");
          context.commit("SAVE_PARAMETER", res.data);
        });
    },
    // async updateParameter(context, data) {
    //   console.log("updateparameter");
    //   console.log(context);
    //   console.log(data);
    //   context.commit("UPDATE_PARAMETER", { data });
    // },
    async updateParameter(context, data) {
      await api
        .put(
          "customer/" +
            context.rootGetters["customer/customer"].id +
            "/ml/parms",
          data.parameter
        )
        .then((res) => {
          data.parameter["id"] = res.data.id;

          context.commit("UPDATE_PARAMETER", res.data);
          window.toastr.success("Adicionado com sucesso", "Parâmetro");
        });
    },
  },
};
