// Definition of default language & language maps.
const defaultLang = "pt-br";
const langMap = {
  "pt-br": require("./msgs/pt_br.json"),
  "en-us": require("./msgs/bulls_foot.json"),
};

// (m)essage, (t)itle, (o)ptions for Toastr (optional)
export const display = {
  s: (m, t, o) => {
    window.toastr.success(m, t, o ? o : {});
  },
  e: (m, t, o) => {
    window.toastr.error(m, t, o ? o : {});
  },
  w: (m, t, o) => {
    window.toastr.warning(m, t, o ? o : {});
  },
  i: (m, t, o) => {
    window.toastr.info(m, t, o ? o : {});
  },
};

export class ErrMsg {
  constructor(message, type) {
    this.message = message ? message : "Um erro não esperado acontenceu. (f1x)";
    this.type = type ? type : "e";
  }
}

// replace {{vars}} into values from data
// ex: template = "Hello {{name}}", data = {'name': "John"}
// ex2: template = "Hello {{user.name}}", data = {'user.name': "John"}
export function replaceTemplate(template, data) {
  if (!template.includes("{{")) {
    return template;
  }
  let result = template;
  for (const key in data) {
    result = result.replaceAll(`{{${key}}}`, data[key]);
  }
  return result;
}

// Wrapper function for the frontend to use since most of the time we use default
// language.
export function frontMsg(mKey, extraData){
  displayErrMsg(mKey, "Atenção", "GLOBAL_INTENT_FALLBACK", extraData);
}

// Displays Toastr message based on error code with title as source and i as intent
// ex: displayError("404", "Login", "LOGIN_USER", {'name': "John", 'age': 20})
// ex: displayError("500", "Tag", "DELETE_TAG")
// extraData is optional
export function displayErrMsg(mKey, source, i, extraData) {
  let msgObj = mKey && errors[mKey] ? errors[mKey] : errors.GLOBAL_DEFAULT;
  let currIntent =
    i && intents[i] ? intents[i] : intents.GLOBAL_INTENT_FALLBACK;
  if(extraData){
    currIntent = {...currIntent, ...extraData};
  }
  let message = replaceTemplate(msgObj.message, currIntent);
  let t = msgObj.type;
  display[t](message, source, { closeButton: true, escapeHtml: true });
}

// Tests all error messages and displays them with the correct intent
export async function testAllErrors() {
  for (const key in errors) {
    for (const i in intents) {
      displayErrMsg(key, i, i);
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }
}

// read the JSON file and return the errors and intents
function readJSON(selLan) {
  let lang = selLan ? selLan : defaultLang;
  let arr = {};
  let intents = {};

  for (const i in langMap[lang].errors) {
    for (const key in langMap[lang].errors[i]) {
      arr[key] = new ErrMsg(
        langMap[lang].errors[i][key].m,
        langMap[lang].errors[i][key].t
      );
    }
  }
  for (const i in langMap[lang].intents) {
    for (const key in langMap[lang].intents[i]) {
      intents[key] = langMap[lang].intents[i][key];
    }
  }
  return [arr, intents];
}

let res = readJSON();
export const errors = res[0];
export const intents = res[1];