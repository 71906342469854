import { api } from "../services/api";
import router from "../router";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    groupCustomers: [],
    customers: [],
    customer: null,
    customerIndicators: [],
    customerReport: null,
    groups: [],
    tabIndex: 0,
    timers: {},
    timersExist: false,
  },

  mutations: {
    async UPDATE_CUSTOMER(state, { data }) {
      await api
        .put("customers", data.customer)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Cliente", "UPDATE_CUSTOMER");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Cliente", "UPDATE_CUSTOMER");
        });
    },
    async GET_CUSTOMERS(state) {
      await api
        .get("customers?sortBy=company_name")
        .then((response) => {
          state.customers = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Cliente", "GET_CUSTOMERS");
        });
    },
    async GET_CUSTOMER(state, { data }) {
      await api
        .get(`customers/${data.customerId}`)
        .then((response) => {
          state.customer = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Cliente", "GET_CUSTOMER");
        });
    },
    async GET_GROUPS(state) {
      await api
        .get("users/groups")
        .then((response) => {
          state.groups = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Group", "GET_GROUPS");
        });
    },
    async GET_INDICATORS(state, { data }) {
      await api
        .get(`customers/${data.customerId}/dashboards`)
        .then((response) => {
          state.customerIndicators = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Indicator", "GET_INDICATORS");
        });
    },
    async GET_REPORT(state, { data }) {
      await api
        .get(`dashboards/${data.reportId}`)
        .then((response) => {
          state.customerReport = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Report", "GET_REPORT");
        });
    },
    async DELETE_CUSTOMER(state, { data }) {
      await api
        .delete(`customers/${data.id}`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", "Cliente", "DELETE_CUSTOMER");

          state.customers = state.customers.filter(
            (customer) => customer.id !== data.id
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Cliente", "DELETE_CUSTOMER");
        });
    },
    async SAVE_CUSTOMER(state, { data }) {
      await api
        .post(`customers`, data)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Cliente", "SAVE_CUSTOMER");
          let { data } = res;
          state.customers.push({ data });
          router.push({
            name: "customer",
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Cliente", "SAVE_CUSTOMER");
        });
    },
    UPDATE_TABINDEX(state, index) {
      state.tabIndex = index;
    },
    SET_GROUP_CUSTOMERS(state, data) {
      state.groupCustomers = data;
    },

    async GET_TIMERS(state, { data }) {
      await api
        .get(`time/app/customer/${data.customer_id}`)
        .then((response) => {
          if (Object.keys(response.data).length === 0) {
            state.timersExist = false;
            return;
          }
          state.timers = response.data;
          state.timersExist = true;
        })
        .catch((err) => {
          state.timersExist = false;
        });
    },

    async ADD_TIMERS(state, { data }) {
      await api
        .post(`time/app/customer/${data.customer_id}`, data.timers)
        .then((response) => {
          state.timers = response.data;
          state.timersExist = true;
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Timer", "ADD_TIMERS");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Timer", "ADD_TIMERS");
          state.timersExist = false;
        });
    },

    async SAVE_TIMERS(state, { data }) {
      await api
        .put(`time/app/customer/${data.customer_id}`, data.timers)
        .then((response) => {
          state.timers = response.data;
          state.timersExist = true;
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Timer", "UPDATE_TIMERS");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Timer", "UPDATE_TIMERS");
          state.timersExist = false;
        });
    },
  },
  getters: {
    customers: (state) => state.customers,
    groupCustomers: (state) => state.groupCustomers,
    customer: (state) => state.customer,
    customerIndicators: (state) => state.customerIndicators,
    customerReport: (state) => state.customerReport,
    groups: (state) => state.groups,
    tabIndex: (state) => state.tabIndex,
    timers: (state) => state.timers,
    timersExist: (state) => state.timersExist,
  },
  actions: {
    get(context) {
      context.commit("GET_CUSTOMERS");
    },
    getCustomer(context, data) {
      context.commit("GET_CUSTOMER", { data });
    },
    getGroups(context) {
      context.commit("GET_GROUPS");
    },
    getIndicators(context, data) {
      context.commit("GET_INDICATORS", { data });
    },
    getReport(context, data) {
      context.commit("GET_REPORT", { data });
    },
    updateCustomer(context, data) {
      context.commit("UPDATE_CUSTOMER", { data });
    },
    saveCustomer(context, data) {
      context.commit("SAVE_CUSTOMER", { data });
    },
    deleteCustomer(context, data) {
      context.commit("DELETE_CUSTOMER", { data });
    },
    updateTabIndex(context, index) {
      context.commit("UPDATE_TABINDEX", index);
    },
    getTimers(context, data) {
      context.commit("GET_TIMERS", { data });
    },
    addTimers(context, data) {
      context.commit("ADD_TIMERS", { data });
    },
    saveTimers(context, data) {
      context.commit("SAVE_TIMERS", { data });
    },
    // Rota de get vo/customer com filtro para pegar informações pbi
    getGroupCustomers(context, groupName) {
      api
        .get(`customers?sortBy=company_name&group_name=${groupName}`)
        .then((response) => {
          context.commit("SET_GROUP_CUSTOMERS", response.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Grupo", "GET_GROUP_CUSTOMERS");
        });
    },
  },
};
