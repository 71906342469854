<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div id="flooradd-div-content" class="content-header px-0">
      <h1>Adicionar andar</h1>
    </div>

    <div
      class="modal fade"
      id="modalMap"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalMapLabel"
      aria-hidden="true"
    >
      <div id="flooradd-modal-dialog" class="modal-dialog" role="document">
        <form id="flooradd-mapset-form" @submit="mapSet" novalidate="true">
          <div id="flooradd-modal-content" class="modal-content">
            <div id="flooradd-modal-header" class="modal-header">
              <h5 class="modal-title" id="ModalMapLabel">Adicionar planta</h5>
              <button
                id="flooradd-btn-close"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div id="flooradd-modal-body" class="modal-body">
              <div id="flooradd-modal-row" class="row">
                <div id="flooradd-col-12" class="col-12">
                  <div id="flooradd-form-group" class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      id="flooradd-mapname-input"
                      v-model="map.map_name"
                      required
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div id="flooradd-col-122" class="col-12">
                  <div id="flooradd-form-group-desc" class="form-group">
                    <label>Descrição</label>

                    <input
                      id="flooradd-mapdesc-input"
                      v-model="map.description"
                      type="text"
                      maxlength="255"
                      class="form-control"
                      placeholder="Descrição"
                    />
                  </div>
                </div>

                <div id="flooradd-col-123" class="col-12">
                  <div id="flooradd-form-group-file" class="form-group">
                    <label>
                      Arquivo
                      <span class="required">*</span>
                    </label>
                    <div id="flooradd-custom-filedv" class="custom-file mb-3">
                      <input
                        type="file"
                        ref="fileInput"
                        v-on:change="onFileChange"
                        class="custom-file-input"
                        id="validatedCustomFile"
                        required
                      />
                      <label
                        id="flooradd-custom-file"
                        class="custom-file-label"
                        for="validatedCustomFile"
                        >{{ fileName }}</label
                      >
                    </div>
                  </div>
                </div>

                <div id="flooradd-col-124" class="col-12">
                  <div id="flooradd-form-group-scale" class="form-group">
                    <label>
                      Escala
                      <span class="required">*</span>
                    </label>

                    <input
                      id="flooradd-mapscale-input"
                      v-model="map.scale"
                      required
                      type="number"
                      class="form-control"
                      placeholder="Escala"
                    />
                    <small id="flooradd-form-text-scale" class="form-text text-muted">
                      Valor da escala representa a quantidade de pixels para 1
                      metro. Por exemplo, uma escala com valor 20, significa 20
                      pixels para 1 metro na planta.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div id="flooradd-modal-footer-rem" class="modal-footer">
              <button
                id="flooradd-removemap-but"
                type="button"
                @click="removeMap"
                class="btn btn-outline-dark"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" id="flooradd-btn-add" class="btn btn-primary">Adicionar</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div id="flooradd-col-126" class="col-12 col-sm-12 p-0 col-md-12">
      <div id="flooradd-card-direct" class="card direct-chat direct-chat-primary">
        <div id="flooradd-card-body" class="card-body">
          <div id="flooradd-dvp-4" class="p-4 bg-white rounded">
            <form id="flooradd-form-savefl" @submit="saveFloor" novalidate="true">
              <div class="row">
                <div id="flooradd-col-61" class="col-6 pr-4">
                  <div id="flooradd-form-group-wrapper" class="form-group">
                    <label>
                      Nome
                      <span class="required">*</span>
                    </label>

                    <input
                      v-model="floor_name"
                      required
                      type="text"
                      maxlength="255"
                      id="flooradd-floorname-in"
                      class="form-control"
                      placeholder="Nome"
                    />
                  </div>
                </div>
                <div id="flooradd-col-6-wrapper" class="col-6 pl-4">
                  <div id="flooradd-form-group-desc" class="form-group">
                    <label>
                      Descrição
                      <span class="required">*</span>
                    </label>

                    <input
                      v-model="description"
                      required
                      type="text"
                      maxlength="255"
                      id="flooradd-desc-in"
                      class="form-control"
                      placeholder="Descrição"
                    />
                  </div>
                </div>
                <div v-if="filePreview" id="flooradd-col-12-fpre" class="col-12 pr-4 overflow-auto">
                  <img id="flooradd-floimg-preview" class="img-preview" :src="filePreview" alt />
                </div>

                <div class="col-12 mb-4 mt-3 pr-4">
                  <a
                    v-if="filePreview"
                    id="flooradd-rm-map-a"
                    class="mr-4 text-danger"
                    data-toggle="modal"
                    @click="removeMap"
                    href="#deleteMap"
                    >Excluir planta</a
                  >
                  <a
                    v-else
                    data-toggle="modal"
                    id="flooradd-add-map-a"
                    data-target="#modalMap"
                    ref="myBtnModal"
                    href="#modalMap"
                    >Adicionar planta</a
                  >
                </div>
              </div>

              <div class="row p-1">
                <button id="flooradd-save-btn" class="btn btn-primary px-5" :disabled="load">
                  <i v-if="load" id="floordadd-load-spin" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Breadcrumb from "../../components/Breadcrumb.vue";

// import axios from "axios";
export default {
  name: "AddFloor",
  data: function () {
    return {
      description: "",
      floor_name: "",
      map: {
        description: "",
        file_archive: "",
        map_name: "",
        scale: "",
      },
      file: "",
      filePreview: "",
      fileName: "",
      breadcrumbList: [],
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }

    if (this.$route.params.buildingId) {
      this.$store.dispatch("building/getBuilding", {
        buildingId: this.$route.params.buildingId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Edifício" }
      );
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    isFloorValid() {
      if (!this.floor_name || this.floor_name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome do andar" }
        );
        return false;
      }
      if (!this.description || this.description.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Descrição" }
        );
        return false;
      }
      if (!this.map.file_archive && !this.map.map_name && !this.map.scale) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          {field: "Planta"}
        );
        this.showModal();
        return false;
      }

      return true;
    },
    saveFloor(e) {
      e.preventDefault();
      if (!this.isFloorValid()) {
        return;
      }
      this.$store.dispatch("floor/saveFloor", {
        building: this.building,
        customer: this.customer,
        floor: {
          building_id: this.building.id,
          floor_name: this.floor_name,
          description: this.description,
          map: this.map,
        },
      });
    },
    resetInput() {
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileName = files[0].name;

      let reader = new FileReader();
      reader.onload = (event) => {
        this.file = event.target.result;
        this.map.file_archive = this.file;
      };
      reader.readAsDataURL(files[0]);
    },
    mapSet(e) {
      e.preventDefault();
      this.hideModal();
      this.filePreview = this.file;
    },
    showModal() {
      const elem = this.$refs.myBtnModal;
      elem.click();
    },
    hideModal() {
      var modal = document.querySelector("#modalMap");
      var jQueryObj = Object.keys(modal).filter(
        (key) =>
          key.toString().indexOf("jQuery") !== -1 &&
          modal[key].hasOwnProperty("bs.modal")
      );

      modal[jQueryObj]["bs.modal"].hide();
    },
    removeMap(e) {
      e.preventDefault();
      this.map.description = "";
      this.filePreview = "";
      this.fileName = "";
      this.file = "";
      this.map.map_name = "";
      this.map.scale = "";
      this.map.file_archive = "";
      this.resetInput();
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
      if (this.building) {
        this.breadcrumbList[2].label = `Editar Edíficio ${this.building.building_name}`;
      }
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    building() {
      if (this.building) {
        this.updateList();
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    building() {
      return this.$store.getters["building/building"];
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style scoped>
.custom-file label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 90px;
}
.custom-file-label::after {
  content: "Arquivo...";
}
.img-preview {
  width: 100%;
}
</style>
