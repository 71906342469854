<template>
  <form @submit="updateParameter">
    <div class="row">
      <div class="col-sm">
        <div class="col-sm" style="margin-bottom: 25px">
          <p class="my-item">Pressão sanguínea</p>
          <div class="row container" style="width: 50%">
            <TwInput
              v-mask="'###'"
              class="pt-2"
              col_lg="10"
              label="Valor mínimo ( sistólica )"
            >
              <input
                v-model="parameter.pressure.hbp_lower_limit"
                class="form-control"
                type="number"
                placeholder="Limite mínimo"
                id="hbp_lower_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Pressão arterial sistólica mínima deve estar entre 50 e 150
              </small>
            </TwInput>
            <TwInput
              class="pt-2"
              col_lg="10"
              label="Valor máximo ( sistólica )"
            >
              <input
                v-model="parameter.pressure.hbp_upper_limit"
                class="form-control"
                v-mask="'###'"
                type="number"
                placeholder="Limite máximo"
                id="hbp_upper_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Pressão arterial sistólica máxima deve estar entre 100 e 200
              </small>
            </TwInput>
          </div>
          <div class="row container" style="width: 50%">
            <TwInput
              class="pt-2"
              col_lg="10"
              v-mask="'###'"
              label="Valor mínimo ( diastólica )"
            >
              <input
                v-model="parameter.pressure.lbp_lower_limit"
                class="form-control"
                type="number"
                placeholder="Limite mínimo"
                id="lbp_lower_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Pressão arterial diastólica mínima deve estar entre 20 e 100
              </small>
            </TwInput>
            <TwInput
              class="pt-2"
              col_lg="10"
              v-mask="'###'"
              label="Valor máximo ( diastólica )"
            >
              <input
                v-model="parameter.pressure.lbp_upper_limit"
                class="form-control"
                type="number"
                placeholder="Limite máximo"
                id="lbp_upper_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Pressão arterial diastólica máxima deve estar entre 80 e 150
              </small>
            </TwInput>
          </div>
        </div>
        <div class="col-sm" style="margin-bottom: 25px">
          <div class="row container" style="width: 50%">
            <p class="my-item">Frequência</p>

            <TwInput class="pt-2" col_lg="10" label="Valor mínimo">
              <input
                v-model="parameter.rate.lower_limit"
                label="Limite mínimo"
                v-mask="'##'"
                class="form-control"
                type="number"
                placeholder="Limite mínimo"
                id="Rate_lower_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Frequência mínima deve estar entre 30 e 60
              </small>
            </TwInput>
            <TwInput class="pt-2" col_lg="10" label="Valor máximo">
              <input
                v-model="parameter.rate.upper_limit"
                class="form-control"
                v-mask="'###'"
                type="number"
                placeholder="Limite máximo"
                id="Rate_upper_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Frequência máxima deve estar entre 150 e 250
              </small>
            </TwInput>
          </div>
          <div class="row container" style="width: 50%">
            <p class="my-item">Temperatura Corporal</p>
            <TwInput class="pt-2" col_lg="10" label="Valor mínimo">
              <input
                v-model="parameter.temperature.lower_limit"
                class="form-control"
                v-mask="'##.#'"
                text
                type="text"
                placeholder="Limite mínimo"
                id="Temperature_lower_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Temperatura mínima deve estar entre 34 e 35.5
              </small>
            </TwInput>
            <TwInput class="pt-2" col_lg="10" label="Valor máximo">
              <input
                v-model="parameter.temperature.upper_limit"
                class="form-control"
                type="number"
                v-mask="'##'"
                placeholder="Limite máximo"
                id="Temperature_upper_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Temperatura máxima deve estar entre 38 e 42
              </small>
            </TwInput>
          </div>
        </div>
        <div class="col-sm">
          <div class="col-sm container" style="margin-bottom: 25px">
            <p class="my-item">Spo2</p>
            <TwInput class="pt-2" col_lg="10" label="Valor mínimo">
              <input
                v-model="parameter.spo2.lower_limit"
                class="form-control"
                type="number"
                v-mask="'###'"
                placeholder="Limite mínimo"
                id="spo2_lower_limit"
              />
              <small id="permissionaHelp" class="form-text text-muted">
                Spo2 mínima deve entrar entre 60 e 100
              </small>
            </TwInput>
          </div>
        </div>
      </div>
    </div>
    <div class="row p-1">
      <button id="saveParameters" class="btn btn-primary px-5" :disabled="load">
        <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
      </button>
    </div>
  </form>
</template>

<script>
import TwInput from "@/components/TwInput.vue";

export default {
  name: "PhysiologicalParameterTab",
  data: function () {
    return {
      parameter: {
        pressure: {
          hbp_lower_limit: "",
          hbp_upper_limit: "",
          lbp_lower_limit: "",
          lbp_upper_limit: "",
        },
        rate: {
          lower_limit: "",
          upper_limit: "",
        },
        spo2: {
          lower_limit: "",
        },
        temperature: {
          lower_limit: "",
          upper_limit: "",
        },
        // id: "",
      },
    };
  },
  mounted() {
    // this.parameter = this.$route.params.parameter;
    // this.$store.dispatch("customer/get");
    // this.$store.dispatch("machinelearning/getparms");

    if (this.$route.params.customerId) {
      this.$store.dispatch("machinelearning/getparms", {
        customerId: this.$route.params.customerId,
        parameter: {
          ...this.parameter,
        },
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "de Parametrização" }
      );
      this.$router.push("/");
    }
  },
  methods: {
    async saveParameters(context) {
      event.preventDefault();
      if (this.isParameterValid()) {
        return;
      }

      console.log(context);
      this.$store.dispatch("machinelearning/saveParameter", {
        parameter: {
          ...this.parameter,
        },
      });
    },
    updateParameter(context) {

      event.preventDefault();
      if (this.isParameterValid()) {
        return;
      }

      console.log(context);
      this.$store.dispatch("machinelearning/updateParameter", {
        parameter: {
          ...this.parameter,
        },
      });
    },
    isParameterValid() {
      if (
        Number(this.parameter.pressure.hbp_lower_limit) &&
        Number(this.parameter.pressure.hbp_lower_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Sistólica Mínima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        Number(this.parameter.pressure.lbp_lower_limit) &&
        Number(this.parameter.pressure.lbp_lower_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Diastólica Mínima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        Number(this.parameter.pressure.hbp_lower_limit) ==
        Number(this.parameter.pressure.hbp_upper_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Sistólica Mínima", numbercondition: "diferente de Pressão Arterial Sistólica Máxima" }
        );
        return false;
      }
      if (
        Number(this.parameter.pressure.lbp_lower_limit) ==
        Number(this.parameter.pressure.lbp_upper_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Diastólica Mínima", numbercondition: "diferente de Pressão Arterial Diastólica Máxima" }
        );
        return false;
      }
      if (
        (Number(this.parameter.pressure.hbp_lower_limit) &&
          Number(this.parameter.pressure.hbp_lower_limit) < 50) ||
        (Number(this.parameter.pressure.hbp_lower_limit) &&
          Number(this.parameter.pressure.hbp_lower_limit) > 150)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Sistólica Mínima", numbercondition: "entre 50 e 150" }
        );
        return false;
      }
      if (
        (Number(this.parameter.pressure.lbp_lower_limit) &&
          Number(this.parameter.pressure.lbp_lower_limit) < 20) ||
        (Number(this.parameter.pressure.lbp_lower_limit) &&
          Number(this.parameter.pressure.lbp_lower_limit) > 100)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Diastólica Mínima", numbercondition: "entre 20 e 100" }
        );
        return false;
      }
      if (
        (Number(this.parameter.pressure.hbp_upper_limit) &&
          Number(this.parameter.pressure.hbp_upper_limit) < 100) ||
        (Number(this.parameter.pressure.hbp_upper_limit) &&
          Number(this.parameter.pressure.hbp_upper_limit) > 200)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Sistólica Máxima", numbercondition: "entre 100 e 200" }
        );
        return false;
      }
      if (
        (Number(this.parameter.pressure.lbp_upper_limit) &&
          Number(this.parameter.pressure.lbp_upper_limit) < 80) ||
        (Number(this.parameter.pressure.lbp_upper_limit) &&
          Number(this.parameter.pressure.lbp_upper_limit) > 150)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Diastólica Máxima", numbercondition: "entre 80 e 150" }
        );
        return false;
      }
      if (
        Number(this.parameter.pressure.hbp_upper_limit) &&
        Number(this.parameter.pressure.hbp_upper_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Sistólica Máxima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        Number(this.parameter.pressure.hbp_upper_limit) <
        Number(this.parameter.pressure.hbp_lower_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Sistólica Máxima", numbercondition: "maior que Pressão Arterial Sistólica Mínima" }
        );
        return false;
      }
      if (
        Number(this.parameter.pressure.lbp_upper_limit) <
        Number(this.parameter.pressure.lbp_lower_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Pressão Arterial Diastólica Máxima", numbercondition: "maior que Pressão Arterial Diastólica Mínima" }
        );
        return false;
      }
      if (
        Number(this.parameter.rate.lower_limit) &&
        Number(this.parameter.rate.lower_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Frequência Cardíaca Mínima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        Number(this.parameter.rate.lower_limit) ==
        Number(this.parameter.rate.upper_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Frequência Cardíaca Mínima", numbercondition: "diferente de Frequência Cardíaca Máxima" }
        );
        return false;
      }

      if (
        (Number(this.parameter.rate.lower_limit) &&
          Number(this.parameter.rate.lower_limit) < 30) ||
        (Number(this.parameter.rate.lower_limit) &&
          Number(this.parameter.rate.lower_limit) > 60)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Frequência Cardíaca Mínima", numbercondition: "entre 30 e 60" }
        );
        return false;
      }

      if (
        Number(this.parameter.rate.upper_limit) &&
        Number(this.parameter.rate.upper_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Frequência Cardíaca Máxima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        (Number(this.parameter.rate.upper_limit) &&
          Number(this.parameter.rate.upper_limit) < 150) ||
        (Number(this.parameter.rate.upper_limit) &&
          Number(this.parameter.rate.upper_limit) > 250)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Frequência Cardíaca Máxima", numbercondition: "entre 150 e 250" }
        );
        return false;
      }
      if (
        Number(this.parameter.rate.upper_limit) <
        Number(this.parameter.rate.lower_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Frequência Cardíaca Máxima", numbercondition: "maior que Frequência Cardíaca Mínima" }
        );
        return false;
      }
      if (
        Number(this.parameter.temperature.lower_limit) &&
        Number(this.parameter.temperature.lower_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Temperatura Mínima", numbercondition: "maior que 0" }
        );
        return false;
      }

      if (
        Number(this.parameter.temperature.lower_limit) ==
        Number(this.parameter.temperature.upper_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Temperatura Mínima", numbercondition: "diferente de Temperatura Máxima" }
        );
        return false;
      }
      if (
        (Number(this.parameter.temperature.lower_limit) &&
          Number(this.parameter.temperature.lower_limit) < 34) ||
        (Number(this.parameter.temperature.lower_limit) &&
          Number(this.parameter.temperature.lower_limit) > 35.5)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Temperatura Mínima", numbercondition: "entre 34 e 35.5" }
        );
        return false;
      }
      if (
        (Number(this.parameter.temperature.upper_limit) &&
          Number(this.parameter.temperature.upper_limit) < 38) ||
        (Number(this.parameter.temperature.upper_limit) &&
          Number(this.parameter.temperature.upper_limit) > 42)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Temperatura Máxima", numbercondition: "entre 38 e 42" }
        );
        return false;
      }
      if (
        Number(this.parameter.temperature.upper_limit) &&
        Number(this.parameter.temperature.upper_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Temperatura Máxima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        Number(this.parameter.temperature.upper_limit) <
        Number(this.parameter.temperature.lower_limit)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Temperatura Máxima", numbercondition: "maior que Temperatura Mínima" }
        );
        return false;
      }
      if (
        Number(this.parameter.spo2.lower_limit) &&
        Number(this.parameter.spo2.lower_limit) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Saturação de Oxigênio Mínima", numbercondition: "maior que 0" }
        );
        return false;
      }
      if (
        (Number(this.parameter.spo2.lower_limit) &&
          Number(this.parameter.spo2.lower_limit) < 60) ||
        (Number(this.parameter.spo2.lower_limit) &&
          Number(this.parameter.spo2.lower_limit) > 100)
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Saturação de Oxigênio Mínima", numbercondition: "entre 60 e 100" }
        );
        return false;
      }
    },
  },
  components: {
    TwInput,
  },
  watch: {
    $route() {
      this.updateList;
    },
    machinelearning() {

      this.parameter.pressure.hbp_lower_limit =
        this.machinelearning.pressure.hbp_lower_limit;

      this.parameter.pressure.hbp_upper_limit =
        this.machinelearning.pressure.hbp_upper_limit ?? "";
      this.parameter.pressure.lbp_lower_limit =
        this.machinelearning.pressure.lbp_lower_limit ?? "";
      this.parameter.pressure.lbp_upper_limit =
        this.machinelearning.pressure.lbp_upper_limit ?? "";
      this.parameter.rate.lower_limit =
        this.machinelearning.rate.lower_limit ?? "";
      this.parameter.rate.upper_limit =
        this.machinelearning.rate.upper_limit ?? "";
      this.parameter.spo2.lower_limit =
        this.machinelearning.spo2.lower_limit ?? "";
      this.parameter.temperature.lower_limit =
        this.machinelearning.temperature.lower_limit ?? "";
      this.parameter.temperature.upper_limit =
        this.machinelearning.temperature.upper_limit ?? "";
      this.id = this.machinelearning.id;
      // this.id = customer();
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    machinelearning() {
      return this.$store.getters["machinelearning/parameter"];
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}
</style>
