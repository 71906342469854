<template>
  <div id="buildflo-content" class="mt-3">
    <div>
      <b-modal
        id="buildflo-modal1"
        size="xl"
        v-model="calibrarShow"
        hide-footer
      >
        <template #modal-header="{ close }">
          <h5 id="buildflo-h5-scale">Calibrar escala do andar</h5>
          <b-button id="buildflo-close-modal" variant="link" @click="close()">
            <span>X</span>
          </b-button>
        </template>
        <div id="buildflo-file-map" v-if="floor.map.file_archive">
          <span id="buildflo-span-ex" style="color: grey">
            Clique em dois pontos para formar uma reta
          </span>

          <CanvasImage
            id="buildflo-canvasimg-map"
            class="mt-3"
            width="1000"
            height="1000"
            :map="floor.map.file_archive"
            @imgSize="setImgSize"
            @distance="scaleConfirm"
          />
        </div>
        <div id="buildflo-no-plant" v-else>Andar não possui planta</div>
      </b-modal>
      <b-modal id="buildflo-modalb-show" v-model="modalShow" hide-footer>
        <template #modal-header="{ close }">
          <h5 id="buildflo-h5-cfloor">Andar</h5>
          <b-button id="buildflo-bclose-modal" variant="link" @click="close()">
            <span id="buildflo-the-x">X</span>
          </b-button>
        </template>
        <form id="buildflo-form-save" @submit.prevent="saveFloor">
          <div id="buildflo-row-wrapper" class="row">
            <div id="buildflo-col-wrapper" class="col-12">
              <div id="buildflo-form-gp" class="form-group">
                <label>
                  Nome
                  <span id="buildflo-span-required" class="required">*</span>
                </label>

                <input
                  id="buildflo-fl-flname"
                  v-model="floor.floor_name"
                  maxlength="255"
                  required
                  type="text"
                  class="form-control"
                  placeholder="Nome"
                />
              </div>
            </div>
            <div id="buildflo-col-wrapper2" class="col-12">
              <div id="buildflo-form-gp2" class="form-group">
                <label>
                  Descrição
                  <span id="buildflo-span-required2" class="required">*</span>
                </label>

                <input
                  id="buildflo-fl-desc"
                  v-model="floor.description"
                  required
                  type="text"
                  maxlength="255"
                  class="form-control"
                  placeholder="Descrição"
                />
              </div>
            </div>
            <div
              id="buildflo-file-prev"
              v-if="filePreview"
              class="col-12 overflow-auto"
            >
              <img
                id="buildflo-img-prev"
                class="img-preview"
                :src="filePreview"
                alt
              />
            </div>

            <div id="buildflo-col-wrapper3" class="col-12 mb-4 mt-3">
              <a
                id="buildflo-file-prev-a"
                v-if="filePreview"
                class="mr-4 text-danger"
                data-toggle="modal"
                @click="removeMap"
                href="#deleteMap"
                >Excluir planta</a
              >
            </div>
          </div>

          <h4 id="buildflo-plant-h4">Planta</h4>

          <div id="buildflo-row-wrapper4" class="row mt-4">
            <div id="buildflo-col-wrapper4" class="col-12">
              <div id="buildflo-form-gp4" class="form-group">
                <label>
                  Nome
                  <span id="buildflo-span-required4" class="required">*</span>
                </label>

                <input
                  id="buildflo-map-mapn"
                  v-model="floor.map.map_name"
                  maxlength="255"
                  required
                  type="text"
                  class="form-control"
                  placeholder="Nome"
                />
              </div>
            </div>

            <div id="buildflo-col-wrapper5" class="col-12">
              <div id="buildflo-from-gp5" class="form-group">
                <label>
                  Descrição
                  <span id="buildflo-span-required5" class="required">*</span>
                </label>
                <input
                  id="buildflo-map-mapd"
                  v-model="floor.map.description"
                  maxlength="255"
                  type="text"
                  required
                  class="form-control"
                  placeholder="Descrição"
                />
              </div>
            </div>
          </div>
          <div id="buildflo-row-wrapper6" class="row">
            <div id="buildflo-col-wrapper6" class="col-12">
              <div id="buildflo-form-gp6" class="form-group">
                <label>
                  <span id="buildflo-change-file" v-if="floor.map.file_archive">
                    Alterar
                  </span>
                  Arquivo
                  <span
                    id="buildflo-span-required6"
                    v-if="!floor.map.file_archive"
                    class="required"
                    >*</span
                  >
                </label>
                <div id="buildflo-custom-file-wrapper" class="custom-file mb-3">
                  <input
                    type="file"
                    ref="fileInput"
                    v-on:change="onFileChange"
                    class="custom-file-input"
                    id="validatedCustomFile"
                    :required="!floor.map.file_archive"
                  />
                  <label
                    id="buildflo-custom-file-label"
                    class="custom-file-label"
                    for="validatedCustomFile"
                    >{{ fileName }}</label
                  >
                </div>
              </div>
            </div>
            <div id="buildflo-col-wrapper7" class="col-12">
              <div id="buildflo-form-gp7" class="form-group">
                <label>
                  Escala (px/m)
                  <span id="buildflo-span-required7" class="required">*</span>
                  <b-button
                    id="buildflo-bbuton-scale"
                    v-if="floor.map.file_archive"
                    variant="link"
                    @click="abrirCalibrar"
                  >
                    calibrar
                  </b-button>
                </label>
                <i
                  title="Selecione um arquivo e então calibre a escala"
                  class="fas fa-question-circle"
                  style="margin-left: 5px"
                >
                </i>
                <input
                  id="buildflo-flmap-scale"
                  v-model="floor.map.scale"
                  required
                  type="number"
                  class="form-control"
                  placeholder="Escala"
                  disabled
                />
                <small id="buildflo-scale-desc" class="form-text text-muted">
                  Valor da escala representa a quantidade de pixels para 1
                  metro. Por exemplo, uma escala com valor 20, significa 20
                  pixels para 1 metro na planta.
                </small>
              </div>
            </div>
          </div>
          <div id="buildflo-row-wrapper8" class="row p-1">
            <button
              id="saveFloor"
              class="btn btn-primary px-5"
              :disabled="load"
            >
              <i
                id="buildflo-load-i"
                v-if="load"
                class="fas fa-sync-alt fa-spin"
              ></i>
              Salvar
            </button>
          </div>
        </form>
      </b-modal>
    </div>

    <div id="buildflo-col-wrapper8" class="row d-flex flex-column">
      <h3>Andares</h3>

      <p id="buildflo-click-p" style="color: silver; font-size: 13px">
        Clique em um andar para prosseguir
      </p>
      <div id="buildflo-col-wrapper9" class="col-12">
        <b-button
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          variant="link"
          @click="addFloor"
          style="float: right; color: white"
          class="btn btn-primary add-user col-2"
          id="addFloor"
        >
          <span id="buildflo-span-add-flo" class="d-none d-md-block d-lg-block">
            Adicionar andar
          </span>
        </b-button>
      </div>
      <b-table
        id="buildflo-floor-table"
        striped
        :busy="load"
        borderless
        hover
        :fields="fieldsFloors"
        :items="floors"
        class="mt-3 rounded floors"
        @row-clicked="goZones"
        show-empty
      >
        <template v-slot:table-colgroup>
          <col
            v-for="field in fieldsFloors"
            :key="field.key"
            :id="field.key"
            :style="{
              width: field.key === 'actions' ? '100px' : '550px',
            }"
          />
        </template>
        <template v-slot:table-busy>
          <div id="buildflo-busy-wrapper" class="text-center my-2">
            <strong>
              <i id="buildflo-spin-i" class="fas fa-sync-alt fa-spin"></i>
            </strong>
          </div>
        </template>

        <template v-slot:cell(map)="data">{{
          data.item.map.description
        }}</template>

        <template v-slot:cell(actions)="data">
          <a
            id="deleteFloor"
            @click="floorDelete = data.item"
            href="#"
            class="mr-3"
            data-toggle="modal"
            data-target="#modalDeleteFloor"
            v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          >
            <i id="trash-i" class="far fa-trash-alt"></i>
          </a>
          <a
            id="editFloor"
            @click="editFloor(data.item)"
            class="mr-3"
            style="cursor: pointer"
            title="Editar"
            v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          >
            <i class="fas fa-pen"></i>
          </a>
          <a
            id="goZones"
            @click="goZones(data.item)"
            class="mr-3"
            style="cursor: pointer"
            title="Zonas"
          >
            <i
              id="buildflo-arrow-right"
              style="color: rgb(43, 151, 214)"
              class="fas fa-arrow-right"
            ></i>
          </a>
        </template>
        <template #empty>
          <span id="buildflo-span-desc-flo"
            >Não existem andares cadastrados nessa edíficio. Sem andares não é
            possível ir para zonas e geofences.</span
          >
        </template>
      </b-table>

      <Modal
        id="modalDeleteFloor"
        title="Excluir Andar"
        v-if="floorDelete"
        v-bind:msg="
          'Tem certeza que deseja excluir ' + floorDelete.floor_name + '?'
        "
        button="Excluir"
        :method="deleteFloor"
      />
    </div>
  </div>
</template>

<script>
import CanvasImage from "@/components/CanvasImage.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    CanvasImage,
    Modal,
  },
  data() {
    return {
      modalShow: false,
      calibrarShow: false,
      imgWidth: 0,
      imgHeight: 0,
      floorDelete: null,
      floor: {
        description: null,
        floor_name: null,
        map: {
          file_archive: null,
          map_name: null,
          scale: null,
        },
      },
      file: "",
      filePreview: "",
      fileName: "",
      fieldsFloors: [
        { key: "floor_name", sortable: true, label: "Nome" },
        { key: "map", sortable: true, label: "Descrição" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  mounted() {
    if (this.$route.params.buildingId) {
      this.$store.dispatch("floor/getFloors", this.$route.params.buildingId);
    }
  },
  methods: {
    abrirCalibrar() {
      this.calibrarShow = !this.calibrarShow;
    },
    setImgSize(size) {
      this.imgWidth = size.imgWidth;
      this.imgHeight = size.imgHeight;
    },
    addFloor() {
      this.floor = {
        description: null,
        floor_name: null,
        map: {
          file_archive: null,
          map_name: null,
          scale: null,
        },
      };
      this.modalShow = !this.modalShow;
    },
    scaleConfirm(distance) {
      this.floor.map.scale = distance;
      this.calibrarShow = false;
    },
    isFloorValid() {
      if (!this.floor.floor_name || this.floor.floor_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome do Andar" });
        return false;
      }
      if (!this.floor.description || this.floor.description.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Descrição" });
        return false;
      }
      if (
        !this.floor.map.file_archive ||
        !this.floor.map.map_name ||
        !this.floor.map.description
      ) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Dados da Planta" });
        return false;
      }
      if (!this.floor.map.scale) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Escala",
          numbercondition: "positivo com decimais separadas por ponto",
        });
        return false;
      }

      return true;
    },
    saveFloor() {
      if (!this.isFloorValid()) {
        return;
      }
      this.$store.dispatch("floor/saveFloor", {
        id: this.floor.id,
        building_id: this.$route.params.buildingId,
        floor_name: this.floor.floor_name,
        description: this.floor.description,
        map: this.floor.map,
      });
      this.modalShow = false;
      this.calibrarShow = false;
    },
    async editFloor(floor) {
      await this.$store.dispatch("floor/getFloor", floor.id);
      this.floor = { id: floor.id, ...this.stored_floor };
      this.modalShow = true;
    },
    async deleteFloor() {
      await this.$store.dispatch("floor/deleteFloor", this.floorDelete.id);
      this.floor = this.stored_floor;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileName = files[0].name;

      let reader = new FileReader();
      reader.onload = (event) => {
        this.file = event.target.result;
        this.floor.map.file_archive = this.file;
      };
      reader.readAsDataURL(files[0]);
    },
    async goZones(floor) {
      await this.$store.dispatch("floor/getFloor", floor.id);
      // this.$store.commit("floor/SAVE_FLOOR", floor);
      this.$emit("next");
    },
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        this.floor = {
          description: null,
          floor_name: null,
          map: {
            file_archive: null,
            map_name: null,
            scale: null,
          },
        };
      }
      this.fileName = "";
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    floors() {
      return this.$store.getters["floor/floors"];
    },
    stored_floor() {
      return this.$store.getters["floor/floor"];
    },
  },
};
</script>
<style>
.floors tr {
  cursor: pointer;
}
</style>
