import { api } from "../services/api";
import router from "../router";
import {displayErrMsg} from "../utils/errors";

export default {
  namespaced: true,
  state: {
    contacts: [],
    contact: null,
  },

  mutations: {
    async SAVE_CONTACT(state, { data }) {
      await api
        .post("customers/" + data.customer_id + "/contacts", data.contact)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_ADD', 'Contato', "SAVE_CONTACT");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Contato', 'SAVE_CONTACT');
        });
      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },
    async DELETE_CONTACT(state, { data }) {
      await api.delete(`contacts/${data.id}`).then(() => {
        displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Contato', "DELETE_CONTACT");
        state.contacts = state.contacts.filter(
          (contact) => contact.id !== data.id
        );
      }).catch((err) => {
        displayErrMsg(err.response.data.code, 'Contato', 'DELETE_CONTACT');
      });
    },
    async UPDATE_CONTACT(state, { data }) {
      await api
        .put("customers/" + data.customer.id + "/contacts", data.contact)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Contato', "UPDATE_CONTACT");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Contato', 'UPDATE_CONTACT');
        });

      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },
    async GET_CONTACTS(state, { data }) {
      await api
        .get("customers/" + data.customer_id + "/contacts")
        .then((res) => {
          state.contacts = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Contato', 'GET_CONTACTS');
        });
    },
    async GET_CONTACT(state, { data }) {

      await api
        .get(`contacts/${data.contactId}`)
        .then((response) => {
          state.contact = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Contato', 'GET_CONTACT');
        });
    },
  },
  getters: {
    contacts: (state) => state.contacts,
    contact: (state) => state.contact,
  },
  actions: {
    deleteContact(context, data) {
      context.commit("DELETE_CONTACT", { data });
    },
    updateContact(context, data) {
      context.commit("UPDATE_CONTACT", { data });
    },
    getContact(context, data) {
      context.commit("GET_CONTACT", { data });
    },
    getContacts(context, data) {
      context.commit("GET_CONTACTS", { data });
    },
    saveContact(context, data) {
      context.commit("SAVE_CONTACT", { data });
    },
  },
};
