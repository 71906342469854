var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumb',{attrs:{"breadcrumbList":_vm.breadcrumbList}}),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card direct-chat direct-chat-primary"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"p-4 bg-white rounded"},[_c('div',{staticClass:"row"},[_c('TwInput',{attrs:{"col_lg":"6","label":"Filtro"}},[_c('div',{staticClass:"pr-1 pl-1 pb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchAvailablesTags),expression:"searchAvailablesTags"}],staticClass:"form-control col-12 w-100",attrs:{"id":"search-available-tags","type":"search","placeholder":"Buscar tags disponíveis"},domProps:{"value":(_vm.searchAvailablesTags)},on:{"input":function($event){if($event.target.composing)return;_vm.searchAvailablesTags=$event.target.value}}})]),_c('div',{staticClass:"row pr-1 pl-1"},[_c('div',{staticClass:"col-12 pb-3"},[_c('button',{staticClass:"btn btn-light table-header-button col-12",attrs:{"type":"button","id":"assignTag","disabled":_vm.load},on:{"click":_vm.onSelectAllAvailable}},[_c('i',{staticClass:"fas fa-chevron-right"}),_c('i',{staticClass:"fas fa-chevron-right"})]),_c('b-table',{staticClass:"custom-scroll mt-2",attrs:{"thead-class":"user-table-header","empty-text":"Não há tags disponíveis","head-variant":"light","borderless":"","no-sort-reset":"","show-empty":"","busy":_vm.load,"fields":[
                        {
                          key: 'tag_label',
                          sortable: true,
                          label: 'Tags disponíveis',
                        },
                        {
                          key: 'tag_model',
                          sortable: true,
                          label: 'Modelo',
                        },
                        {
                          key: 'mac_address',
                          sortable: true,
                          label: 'Mac Address',
                        },
                      ],"items":_vm.availableTags,"filter":_vm.searchAvailablesTags,"empty-filtered-text":"nenhuma tag encontrada com esse filtro"},on:{"row-clicked":_vm.onSelectAvailableTag},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_c('i',{staticClass:"fas fa-sync-alt fa-spin"})])])]},proxy:true}])})],1)])]),_c('TwInput',{attrs:{"col_lg":"6","label":"Filtro"}},[_c('div',{staticClass:"pr-1 pl-1 pb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilteredAssignedTags),expression:"searchFilteredAssignedTags"}],staticClass:"form-control col-12 w-100",attrs:{"id":"search-assigned-tags","type":"search","placeholder":"Buscar tags já associadas"},domProps:{"value":(_vm.searchFilteredAssignedTags)},on:{"input":function($event){if($event.target.composing)return;_vm.searchFilteredAssignedTags=$event.target.value}}})]),_c('div',{staticClass:"row pr-1 pl-1"},[_c('div',{staticClass:"col-12 pb-3"},[_c('button',{staticClass:"btn btn-light table-header-button col-12",attrs:{"type":"button","id":"unassignTag","disabled":_vm.load},on:{"click":_vm.onDeselectAll}},[_c('i',{staticClass:"fas fa-chevron-left"}),_c('i',{staticClass:"fas fa-chevron-left"})]),_c('b-table',{staticClass:"custom-scroll mt-2",attrs:{"thead-class":"user-table-header","empty-text":"Não há tags associadas","head-variant":"light","borderless":"","no-sort-reset":"","show-empty":"","busy":_vm.load,"items":_vm.customerTags,"fields":[
                        {
                          key: 'tag_label',
                          sortable: true,
                          label: 'Tags Associadas',
                        },
                        {
                          key: 'tag_model',
                          sortable: true,
                          label: 'Modelo',
                        },
                        {
                          key: 'mac_address',
                          sortable: true,
                          label: 'Mac Address',
                        },
                      ],"filter":_vm.searchFilteredAssignedTags,"empty-filtered-text":"nenhuma tag encontrada com esse filtro"},on:{"row-clicked":_vm.onDeselectTag},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_c('i',{staticClass:"fas fa-sync-alt fa-spin"})])])]},proxy:true}])})],1)])])],1)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header px-0"},[_c('h1',{staticClass:"title-I"},[_vm._v("Associar Tags")])])
}]

export { render, staticRenderFns }