<template>
  <div id="build-see-content" class="d-flex flex-column justify-content-center align-content-center">
    <canvas
      id="build-see-canvas" style="border: 1px solid #2b97d6"
      :width="`${canvasWidth}`"
      :height="`${canvasHeigth}`"
    ></canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ctx: null,
      canvasWidth: window.innerWidth * 0.75,
      canvasHeigth: 700,
      fundo: null,
      ball: null,
      pt: null,
      svg: null,
      xform: null,
      scaleFactor: null,
      lastX: 0,
      lastY: 0,
      canvas: null,
      dragged: false,
      dragStart: 0,
      raf: null,
      deg: null,
      sumClicks: 0,
    };
  },
  async mounted() {
    //

    this.canvas = document.getElementsByTagName("canvas")[0];
    this.ctx = this.canvas.getContext("2d");
    this.trackTransforms(this.ctx);

    this.fundo = new Image();
    this.fundo.onLoad = () => {
      this.fundo.src = this.floor?.map?.file_archive;
    };
    setInterval(() => {
      if (this.floor?.map?.file_archive) this.deg = (this.deg + 10) % 360;
    }, 50);

    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    this.xform = this.svg.createSVGMatrix();

    this.lastX = this.canvas.width / 2;
    this.lastY = this.canvas.height / 2;
    this.canvas.addEventListener("mousedown", this.clickMouse, false);
    this.canvas.addEventListener("mousemove", this.movingMouse, false);
    this.canvas.addEventListener("mouseup", this.unClickMouse, false);

    this.scaleFactor = 1.1;

    this.canvas.addEventListener("DOMMouseScroll", this.handleScroll, false);
    this.canvas.addEventListener("mousewheel", this.handleScroll, false);

    window.addEventListener("resize", this.reciseScreen, false);

    this.cfg = {
      perDeg: 1,
    };
  },
  methods: {
    redraw() {
      // Clear the entire canvas
      // var p1 = this.transformedPoint(0, 0);
      // var p2 = this.transformedPoint(this.canvasWidth, this.canvasHeigth);
      // this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

      // Alternatively:
      this.ctx.save();
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeigth);
      this.ctx.restore();

      this.ctx.drawImage(this.fundo, 0, 0);

      //foreach
      this.allGeofences.forEach((geo) => {
        this.coverGeofences(
          geo.x_position * this.floor.map.scale,
          geo.y_position * this.floor.map.scale,
          geo.radius * this.floor.map.scale,
          geo.color
        );
      });

      this.init();
    },
    trackTransforms() {
      this.ctx.getTransform = () => {
        return this.xform;
      };

      var savedTransforms = [];
      var save = this.ctx.save;
      this.ctx.save = () => {
        savedTransforms.push(this.xform.translate(0, 0));
        return save.call(this.ctx);
      };
      var restore = this.ctx.restore;
      this.ctx.restore = () => {
        this.xform = savedTransforms.pop();
        return restore.call(this.ctx);
      };

      var scale = this.ctx.scale;
      this.ctx.scale = (sx, sy) => {
        this.xform = this.xform.scaleNonUniform(sx, sy);
        return scale.call(this.ctx, sx, sy);
      };
      var rotate = this.ctx.rotate;
      this.ctx.rotate = (radians) => {
        this.xform = this.xform.rotate((radians * 180) / Math.PI);
        return rotate.call(this.ctx, radians);
      };
      var translate = this.ctx.translate;
      this.ctx.translate = (dx, dy) => {
        this.xform = this.xform.translate(dx, dy);
        return translate.call(this.ctx, dx, dy);
      };
      var transform = this.ctx.transform;
      this.ctx.transform = (a, b, c, d, e, f) => {
        var m2 = this.svg.createSVGMatrix();
        m2.a = a;
        m2.b = b;
        m2.c = c;
        m2.d = d;
        m2.e = e;
        m2.f = f;
        this.xform = this.xform.multiply(m2);
        return transform.call(this.ctx, a, b, c, d, e, f);
      };
      var setTransform = this.ctx.setTransform;
      this.ctx.setTransform = (a, b, c, d, e, f) => {
        this.xform.a = a;
        this.xform.b = b;
        this.xform.c = c;
        this.xform.d = d;
        this.xform.e = e;
        this.xform.f = f;
        return setTransform.call(this.ctx, a, b, c, d, e, f);
      };
    },
    clickMouse(evt) {
      document.body.style.mozUserSelect =
        document.body.style.webkitUserSelect =
        document.body.style.userSelect =
          "none";
      this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
      this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;
      this.dragStart = this.transformedPoint(this.lastX, this.lastY);
      this.dragged = false;
    },
    unClickMouse(evt) {
      this.dragStart = null;
      if (!this.dragged) this.zoom(evt.shiftKey ? -1 : 1);
    },
    movingMouse(evt) {
      this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
      this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;
      this.dragged = true;
      if (this.dragStart) {
        var pt = this.transformedPoint(this.lastX, this.lastY);
        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
        this.redraw();
      }
    },
    transformedPoint(x, y) {
      var pt = this.svg.createSVGPoint();
      pt.x = x;
      pt.y = y;
      return pt.matrixTransform(this.xform.inverse());
    },
    zoom(clicks) {
      this.sumClicks += clicks;
      if (this.sumClicks > -20 && this.sumClicks < 15) {
        var pt = this.transformedPoint(this.lastX, this.lastY);
        this.ctx.translate(pt.x, pt.y);
        var factor = Math.pow(this.scaleFactor, clicks);
        this.ctx.scale(factor, factor);
        this.ctx.translate(-pt.x, -pt.y);
        this.redraw();
      } else if (this.sumClicks < -20) {
        this.sumClicks = -20;
      } else if (this.sumClicks > 15) {
        this.sumClicks = 15;
      }
    },
    handleScroll(evt) {
      var delta = evt.wheelDelta
        ? evt.wheelDelta / 40
        : evt.detail
        ? -evt.detail
        : 0;
      if (delta) this.zoom(delta);
      return evt.preventDefault() && false;
    },
    reciseScreen() {
      this.canvasWidth = window.innerWidth * 0.75;
    },
    ///
    init() {
      this.raf = window.requestAnimationFrame(this.loop);
    },
    loop() {
      this.raf = window.requestAnimationFrame(this.loop);
    },
    coverGeofences(x, y, radius, color) {
      this.ctx.beginPath();
      this.ctx.fillStyle = color;
      this.ctx.arc(x, y, radius, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.closePath();
    },
    drawRadar(iDeg, x, y, radius, color) {
      this.ctx.fillStyle = color;
      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
      this.ctx.arc(
        x,
        y,
        radius,
        ((-70 * this.cfg.perDeg + iDeg) / 180) * Math.PI,
        ((0 + iDeg) / 180) * Math.PI
      );
      this.ctx.closePath();
      this.ctx.fill();
    },
    hexToRgbA(hex) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          ",0.4)"
        );
      }
      return "rgba(0,0,0,0.4)";
    },
  },
  watch: {
    deg() {
      this.redraw();
      this.allGeofences.forEach((geo) => {
        this.drawRadar(
          this.deg,
          geo.x_position * this.floor.map.scale,
          geo.y_position * this.floor.map.scale,
          geo.radius * this.floor.map.scale,
          "rgb(250,250,250,0.3)"
        );
      });
    },
    async floor() {
      await this.$store.dispatch("zone/getZones", this.floor?.id);
      this.fundo.src = this.floor.map.file_archive;

      this.$store.commit("zone/CLEAR_ALL_GEOFENCES");
      await this.zones.forEach(async (zone) => {
        await this.$store.dispatch("zone/getGeofencesByZone", zone.id);
      });
    },
  },
  computed: {
    building_id() {
      return this.$route.params.buildingId;
    },
    floor() {
      return this.$store.getters["floor/floor"];
    },
    zones() {
      return this.$store.getters["zone/zones"];
    },
    allGeofences() {
      const allGeofences = this.$store.getters["zone/allGeofences"];
      allGeofences.forEach((geo) => {
        const zoneIndex = this.zones?.findIndex((z) => {
          return geo.location === z.zone_name;
        });
        geo.color = this.hexToRgbA(this.zones[zoneIndex]?.zone_color);
      });
      return allGeofences;
    },
  },
};
</script>

<style></style>
